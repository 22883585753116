import { useState, useEffect } from "react";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal as GnocityModal } from "../../components/Modal";
import { InputText } from "../../components/inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faBars } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import { getTokenByContract } from "../../services/TokenService";
import { getAllCards, getUserLineUpList } from "../../services/LineupService";

import waxUrl from "../../shared/waxUrl";
import { baseUrlIpfs } from "../../shared/constants";
import pvpConstants from "./constants";

import "./index.scss";
import { getCardAssetsBySchema } from "../../services/AssetService";
import { Button } from "react-bootstrap";
import { healCard } from "../../services/HealthService";
import { buyNFT, getShopListings } from "../../services/MarketService";

export default function GnocityShopPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay, currencies, currencyCollection } =
    pvpConstants;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [healthCards, setHealthCards] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [selectedHealthCards, setSelectedHealthCards] = useState([]);
  const [cardFilter, setCardFilter] = useState("");

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const shopResponse = await getShopListings();

    if (shopResponse.success) {
      const additionalCards = shopResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }
  }

  useEffect(() => {
    if (activeUser) {
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="shop-page">
      <GnocityModal
        className="shop-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <div className="tf-container">
        <h4 className="shop-page__title shop-page__title-grid">
          Shop Listings{" "}
          <div>
            <InputText
              value={cardFilter}
              placeholder="Enter Filter"
              onInputChanged={(value) => {
                setCardFilter(value);
              }}
            />
          </div>
          <Button
            className="tf-button lineup-page__btn"
            onClick={() => setCardGrid((grid) => !grid)}
          >
            {!cardGrid ? (
              <FontAwesomeIcon icon={faThLarge} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </Button>
        </h4>

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible shop-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards
              .filter((c) => {
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.name.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard;
                }

                return true;
              })
              .map((c, i) => {
                const limit = 64;
                const amount = c?.amount.split(" ");
                const buyCollection =
                  amount[1] === currencies.aether
                    ? currencyCollection.erplanet
                    : currencyCollection.gnokentoken;

                return (
                  <SwiperSlide key={i}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="lineup-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name">{`${amount[0]} ${amount[1]}`}</h6>
                        <Button
                          className="tf-button"
                          onClick={async () => {
                            try {
                              const response = await buyNFT(
                                userProperties,
                                activeUser,
                                buyCollection,
                                c.nft_template,
                                c.amount
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage(`NFT SUCCESSFULLY BOUGHT`);
                              }

                              if (!response.success) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage(`TRANSACTION FAILED`);
                              }
                            } catch (error) {
                              setMessageOpen(true);
                              setMessage(`TRANSACTION FAILED : ${error}`);
                              setMessagePositive(false);
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          BUY
                        </Button>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <div className="shop-page__card-grid">
            {allCards
              .filter((c) => {
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.name.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard;
                }

                return true;
              })
              .map((c, i) => {
                const limit = 64;
                const amount = c?.amount.split(" ");
                const buyCollection =
                  amount[1] === currencies.aether
                    ? currencyCollection.erplanet
                    : currencyCollection.gnokentoken;

                return (
                  <div className="tf-product ">
                    <div className="image">
                      <img
                        className="lineup-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">{`${amount[0]} ${amount[1]}`}</h6>
                    <Button
                      className="tf-button"
                      onClick={async () => {
                        try {
                          const response = await buyNFT(
                            userProperties,
                            activeUser,
                            buyCollection,
                            c.nft_template,
                            c.amount
                          );

                          if (response.success) {
                            setMessageOpen(true);
                            setMessagePositive(true);
                            setMessage(`NFT SUCCESSFULLY BOUGHT`);
                          }

                          if (!response.success) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage(`TRANSACTION FAILED`);
                          }
                        } catch (error) {
                          setMessageOpen(true);
                          setMessage(`TRANSACTION FAILED : ${error}`);
                          setMessagePositive(false);
                          console.log("🎶🎶🎶", error);
                        }
                      }}
                    >
                      BUY
                    </Button>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
