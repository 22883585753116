import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faBars,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import { Modal as GnocityModal } from "../../components/Modal";

import waxUrl from "../../shared/waxUrl";
import { baseUrlIpfs } from "../../shared/constants";
import eggyConstants from "./constants";
import { InputText } from "../../components/inputs";

import "./index.scss";

import {
  claimAllEggy,
  claimByEggType,
  getAvailableCards,
  getEggAssetDetails,
  getEggTypeTokens,
  getStakedEggs,
  getWhitelistedEggTokens,
  stakeEggy,
  unstakeAllEggy,
  unstakeByType,
  unstakeEgg,
} from "../../services/EggyService";
import GnocityDropdown from "../../components/Dropdown";

export default function EggyPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay } = eggyConstants;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [loading, setLoading] = useState(false);
  const [eggsLoading, setEggsLoading] = useState(false);
  const [allCards, setAllCards] = useState([]);
  const [stakedCards, setStakedCards] = useState([]);
  const [eggTypes, setEggTypes] = useState([]);
  const [eggTypeList, setEggTypeList] = useState([]);
  const [eggTypeFilter, setEggTypeFilter] = useState("");
  const [selectedEggType, setSelectedEggType] = useState("");
  const [whiteListedEggs, setWhiteListedEggs] = useState([]);
  const [cardFilter, setCardFilter] = useState("");

  async function _serviceInit(activeUser) {
    const eggTypeResponse = await getEggTypeTokens();
    const whitelistResponse = await getWhitelistedEggTokens();

    if (eggTypeResponse.success) {
      const eggTypes = eggTypeResponse.responseData.rows;
      const eggTypesDropdownList = eggTypes.map((e) => {
        return { label: e.eggtype, value: e.eggtypeid };
      });

      setEggTypes(eggTypes);
      setEggTypeList(eggTypesDropdownList);
    }

    if (whitelistResponse.success) {
      const eggWhitelist = whitelistResponse.responseData.rows;
      setWhiteListedEggs(eggWhitelist);
    }
  }

  async function _dataInit(activeUser, types) {
    try {
      setLoading(true);
      const stakedEggList = await getStakedEggs(activeUser, types);
      const detailedEggList = await getEggAssetDetails(
        stakedEggList,
        whiteListedEggs
      );

      setStakedCards(detailedEggList);
      setLoading(false);
    } catch (error) {
      console.log("🎶🎶", error);
    }
  }

  async function _dataListInit(activeUser, whiteListedEggs) {
    try {
      setEggsLoading(true);
      const unstakedEggList = await getAvailableCards(
        activeUser,
        whiteListedEggs
      );
      if (unstakedEggList) setAllCards(unstakedEggList);
      setEggsLoading(false);
    } catch (error) {
      console.log("🎶🎶🎶", error);
    }
  }

  useEffect(() => {
    if (whiteListedEggs && !loading) {
      _dataListInit(activeUser, whiteListedEggs);
    }
  }, [loading, whiteListedEggs]);

  useEffect(() => {
    if (eggTypes.length && whiteListedEggs.length) {
      _dataInit(activeUser, eggTypes, whiteListedEggs);
    }
  }, [eggTypes, whiteListedEggs]);

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="eggy-page">
      <GnocityModal
        className="eggy-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <div className="tf-container">
        <h4 className="eggy-page__title eggy-page__btn-grp-container">
          staking cards
          <div className="eggy-page__btn-container">
            <Button
              className="tf-button lineup-page__btn eggy-page__grid-btn"
              onClick={() => setCardGrid((grid) => !grid)}
            >
              {!cardGrid ? (
                <FontAwesomeIcon icon={faThLarge} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </Button>
            <Button
              className="tf-button eggy-page__btn--spaced"
              onClick={async () => {
                try {
                  const assetIds = allCards.map((c) => c.asset_id);
                  const response = await stakeEggy(
                    userProperties,
                    activeUser,
                    assetIds
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`STAKE SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`STAKE FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`STAKE FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              STAKE ALL
            </Button>
            <Button
              className="tf-button eggy-page__btn--green"
              onClick={async () => {
                try {
                  const claimedEggs = stakedCards.map((c) => c.asset_id);
                  const response = await claimAllEggy(
                    userProperties,
                    activeUser,
                    claimedEggs
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`CLAIM SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`CLAIM FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`CLAIM FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              CLAIM ALL
            </Button>
          </div>
          <div className="eggy-page__btn-container">
            <GnocityDropdown
              list={eggTypeList}
              title={`SELECT EGG TYPE`}
              onDropdownChanged={(value) => {
                setSelectedEggType(value);
                setEggTypeFilter(value);
              }}
            />

            <Button
              className="tf-button eggy-page__btn--spaced"
              onClick={async () => {
                try {
                  const assetIds = allCards.map((c) => c.asset_id);
                  const response = await unstakeByType(
                    userProperties,
                    activeUser,
                    selectedEggType
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`UNSTAKE SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`UNSTAKE FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`UNSTAKE FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              UNSTAKE BY EGG TYPE
            </Button>
            <Button
              className="tf-button eggy-page__btn--green"
              onClick={async () => {
                try {
                  const response = await claimByEggType(
                    userProperties,
                    activeUser,
                    selectedEggType
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`CLAIM SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`CLAIM FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`CLAIM FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              CLAIM BY EGG TYPE
            </Button>
          </div>
        </h4>

        {!loading ? null : (
          <h4 className="eggy-page__loader">
            LOADING <FontAwesomeIcon icon={faSpinner} spin />
          </h4>
        )}

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible eggy-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {stakedCards.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

              const level = c.data?.level ? parseInt(c.data.level) : 0;
              const levelPercentage = level / 100;
              const parsedAmount = parseFloat(c.amount);
              const eggPerHour = parsedAmount * levelPercentage + parsedAmount;
              const hourDifference = parseInt(c.hourDifference);
              const cardDetails =
                hourDifference > 720
                  ? "Please Claim Experience to Continue Earning Hourly."
                  : `Maximum Cap in ${720 - hourDifference} Hours.`;

              return (
                <SwiperSlide key={c.asset_id}>
                  <div className="slider-item">
                    <div className="tf-product ">
                      <h6 className="eggy-page__card-header name">{`${eggPerHour}/HR`}</h6>
                      <div className="image">
                        <img
                          className="lineup-page__slider-image"
                          src={`${imagePathPoint}${c.data.img}`}
                          alt={c.imgUrl}
                        />
                      </div>
                      <h6 className="eggy-page__card-details name">
                        {cardDetails}
                      </h6>
                      <h6 className="name">
                        <Button
                          className="tf-button eggy-page__btn eggy-page__btn--green"
                          onClick={async () => {
                            try {
                              const response = await unstakeEgg(
                                userProperties,
                                activeUser,
                                c.asset_id
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage(`UNSTAKE SUCCESSFUL`);
                              }

                              if (!response.success) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage(`UNSTAKE FAILED`);
                              }
                            } catch (error) {
                              setMessageOpen(true);
                              setMessage(`UNSTAKE FAILED : ${error}`);
                              setMessagePositive(false);
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          UNSTAKE
                        </Button>
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className="eggy-page__card-grid">
            {stakedCards.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

              const level = c.data?.level ? parseInt(c.data.level) : 0;
              const levelPercentage = level / 100;
              const parsedAmount = parseFloat(c.amount);
              const eggPerHour = parsedAmount * levelPercentage + parsedAmount;
              const hourDifference = parseInt(c.hourDifference);
              const cardDetails =
                hourDifference > 720
                  ? "Please Claim Experience to Continue Earning Hourly."
                  : `Maximum Cap in ${720 - hourDifference} Hours.`;

              return (
                <div className="tf-product " key={c.asset_id}>
                  <h6 className="eggy-page__card-header name">{`${eggPerHour}/HR`}</h6>
                  <div className="image">
                    <img
                      className="lineup-page__slider-image"
                      src={`${imagePathPoint}${c.data.img}`}
                      alt={c.imgUrl}
                    />
                  </div>
                  <h6 className="eggy-page__card-details name">
                    {cardDetails}
                  </h6>
                  <h6 className="name">
                    <Button
                      className="tf-button eggy-page__btn eggy-page__btn--green"
                      onClick={async () => {
                        try {
                          const response = await unstakeEgg(
                            userProperties,
                            activeUser,
                            c.asset_id
                          );

                          if (response.success) {
                            setMessageOpen(true);
                            setMessagePositive(true);
                            setMessage(`UNSTAKE SUCCESSFUL`);
                          }

                          if (!response.success) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage(`UNSTAKE FAILED`);
                          }
                        } catch (error) {
                          setMessageOpen(true);
                          setMessage(`UNSTAKE FAILED : ${error}`);
                          setMessagePositive(false);
                          console.log("🎶🎶🎶", error);
                        }
                      }}
                    >
                      UNSTAKE
                    </Button>
                  </h6>
                </div>
              );
            })}
          </div>
        )}

        <h4 className="eggy-page__title">
          Available for staking
          <div className="eggy-page__btn-container">
            <InputText
              value={cardFilter}
              placeholder="Enter Filter"
              onInputChanged={(value) => {
                setCardFilter(value);
              }}
            />
          </div>
          <div className="eggy-page__btn-container">
            <Button
              className="tf-button eggy-page__btn--spaced"
              onClick={async () => {
                try {
                  const assetIds = allCards.map((c) => c.asset_id);
                  const response = await stakeEggy(
                    userProperties,
                    activeUser,
                    assetIds
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`STAKE SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`STAKE FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`STAKE FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              STAKE ALL
            </Button>
          </div>
        </h4>

        {!eggsLoading ? null : (
          <h4 className="eggy-page__loader">
            LOADING <FontAwesomeIcon icon={faSpinner} spin />
          </h4>
        )}

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible eggy-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards
              .filter((c) => {
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard;
                }

                return true;
              })
              .map((c, i) => {
                return (
                  <SwiperSlide key={`${c.asset_id}{${i}}`}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="lineup-page__slider-image"
                            src={`${c.imgUrl}`}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name">
                          <Button
                            className="tf-button"
                            onClick={async () => {
                              try {
                                const response = await stakeEggy(
                                  userProperties,
                                  activeUser,
                                  [c.asset_id]
                                );

                                if (response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(true);
                                  setMessage(`STAKE SUCCESSFUL`);
                                }

                                if (!response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(false);
                                  setMessage(`STAKE FAILED`);
                                }
                              } catch (error) {
                                setMessageOpen(true);
                                setMessage(`STAKE FAILED : ${error}`);
                                setMessagePositive(false);
                                console.log("🎶🎶🎶", error);
                              }
                            }}
                          >
                            STAKE
                          </Button>
                        </h6>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <div className="eggy-page__card-grid">
            {allCards
              .filter((c) => {
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard;
                }

                return true;
              })
              .map((c, i) => {
                return (
                  <div className="tf-product " key={`${c.asset_id}{${i}}`}>
                    <div className="image">
                      <img
                        className="lineup-page__slider-image"
                        src={`${c.imgUrl}`}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">
                      <Button
                        className="tf-button"
                        onClick={async () => {
                          try {
                            const response = await stakeEggy(
                              userProperties,
                              activeUser,
                              [c.asset_id]
                            );

                            if (response.success) {
                              setMessageOpen(true);
                              setMessagePositive(true);
                              setMessage(`STAKE SUCCESSFUL`);
                            }

                            if (!response.success) {
                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage(`STAKE FAILED`);
                            }
                          } catch (error) {
                            setMessageOpen(true);
                            setMessage(`STAKE FAILED : ${error}`);
                            setMessagePositive(false);
                            console.log("🎶🎶🎶", error);
                          }
                        }}
                      >
                        STAKE
                      </Button>
                    </h6>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
