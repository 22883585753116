import transactionSigner from "./TransactionSigner";
import waxUrl from "../shared/waxUrl";

const { atomicContract, gnomeContract, tokenContract, pvpContract } = waxUrl;

export async function allocateSkills(
  userProperties,
  activeUser,
  assetIds,
  skillValue,
  skillAttribute
) {
  const transactionData = [
    {
      contractAccount: "gnocitystory",
      actionName: "collectexp",
      data: {
        account: activeUser,
        toasset_id: assetIds,
        exptoallocate: skillValue,
        attribute_name: skillAttribute,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function saveSkills(
  userProperties,
  activeUser,
  assetIds,
  cardAssetId
) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: gnomeContract,
        asset_ids: assetIds,
        memo: cardAssetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function resetSkills(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: tokenContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: pvpContract,
        quantity: "25000.0000 GNOKEN",
        memo: assetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}
