import { useEffect, useState } from "react";
import classNames from "classnames";
import "./index.scss";

export function BoxDetail({ active = false, title, value, onBoxClicked }) {
  const activeClass = classNames({
    "box-detail--active": active,
  });

  return (
    <div
      className={`box-detail ${activeClass}`}
      onClick={() => {
        if (onBoxClicked) onBoxClicked();
      }}
    >
      <div className="box-detail__title">{title}</div>
      <div className="box-detail__value">{value}</div>
    </div>
  );
}

export function BoxHeader({ title, value, children }) {
  return (
    <div className="box-detail">
      <div className="box-detail__title">{title}</div>
      <div className="box-detail__value">{children}</div>
    </div>
  );
}

export function BoxSlider({
  title,
  value = 0,
  minValue = 0,
  maxValue = 100,
  disabled = false,
  onSliderValueChanged,
}) {
  const [sliderVal, setSliderVal] = useState(value);

  useEffect(() => {
    setSliderVal(value);
  }, [value]);
  return (
    <div className="box-detail">
      <div className="box-detail__slider-title">
        <div>{title}</div>
        <div>{sliderVal}</div>
      </div>
      <div className="box-detail__value">
        <input
          className="box-detail__slider"
          type="range"
          disabled={disabled}
          value={sliderVal}
          min={`${minValue}`}
          max={`${maxValue}`}
          onChange={(e) => {
            const sliderValue = e.target.value;
            const parsedValue = parseInt(sliderValue);
            setSliderVal(sliderValue);
            onSliderValueChanged(parsedValue);
          }}
        />
      </div>
    </div>
  );
}
