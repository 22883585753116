import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

Button.propTypes = {
  title: PropTypes.string,
};

function Button({ title, path, onButtonClicked }) {
  return (
    <Link
      to={path}
      className="tf-button "
      onClick={() => {
        if (onButtonClicked) onButtonClicked();
      }}
    >
      {title}
    </Link>
  );
}

export default Button;
