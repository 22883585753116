import { useState, useEffect } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faBars } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import LinkButton from "../../components/button/LinkButton";
import { Modal as GnocityModal } from "../../components/Modal";

import { getTokenByContract } from "../../services/TokenService";
import { getUserLineUpList } from "../../services/LineupService";
import { getAllCards } from "../../services/LineupService";

import route from "../../routes/routes";
import waxUrl from "../../shared/waxUrl";
import { baseUrlIpfs } from "../../shared/constants";
import petsConstants from "./constants";

import "./index.scss";
import {
  getStakedPets,
  unstakeAllPet,
  unstakePet,
  stakePets,
} from "../../services/PetsService";
import { Button } from "react-bootstrap";

export default function PetsPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay, claimHours } = petsConstants;
  const { petsExperience } = route;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [cardLineup, setCardLineup] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [stakedPets, setStakedPets] = useState([]);
  const [hourlyExp, setHourlyExp] = useState(0);
  const [bonusExp, setBonusExp] = useState(0);

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const petsResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "complexserie",
      "gnocitypets"
    );

    const stakedPetsResponse = await getStakedPets(activeUser);

    if (petsResponse.success) {
      const additionalCards = petsResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (stakedPetsResponse.success) {
      const additionalCards = stakedPetsResponse.responseData;
      setStakedPets((pets) => [...pets, ...additionalCards]);
    }
  }

  useEffect(() => {
    if (stakedPets.length) {
      let hourlyExp = 0;
      let totalExp = 0;
      stakedPets.forEach((sp) => {
        totalExp +=
          sp.hourRange < claimHours
            ? sp.hourRange * sp.hourlyExp
            : claimHours * sp.hourlyExp;

        hourlyExp += sp.hourlyExp;
      });
      setHourlyExp(hourlyExp);
      setBonusExp(totalExp);
    }
  }, [stakedPets]);

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      setStakedPets([]);
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="pets-page">
      <GnocityModal
        className="pets-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <div className="tf-container">
        <article className="pets-page__header-container">
          <section className="pets-page__header-action">
            <h3 className="pets-page__header">CURRENT BONUS</h3>
            <h6 className="pets-page__header-title">PER HOUR</h6>
            <LinkButton
              className="pets-page__action-btn tf-button"
              value={`+ ${hourlyExp} EXP`}
            />
          </section>

          <section className="pets-page__header-action">
            <h3 className="pets-page__header">CLAIM NOW</h3>
            <h6 className="pets-page__header-title">
              Your claim will maxed out in 48 hours
            </h6>
            <LinkButton
              className="pets-page__action-btn tf-button"
              value={`${bonusExp} EXP`}
              url={petsExperience}
            />
          </section>

          <section className="pets-page__header-action">
            <h3 className="pets-page__header">STAKED</h3>
            <h6 className="pets-page__header-title">No. OF PETS</h6>
            <LinkButton
              className="pets-page__action-btn tf-button"
              value={`${stakedPets.length}`}
            />
          </section>

          <section className="pets-page__header-action">
            <h3 className="pets-page__header">UNSTAKED</h3>
            <h6 className="pets-page__header-title">No. OF PETS</h6>
            <LinkButton
              className="pets-page__action-btn tf-button"
              value={`${allCards.length}`}
            />
          </section>
        </article>
      </div>

      <div className="tf-container">
        <h4 className="pets-page__title">
          current lineup
          <div className="pets-page__btn-container">
            <Button
              className="tf-button pets-page__btn"
              onClick={() => setCardGrid((grid) => !grid)}
            >
              {!cardGrid ? (
                <FontAwesomeIcon icon={faThLarge} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </Button>

            <Button
              className="tf-button pets-page__btn--spaced"
              onClick={async () => {
                try {
                  const assetIds = allCards.map((c) => c.asset_id);
                  const response = await stakePets(
                    userProperties,
                    activeUser,
                    assetIds
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`STAKE SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`STAKE FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`STAKE FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              STAKE ALL
            </Button>
            <Button
              className="tf-button pets-page__btn--green"
              onClick={async () => {
                try {
                  const response = await unstakeAllPet(
                    userProperties,
                    activeUser
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`UNSTAKE SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`UNSTAKE FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`UNSTAKE FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              UNSTAKE ALL
            </Button>
          </div>
        </h4>

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible pets-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
              return (
                <SwiperSlide key={c.asset_id}>
                  <div className="slider-item">
                    <div className="tf-product ">
                      <div className="image">
                        <img
                          className="lineup-page__slider-image"
                          src={`${imagePathPoint}${c.assetData.data.img}`}
                          alt={c.imgUrl}
                        />
                      </div>
                      <h6 className="name">
                        <Button
                          className="tf-button"
                          onClick={async () => {
                            try {
                              const response = await stakePets(
                                userProperties,
                                activeUser,
                                [c.asset_id]
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage(`STAKE SUCCESSFUL`);
                              }

                              if (!response.success) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage(`STAKE FAILED`);
                              }
                            } catch (error) {
                              setMessageOpen(true);
                              setMessage(`STAKE FAILED : ${error}`);
                              setMessagePositive(false);
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          STAKE
                        </Button>
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
            {stakedPets.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

              return (
                <SwiperSlide key={c.asset_id}>
                  <div className="slider-item">
                    <div className="tf-product ">
                      <div className="image">
                        <img
                          className="lineup-page__slider-image"
                          src={`${imagePathPoint}${c.data.img}`}
                          alt={c.imgUrl}
                        />
                      </div>
                      <h6 className="name">
                        <Button
                          className="tf-button pets-page__btn--green"
                          onClick={async () => {
                            try {
                              const response = await unstakePet(
                                userProperties,
                                activeUser,
                                c.asset_id
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage(`UNSTAKE SUCCESSFUL`);
                              }

                              if (!response.success) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage(`UNSTAKE FAILED`);
                              }
                            } catch (error) {
                              setMessageOpen(true);
                              setMessage(`UNSTAKE FAILED : ${error}`);
                              setMessagePositive(false);
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          UNSTAKE
                        </Button>
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className="pets-page__card-grid">
            {allCards.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
              return (
                <div className="tf-product " key={c.asset_id}>
                  <div className="image">
                    <img
                      className="lineup-page__slider-image"
                      src={`${imagePathPoint}${c.assetData.data.img}`}
                      alt={c.imgUrl}
                    />
                  </div>
                  <h6 className="name">
                    <Button
                      className="tf-button"
                      onClick={async () => {
                        try {
                          const response = await stakePets(
                            userProperties,
                            activeUser,
                            [c.asset_id]
                          );

                          if (response.success) {
                            setMessageOpen(true);
                            setMessagePositive(true);
                            setMessage(`STAKE SUCCESSFUL`);
                          }

                          if (!response.success) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage(`STAKE FAILED`);
                          }
                        } catch (error) {
                          setMessageOpen(true);
                          setMessage(`STAKE FAILED : ${error}`);
                          setMessagePositive(false);
                          console.log("🎶🎶🎶", error);
                        }
                      }}
                    >
                      STAKE
                    </Button>
                  </h6>
                </div>
              );
            })}
            {stakedPets.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

              return (
                <div className="tf-product " key={c.asset_id}>
                  <div className="image">
                    <img
                      className="lineup-page__slider-image"
                      src={`${imagePathPoint}${c.data.img}`}
                      alt={c.imgUrl}
                    />
                  </div>
                  <h6 className="name">
                    <Button
                      className="tf-button pets-page__btn--green"
                      onClick={async () => {
                        try {
                          const response = await unstakePet(
                            userProperties,
                            activeUser,
                            c.asset_id
                          );

                          if (response.success) {
                            setMessageOpen(true);
                            setMessagePositive(true);
                            setMessage(`UNSTAKE SUCCESSFUL`);
                          }

                          if (!response.success) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage(`UNSTAKE FAILED`);
                          }
                        } catch (error) {
                          setMessageOpen(true);
                          setMessage(`UNSTAKE FAILED : ${error}`);
                          setMessagePositive(false);
                          console.log("🎶🎶🎶", error);
                        }
                      }}
                    >
                      UNSTAKE
                    </Button>
                  </h6>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
