


import logo1 from '../images/partner/partner-1s.png'
import logo2 from '../images/partner/partner-2s.png'
import logo3 from '../images/partner/partner-3s.png'
import logo4 from '../images/partner/partner-4s.png'
import logo5 from '../images/partner/partner-5s.png'
import logo6 from '../images/partner/partner-6s.png'
import logo7 from '../images/partner/partner-7s.png'


const dataPartner = [
    {
        id: 1,
        img: logo1
    },
    {
        id: 2,
        img: logo2
    },
    {
        id: 3,
        img: logo3
    },

    {
        id: 4,
        img: logo4
    },
    {
        id: 5,
        img: logo5
    },

    {
        id: 6,
        img: logo6
    },
    {
        id: 7,
        img: logo7
    },

   
]

export default dataPartner;