const pvpConstants = {
  maxCards: 5,
  skillsDetail: [
    {
      title: "Speed Skill",
      attribute: "speed_skill",
      subTitle: "#Current Speed Exp",
      url: "https://gnomeseries.com/wp-content/uploads/2021/06/top-gnome-ico.png",
    },
    {
      title: "Assassin Skill",
      attribute: "assassin_skill",
      subTitle: "#Current Assassin Exp",
      url: "https://gnomeseries.com/wp-content/uploads/2021/06/assassin-ico.png",
    },
    {
      title: "Combat Skill",
      attribute: "combat_skill",
      subTitle: "#Current Combat Exp",
      url: "https://gnomeseries.com/wp-content/uploads/2021/06/combat.jpg",
    },
    {
      title: "Awareness Skill",
      attribute: "awareness_skill",
      subTitle: "#Current Awareness Exp",
      url: "https://gnomeseries.com/wp-content/uploads/2021/06/awareness.jpg",
    },
    {
      title: "Manipulation Skill",
      attribute: "manipulation_skill",
      subTitle: "#Current Manipulation Exp",
      url: "https://gnomeseries.com/wp-content/uploads/2021/06/manip.jpg",
    },
    {
      title: "Defense Skill",
      attribute: "defense_skill",
      subTitle: "#Current Defense Exp",
      url: "https://gnomeseries.com/wp-content/uploads/2021/06/defense-ico.png",
    },
    {
      title: "Willpower Skill",
      attribute: "willpower_skill",
      subTitle: "#Current Willpower Exp",
      url: "https://gnomeseries.com/wp-content/uploads/2021/06/willpower.jpg",
    },
    {
      title: "Intelligence Skill",
      attribute: "intelligence_skill",
      subTitle: "#Current Intelligence Exp",
      url: "https://gnomeseries.com/wp-content/uploads/2021/06/iq.jpg",
    },
  ],
  sliderBreakpoints: {
    0: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    991: {
      slidesPerView: 5,
    },
  },
  resultSliderBreakpoints: {
    0: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 2,
    },
    991: {
      slidesPerView: 2,
    },
  },
  sliderAutoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
};

export default pvpConstants;
