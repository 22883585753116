import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faBars } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal as GnocityModal } from "../../components/Modal";

import { getAllCards } from "../../services/LineupService";
import {
  getUserCharacters,
  stakeVehicle,
  unstakeVehicle,
  stakeCharacter,
  unstakeCharacter,
} from "../../services/RacingService";
import { getTokenByContract } from "../../services/TokenService";
import { InputText } from "../../components/inputs";

import waxUrl from "../../shared/waxUrl";
import perkConstants from "../PerksPage/constants";

import "./index.scss";

export default function RacingCharacters({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay } = perkConstants;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);
  const [cardFilter, setCardFilter] = useState("");

  const [selectedCard, setSelectedCard] = useState(null);
  const [allCards, setAllCards] = useState([]);
  const [cardLineup, setCardLineup] = useState([]);

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);

    const stakedCharResponse = await getUserCharacters(activeUser);
    const gnomeSeries1Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1"
    );
    const gnomeSeries2Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1",
      "series2cards"
    );
    const complexResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "complexserie",
      "complexcard"
    );
    const cykoResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "cykokobattle",
      "cykokocard"
    );

    if (gnomeSeries1Response.success) {
      const additionalCards = gnomeSeries1Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (gnomeSeries2Response.success) {
      const additionalCards = gnomeSeries2Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (complexResponse.success) {
      const additionalCards = complexResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (cykoResponse.success) {
      const additionalCards = cykoResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (stakedCharResponse.success) {
      const additionalCards = stakedCharResponse.responseData;
      setCardLineup((cards) => [...cards, ...additionalCards]);
    }
  }

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="racing-page">
      {!cardLineup.length ? null : (
        <h3 className="racing-page__title">Your Lineup Cards </h3>
      )}

      <GnocityModal
        className="racing-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <div className="racing-page__card-container">
        <Swiper
          className="collection-1 visible racing-page__bg--gray"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {cardLineup.map((c) => {
            return (
              <SwiperSlide key={c.asset_id}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div className="image">
                      <img
                        className="racing-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">
                      <Button
                        className="tf-button"
                        onClick={async () => {
                          try {
                            const response = await unstakeCharacter(
                              userProperties,
                              activeUser,
                              c
                            );

                            if (response.success) {
                              setMessageOpen(true);
                              setMessagePositive(true);
                              setMessage(`UNSTAKE SUCCESSFUL`);
                            }

                            if (!response.success) {
                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage(`UNSTAKE FAILED`);
                            }
                          } catch (error) {
                            setMessageOpen(true);
                            setMessage(`UNSTAKE FAILED : ${error}`);
                            setMessagePositive(false);
                            console.log("🎶🎶🎶", error);
                          }
                        }}
                      >
                        UNSTAKE
                      </Button>
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}

          {!selectedCard ? null : (
            <SwiperSlide key={selectedCard.asset_id}>
              <div className="slider-item">
                <div className="tf-product ">
                  <div className="image">
                    <img
                      className="racing-page__slider-image"
                      src={selectedCard.imgUrl}
                      alt={selectedCard.imgUrl}
                    />
                  </div>
                  <h6 className="name">
                    <Button
                      className="tf-button"
                      onClick={async () => {
                        try {
                          const response = await stakeCharacter(
                            userProperties,
                            activeUser,
                            selectedCard
                          );

                          if (response.success) {
                            setMessageOpen(true);
                            setMessagePositive(true);
                            setMessage(`STAKE SUCCESSFUL`);
                          }

                          if (!response.success) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage(`STAKE FAILED`);
                          }
                        } catch (error) {
                          setMessageOpen(true);
                          setMessage(`STAKE FAILED : ${error}`);
                          setMessagePositive(false);
                          console.log("🎶🎶🎶", error);
                        }
                      }}
                    >
                      STAKE
                    </Button>
                  </h6>
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>

      {!allCards.length ? null : (
        <h3 className="racing-page__title racing-page__title-grid">
          choose a character{" "}
          <div>
            <InputText
              value={cardFilter}
              placeholder="Enter Filter"
              onInputChanged={(value) => {
                setCardFilter(value);
              }}
            />
          </div>
          <Button
            className="tf-button lineup-page__btn"
            onClick={() => setCardGrid((grid) => !grid)}
          >
            {!cardGrid ? (
              <FontAwesomeIcon icon={faThLarge} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </Button>
        </h3>
      )}

      <div className="racing-page__card-container">
        {!cardGrid ? (
          <Swiper
            className="collection-1 visible racing-page__bg--gray"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards
              .filter((c) => {
                const hasLevel = c.assetData.mutable_data?.level;
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard && hasLevel;
                }

                return hasLevel;
              })
              .map((c) => {
                const isSelected =
                  selectedCard && selectedCard.asset_id === c.asset_id;

                return (
                  <SwiperSlide key={c.asset_id}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="racing-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name">
                          <Button
                            className="tf-button"
                            onClick={async () => {
                              if (selectedCard) {
                                setSelectedCard(null);
                                return;
                              }

                              setSelectedCard(c);
                            }}
                          >
                            {isSelected ? `UNSELECT` : `SELECT`}
                          </Button>
                        </h6>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <div className="racing-page__card-grid">
            {allCards
              .filter((c) => {
                const hasLevel = c.assetData.mutable_data?.level;
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard && hasLevel;
                }

                return hasLevel;
              })
              .map((c) => {
                const isSelected =
                  selectedCard && selectedCard.asset_id === c.asset_id;

                return (
                  <div className="tf-product " key={c.asset_id}>
                    <div className="image">
                      <img
                        className="racing-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">
                      <Button
                        className="tf-button"
                        onClick={async () => {
                          if (selectedCard) {
                            setSelectedCard(null);
                            return;
                          }

                          setSelectedCard(c);
                        }}
                      >
                        {isSelected ? `UNSELECT` : `SELECT`}
                      </Button>
                    </h6>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
