import { RpcError } from "eosjs";

export default async function transactionSigner(
  userProperties,
  transactionData
) {
  let result = null;
  let statusCode = 200;
  let message = "success";

  const transactionDetails = transactionData.map((t, i) => {
    const account = Array.isArray(transactionData[i])
      ? transactionData[i][0].contractAccount
      : transactionData[i].contractAccount;

    const name = Array.isArray(transactionData[i])
      ? transactionData[i][0].actionName
      : transactionData[i].actionName;

    const authorization = [
      {
        actor: userProperties.accountName,
        permission: userProperties.requestPermission,
      },
    ];

    const data = Array.isArray(transactionData[i])
      ? transactionData[i][0].data
      : transactionData[i].data;

    return { account, name, authorization, data };
  });

  console.log("😎😎", transactionDetails);

  try {
    result = await userProperties.signTransaction(
      {
        actions: transactionDetails,
      },
      {
        blocksBehind: 3,
        expireSeconds: 120,
      }
    );

    console.log("😎😎😎", result);
    return { success: true, status: statusCode, responseData: result };
  } catch (e) {
    if (e instanceof RpcError) result = e.json;

    if (typeof result === "string" && "code" in result) {
      statusCode = result["code"];
    } else {
      statusCode = 500;
    }

    message = e + "";

    console.log("🎶🎶🎂", e);
    return { success: false, status: statusCode, responseData: e };
  }
}
