



const dataBanner = [
    {
        id: 1,
        title: 'GNO CITY V2',
        desc: 'Revamping the entire GNO City game.',
    
    },
    {
        id: 2,
        title: 'BUILDING GNO CITY BETTER',
        desc: 'Rebuilding GNO City for a better experience.',
    
    },

    
    

]

export default dataBanner;