import { Fragment, React, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AOS from "aos";

import routes from "./routes/routes";
import Header from "./components/header/Header";

import Page404 from "./pages/404";
import LandingPage from "./pages/LandingPage";
import LineUpPage from "./pages/LineUpPage";
import PvpPage from "./pages/PvpPage";
import HealthCenterPage from "./pages/HealthCenterPage";
import ApplySkillsPage from "./pages/ApplySkillsPage";
import GnomeSkillsPage from "./pages/GnomeSeriesPage";
import GnomeSkills2Page from "./pages/GnomeSeries2Page";
import ComplexCardPage from "./pages/ComplexCardPage";
import CykoCardPage from "./pages/CykoCardPage";
import PerksPage from "./pages/PerksPage";
import PetsPage from "./pages/PetsPage";
import RacingPage from "./pages/RacingPage";
import RacingVehicles from "./pages/RacingPage/RacingVehicles";
import RacingCharacters from "./pages/RacingPage/RacingCharacters";
import BonusPage from "./pages/BonusPage";
import MarketPlacePage from "./pages/MarketPlace";
import EggyPage from "./pages/EggyPage";
import RacingAddOns from "./pages/RacingPage/RacingAddOns";
import MiningPage from "./pages/MiningPage";
import EvolutionPage from "./pages/EvolutionPage";
import GnomeLandPage from "./pages/GnomeLandPage";
import PetsExperiencePage from "./pages/PetsExperiencePage";
import ComingSoonPage from "./pages/ComingSoonPage";
import GnocityShopPage from "./pages/GnocityShopPage";

function App({ ual }) {
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);

  const {
    landing,
    lineup,
    pvp,
    health,
    skills,
    gnomeSkills,
    gnomeSkills2,
    complexSkills,
    cykoSkills,
    perks,
    pets,
    petsExperience,
    racing,
    bonus,
    racingVehicles,
    racingCharacters,
    racingAddOns,
    marketplace,
    eggy,
    mining,
    evolution,
    land,
    miningTest,
  } = routes;

  return (
    <Fragment>
      <Header
        userProperties={ual.activeUser}
        activeUser={ual.activeUser?.accountName}
        showWaxModal={ual.showModal}
        onUserLogout={ual.logout}
      />

      <Routes>
        <Route
          path={landing}
          element={
            <LandingPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
              showWaxModal={ual.showModal}
              onUserLogout={ual.logout}
            />
          }
          exact
        />

        <Route
          path={lineup}
          element={
            <LineUpPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={pvp}
          element={
            <PvpPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={health}
          element={
            <HealthCenterPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={skills}
          element={
            <ApplySkillsPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={gnomeSkills}
          element={
            <GnomeSkillsPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={gnomeSkills2}
          element={
            <GnomeSkills2Page
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={complexSkills}
          element={
            <ComplexCardPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={cykoSkills}
          element={
            <CykoCardPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={perks}
          element={
            <PerksPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={pets}
          element={
            <PetsPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={petsExperience}
          element={
            <PetsExperiencePage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={racing}
          element={
            <RacingPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={racingVehicles}
          element={
            <RacingVehicles
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={racingCharacters}
          element={
            <RacingCharacters
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={racingAddOns}
          element={
            <RacingAddOns
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={bonus}
          element={
            <BonusPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={marketplace}
          element={
            <MarketPlacePage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={eggy}
          element={
            <EggyPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={mining}
          element={
            <ComingSoonPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={miningTest}
          element={
            <MiningPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={evolution}
          element={
            <EvolutionPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={land}
          element={
            <GnomeLandPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route
          path={"/shops"}
          element={
            <GnocityShopPage
              userProperties={ual.activeUser}
              activeUser={ual.activeUser?.accountName}
            />
          }
          exact
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </Fragment>
  );
}

export default App;
