import { apiBaseUrl } from "../shared/constants";
import waxUrl from "../shared/waxUrl";

const { landboxContract, rpcEndpoint } = waxUrl;

export async function getPlayerDetails(activeUser) {
  const url = `${apiBaseUrl}/api/v1/player/${activeUser}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getUserBalance(table = "accounts", activeUser, scope) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const lanboxBounds = "GNOKEN";
  const balanceScope = scope ? scope : activeUser;

  const urlBody = {
    limit: 1,
    code: landboxContract,
    scope: balanceScope,
    upperBound: lanboxBounds,
    lowerBound: lanboxBounds,
    table: table,
    json: true,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
