import { useEffect } from "react";
import LinkButton from "../../components/button/LinkButton";

import "./index.scss";

export default function ComingSoonPage({ userProperties, activeUser }) {
  async function _serviceInit(activeUser) {}

  useEffect(() => {
    if (activeUser) {
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="mining-page">
      <div className="mining-page__full-title">
        <h1>COMING SOON! </h1>
        <LinkButton
          url={`/`}
          value={`BACK TO HOME`}
          className="mining-page__action-btn"
        />
      </div>
    </div>
  );
}
