import { useState, useEffect } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faBars } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import { Modal as GnocityModal } from "../../components/Modal";
import { InputText } from "../../components/inputs";
import { BoxDetail } from "../../components/BoxDetail";

import { getTokenByContract } from "../../services/TokenService";
import { getAllCards } from "../../services/LineupService";

import waxUrl from "../../shared/waxUrl";
import pvpConstants from "./constants";

import "./index.scss";
import { getCardAssetsBySchema } from "../../services/AssetService";
import { Button } from "react-bootstrap";
import { claimPet } from "../../services/PetsService";

export default function PetsExperiencePage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay, viableStatusProperties } =
    pvpConstants;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [activeCard, setActiveCard] = useState(null);
  const [activeSkill, setActiveSkill] = useState("");
  const [healthCards, setHealthCards] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [selectedHealthCards, setSelectedHealthCards] = useState([]);
  const [cardFilter, setCardFilter] = useState("");

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);

    const gnomeSeries1Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1"
    );
    const gnomeSeries2Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1",
      "series2cards"
    );
    const complexResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "complexserie",
      "complexcard"
    );
    const cykoResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "cykokobattle",
      "cykokocard"
    );
    const healthCardsResponse = await getCardAssetsBySchema(
      activeUser,
      "gnocityfood1",
      "gnocityfood",
      1,
      1000
    );

    if (gnomeSeries1Response.success) {
      const additionalCards = gnomeSeries1Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (gnomeSeries2Response.success) {
      const additionalCards = gnomeSeries2Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (complexResponse.success) {
      const additionalCards = complexResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (cykoResponse.success) {
      const additionalCards = cykoResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }
    if (healthCardsResponse.success) {
      const healthCards = healthCardsResponse.responseData.data;
      setHealthCards(healthCards);
    }
  }

  useEffect(() => {
    if (activeCard) {
    }
  }, [activeCard]);

  useEffect(() => {
    if (activeUser) {
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="pet-experience-page">
      <GnocityModal
        isOpen={activeCard}
        className="pet-experience-page__modal-content"
        popupMessage={message}
        messagePositive={messagePositive}
        onModalClose={() => {
          setActiveCard(null);
          setActiveSkill("");
        }}
      >
        <div className="pet-experience-page__image-container">
          <img
            className="pet-experience-page__modal-image"
            src={activeCard?.imgUrl}
            alt={activeCard?.imgUrl}
          />
        </div>

        <div className="pet-experience-page__container">
          {!activeCard?.mint_number ? null : (
            <div className="pet-experience-page__details-container">
              <div className="pet-experience-page__detail-title">Mint No.</div>
              <div className="pet-experience-page__detail-value">
                {activeCard?.mint_number}
              </div>
            </div>
          )}

          <div className="pet-experience-page__details-container">
            <div className="pet-experience-page__detail-title">Name</div>
            <div className="pet-experience-page__detail-value">
              {activeCard?.cardName}
            </div>
          </div>

          <div className="pet-experience-page__details-container">
            <div className="pet-experience-page__detail-title">Level</div>
            <div className="pet-experience-page__detail-value">
              {activeCard?.level}
            </div>
          </div>

          <div className="pet-experience-page__details-container">
            <div className="pet-experience-page__detail-title">Gnoken/WIN</div>
            <div className="pet-experience-page__detail-value">
              {activeCard?.gnokenPerWin}
            </div>
          </div>

          <div className="pet-experience-page__details-container">
            <div className="pet-experience-page__detail-title">Status</div>
            <div className="pet-experience-page__detail-action"></div>
          </div>

          <h4 className="pet-experience-page__title">SKILLS</h4>
          <div className="pet-experience-page__skills-container">
            <BoxDetail
              title="Assassin"
              value={activeCard?.info.AssassinSkill}
              active={activeSkill === viableStatusProperties.assassin}
              onBoxClicked={() =>
                setActiveSkill(viableStatusProperties.assassin)
              }
            />
            <BoxDetail
              title="Awareness"
              value={activeCard?.info.AwarenessSkill}
              active={activeSkill === viableStatusProperties.awareness}
              onBoxClicked={() =>
                setActiveSkill(viableStatusProperties.awareness)
              }
            />
            <BoxDetail
              title="Combat"
              value={activeCard?.info.CombatSkill}
              active={activeSkill === viableStatusProperties.combat}
              onBoxClicked={() => setActiveSkill(viableStatusProperties.combat)}
            />
            <BoxDetail
              title="Defense"
              value={activeCard?.info.DefenseSkill}
              active={activeSkill === viableStatusProperties.defense}
              onBoxClicked={() =>
                setActiveSkill(viableStatusProperties.defense)
              }
            />
            <BoxDetail
              title="Intelligence"
              value={activeCard?.info.IntelligenceSkill}
              active={activeSkill === viableStatusProperties.intelligence}
              onBoxClicked={() =>
                setActiveSkill(viableStatusProperties.intelligence)
              }
            />
            <BoxDetail
              title="Manipulation"
              value={activeCard?.info.ManipulationSkill}
              active={activeSkill === viableStatusProperties.manipulation}
              onBoxClicked={() =>
                setActiveSkill(viableStatusProperties.manipulation)
              }
            />
            <BoxDetail
              title="Speed"
              value={activeCard?.info.SpeedSkill}
              active={activeSkill === viableStatusProperties.speed}
              onBoxClicked={() => setActiveSkill(viableStatusProperties.speed)}
            />
            <BoxDetail
              title="Willpower"
              value={activeCard?.info.WillpowerSkill}
              active={activeSkill === viableStatusProperties.willpower}
              onBoxClicked={() =>
                setActiveSkill(viableStatusProperties.willpower)
              }
            />
          </div>

          <div className="pet-experience-page__btn-container">
            <Button
              disabled={!activeSkill}
              className="skills-page__button tf-button"
              onClick={async () => {
                try {
                  const activeAssetId = activeCard?.assetData.asset_id;
                  const response = await claimPet(
                    userProperties,
                    activeUser,
                    activeAssetId,
                    activeSkill
                  );
                  if (response.success) {
                    setActiveCard(null);
                    setMessage("CLAIM SUCCESSFUL");
                    setMessagePositive(true);
                  }
                  if (!response.success) {
                    setMessage("CLAIM FAILED");
                    setMessagePositive(false);
                  }
                } catch (error) {
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              CLAIM
            </Button>
            <Button
              className="skills-page__button tf-button"
              onClick={() => {
                setActiveSkill("");
              }}
            >
              RESET
            </Button>
          </div>
        </div>
      </GnocityModal>

      <div className="tf-container">
        <h4 className="pet-experience-page__title pet-experience-page__title-grid">
          <div>cards </div>

          <div>
            <InputText
              value={cardFilter}
              placeholder="Enter Filter"
              onInputChanged={(value) => {
                setCardFilter(value);
              }}
            />
          </div>
          <Button
            className="tf-button lineup-page__btn"
            onClick={() => setCardGrid((grid) => !grid)}
          >
            {!cardGrid ? (
              <FontAwesomeIcon icon={faThLarge} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </Button>
        </h4>

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible pet-experience-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards
              .filter((c) => {
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                   return (
        hasCard &&
        ((c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
             (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity))
      );
    }

    return (
     (c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
        (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity)
    );
  })
              .map((c, i) => {
                const isSelected = activeCard?.asset_id === c.asset_id;

                return (
                  <SwiperSlide key={i}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="pet-experience-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                            onClick={() => setActiveCard(c)}
                          />
                        </div>
                        <h6 className="name"></h6>
                        <Button
                          className="tf-button"
                          onClick={async () => {
                            if (isSelected) {
                              setActiveCard(null);
                            }
                            setActiveCard(c);
                          }}
                        >
                          {isSelected ? `UNSELECT` : `SELECT`}
                        </Button>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <div className="pet-experience-page__card-grid">
            {allCards
              .filter((c, i) => {
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return (
        hasCard &&
        ((c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
             (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity))
      );
    }

    return (
     (c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
        (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity)
    );
  })
              .map((c, i) => {
                const isSelected = activeCard?.asset_id === c.asset_id;

                return (
                  <div className="tf-product ">
                    <div className="image">
                      <img
                        className="pet-experience-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                        onClick={() => setActiveCard(c)}
                      />
                    </div>
                    <h6 className="name"></h6>
                    <Button
                      className="tf-button"
                      onClick={async () => {
                        if (isSelected) {
                          setActiveCard(null);
                        }
                        setActiveCard(c);
                      }}
                    >
                      {isSelected ? `UNSELECT` : `SELECT`}
                    </Button>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
