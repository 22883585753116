const pvpConstants = {
  maxCards: 5,
  currencies: {
    gnoken: "GNOKEN",
    aether: "AETHER",
  },
  currencyCollection: {
    gnokentoken: "gnokentokens",
    erplanet: "e.rplanet",
  },
  sliderBreakpoints: {
    0: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    991: {
      slidesPerView: 5,
    },
  },
  sliderAutoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
};

export default pvpConstants;
