import React from "react";
import { FormGroup, FormText } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import "./index.scss";

export function InputText({
  containerClassName = "",
  className = "",
  label = "",
  placeholder = "",
  value = "",
  onInputChanged,
}) {
  return (
    <Form>
      <Form.Group className={containerClassName} controlId="formBasicEmail">
        {!label ? null : <Form.Label>Email address</Form.Label>}
        <Form.Control
          className={className}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            const textValue = e.target.value;
            if (onInputChanged) onInputChanged(textValue);
          }}
        />
      </Form.Group>
    </Form>
  );
}

export function InputSelect({
  containerClassName = "",
  className = "",
  label = "Select One",
  list = [],
  placeholder = "",
  value = "",
  onDropdownChanged,
}) {
  return (
    <Form>
      <Form.Group
        className={`inputs ${containerClassName}`}
        controlId="formBasicEmail"
      >
        <Form.Select
          className={`inputs__component ${className}`}
          onChange={(e) => {
            const value = e.target.value;
            if (onDropdownChanged) onDropdownChanged(value);
          }}
        >
          <option value="">{label}</option>
          {list.map((li) => {
            return (
              <option className="inputs__value-container" value={li.value}>
                {li.title}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    </Form>
  );
}
