import { useState, useEffect } from "react";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import routes from "../../routes/routes";
import LinkButton from "../../components/button/LinkButton";

import { getTokenByContract } from "../../services/TokenService";
import {
  getRaceLogs,
  getUserAddOns,
  getUserCharacters,
  getUserDetails,
  getUserGarage,
  getUserVehicles,
  raceSetReady,
  raceTimeTrial,
} from "../../services/RacingService";

import waxUrl from "../../shared/waxUrl";
import { baseUrlIpfs } from "../../shared/constants";
import racingConstants from "./constants";

import "./index.scss";

import { Button } from "react-bootstrap";

export default function RacingPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;

  const [allCards, setAllCards] = useState([]);
  const [raceStatusReady, setRaceStatusReady] = useState(false);

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const userResponse = await getUserDetails(activeUser);
    const garageResponse = await getUserGarage(activeUser);
    const vehiclesResponse = await getUserVehicles(activeUser);
    const characterResponse = await getUserCharacters(activeUser);
    const raceLogsResponse = await getRaceLogs(activeUser);
    const addOnsResponse = await getUserAddOns(activeUser);

    if (userResponse.success) {
      const response = userResponse.responseData.rows;
      const userIsReady = response.length > 0;
      if (userIsReady) {
        setRaceStatusReady(true);
      }
    }
  }

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="racing-page">
      <div className="tf-container">
        <article className="racing-page__header-container">
          <section className="racing-page__header-action">
            <h3 className="racing-page__header">VEHICLE</h3>
            <h6 className="racing-page__header-title">SELECTION</h6>
            <LinkButton
              url={routes.racingVehicles}
              className="racing-page__action-btn tf-button"
              value={`GO TO GARAGE`}
            />
          </section>

          <section className="racing-page__header-action">
            <h3 className="racing-page__header">DRIVER / RACER</h3>
            <h6 className="racing-page__header-title">SELECTION</h6>
            <LinkButton
              url={routes.racingCharacters}
              className="racing-page__action-btn tf-button"
              value={`GO TO LOCKER ROOM`}
            />
          </section>

          <section className="racing-page__header-action">
            <h3 className="racing-page__header">UPGRADE</h3>
            <h6 className="racing-page__header-title">your CAR</h6>
            <LinkButton
              url={routes.racingAddOns}
              className="racing-page__action-btn tf-button"
              value={`GO TO PITSTOP`}
            />
          </section>

          <section className="racing-page__header-action">
            <h3 className="racing-page__header">STAKE</h3>
            <h6 className="racing-page__header-title">DAILY RACING TICKETS</h6>
            <Button className="racing-page__action-btn tf-button">STAKE</Button>
          </section>

          <section className="racing-page__header-action">
            <h3 className="racing-page__header">RACE STATUS</h3>
            <h6 className="racing-page__header-title">
              {raceStatusReady ? "READY" : "NOT READY"}
            </h6>
            {raceStatusReady ? (
              <Button
                className="racing-page__action-btn tf-button"
                onClick={async () => {
                  const setRacer = false;
                  await raceSetReady(userProperties, activeUser, setRacer);
                }}
              >
                AUTOMATICALLY FINDING MATCHES
              </Button>
            ) : (
              <Button
                className="racing-page__action-btn tf-button"
                onClick={async () => {
                  const setRacer = true;
                  await raceSetReady(userProperties, activeUser, setRacer);
                }}
              >
                START MATCHMAKING
              </Button>
            )}
          </section>

          <section className="racing-page__header-action">
            <h3 className="racing-page__header">TRIAL MODE</h3>
            <h6 className="racing-page__header-title">START</h6>
            <Button
              className="racing-page__action-btn tf-button"
              onClick={async () => {
                await raceTimeTrial(userProperties, activeUser);
              }}
            >
              LETS RACE!
            </Button>
          </section>
        </article>
      </div>
    </div>
  );
}
