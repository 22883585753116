import transactionSigner from "./TransactionSigner";
import waxUrl from "../shared/waxUrl";
import { getAssetDetails } from "./AssetService";

const { rpcEndpoint, perksContract, atomicContract } = waxUrl;

export async function unstakePerk(userProperties, activeUser, perkAssetId) {
  const transactionData = [
    {
      contractAccount: perksContract,
      actionName: "unstake",
      data: {
        account: activeUser,
        perk_asset_id: perkAssetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function stakePerk(
  userProperties,
  activeUser,
  charAssetId,
  perkAssetId
) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: perksContract,
        asset_ids: [perkAssetId],
        memo: "anything",
      },
    },
    {
      contractAccount: perkAssetId,
      actionName: "stake",
      data: {
        account: activeUser,
        perk_asset_id: perkAssetId,
        character_asset_id: charAssetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function getStakedPerks(activeUser, rows = 1000) {
  const stakedPerksDetails = [];
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlbody = {
    json: true,
    code: perksContract,
    scope: activeUser,
    table: "perksinfo",
    limit: rows,
  };

  const options = (urlBody) => {
    return {
      method: "POST",
      body: JSON.stringify(urlBody),
      mode: "cors",
      credentials: "omit",
    };
  };

  const response = await fetch(url, options(urlbody));

  if (response.status === 200) {
    const responseJson = await response.json();
    const stakedPerks = responseJson.rows;
    for (let i = 0; i < stakedPerks.length; i++) {
      const cardDetailResponse = await getAssetDetails(
        stakedPerks[i]?.perk_asset_id
      );
      if (cardDetailResponse.success) {
        const perkDetails = cardDetailResponse.responseData.data;
        stakedPerksDetails.push({ ...stakedPerks[i], details: perkDetails });
      }
    }
    return { success: true, responseData: stakedPerksDetails };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
