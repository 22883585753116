import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BoxDetail } from "../../components/BoxDetail";
import { Modal as GnocityModal } from "../../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faBars } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import {
  getAllCards,
  getCardLineupDetails,
  getUserLineupCards,
  getUserStakedCards,
  getWeatherDetails,
  healAsset,
  saveLineup,
  withdrawLineup,
} from "../../services/LineupService";
import { getTokenByContract } from "../../services/TokenService";

import waxUrl from "../../shared/waxUrl";
import lineupConstants from "./constants";

import "./index.scss";
import { resetSkills } from "../../services/SkillService";
import { InputText } from "../../components/inputs";

export default function LineUpPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { maxCards, sliderBreakpoints, sliderAutoplay } = lineupConstants;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [activeCard, setActiveCard] = useState(null);
  const [allCards, setAllCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [cardLineup, setCardLineup] = useState([]);
  const [cardFilter, setCardFilter] = useState("");

  const hasMaxCards = selectedCards.length >= maxCards;
  const hasAddedCards = selectedCards.filter((sc) => sc?.assetData).length > 0;

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const userLineupResponse = await getUserLineupCards(activeUser);
    const stakedCardsResponse = await getUserStakedCards(activeUser);
    const weatherResponse = await getWeatherDetails(activeUser);

    const gnomeSeries1Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1"
    );
    const gnomeSeries2Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1",
      "series2cards"
    );
    const complexResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "complexserie",
      "complexcard"
    );
    const cykoResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "cykokobattle",
      "cykokocard"
    );

    if (userLineupResponse.success) {
      const lineupCards = userLineupResponse.responseData;
      const tokensData = tokens.responseData;
      const stakedCards = stakedCardsResponse.responseData;
      const weatherBonus = weatherResponse.responseData;

      const response = await getCardLineupDetails(
        lineupCards,
        tokensData,
        stakedCards,
        weatherBonus
      );

      if (response.success) {
        setSelectedCards([...response.responseData.cards]);
        setCardLineup([...response.responseData.lineup]);
      }
    }

    if (gnomeSeries1Response.success) {
      const additionalCards = gnomeSeries1Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (gnomeSeries2Response.success) {
      const additionalCards = gnomeSeries2Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (complexResponse.success) {
      const additionalCards = complexResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (cykoResponse.success) {
      const additionalCards = cykoResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }
  }

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      _serviceInit(activeUser);
      // _serviceInit("5hcba.wam");
    }
  }, [activeUser]);

  return (
    <div className="lineup-page">
      <GnocityModal
        className="lineup-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <GnocityModal
        isOpen={activeCard}
        popupMessage={message}
        messagePositive={messagePositive}
        onModalClose={() => {
          setMessage("");
          setActiveCard(null);
        }}
        onMessageClose={() => {
          setMessage("");
        }}
      >
        <div className="lineup-page__modal-content">
          <div className="lineup-page__image-container">
            <img
              className="lineup-page__modal-image"
              src={activeCard?.imgUrl}
              alt={activeCard?.imgUrl}
            />
          </div>

          <div className="lineup-page__container">
            {!activeCard?.mint_number ? null : (
              <div className="lineup-page__details-container">
                <div className="lineup-page__detail-title">Mint No.</div>
                <div className="lineup-page__detail-value">
                  {activeCard?.mint_number}
                </div>
              </div>
            )}

            <div className="lineup-page__details-container">
              <div className="lineup-page__detail-title">Name</div>
              <div className="lineup-page__detail-value">
                {activeCard?.cardName}
              </div>
            </div>

            <div className="lineup-page__details-container">
              <div className="lineup-page__detail-title">Level</div>
              <div className="lineup-page__detail-value">
                {activeCard?.level}
              </div>
            </div>

            <div className="lineup-page__details-container">
              <div className="lineup-page__detail-title">Gnoken/WIN</div>
              <div className="lineup-page__detail-value">
                {activeCard?.gnokenPerWin}
              </div>
            </div>

            <div className="lineup-page__details-container">
              <div className="lineup-page__detail-title">Status</div>
              <div className="lineup-page__detail-action">
                {!activeCard?.lowHealth ? null : (
                  <Button
                    className="tf-button lineup-page__btn--small"
                    onClick={async () => {
                      try {
                        const healResponse = await healAsset(
                          userProperties,
                          activeUser,
                          activeCard.asset_id
                        );
                        if (healResponse.success) {
                          setMessage("HEAL SUCCESSFUL");
                          setMessagePositive(true);
                        }

                        if (!healResponse.success) {
                          setMessage("HEAL FAILED");
                          setMessagePositive(false);
                        }
                      } catch (error) {
                        console.log("🎶🎶🎶", error);
                      }
                    }}
                  >
                    HEAL
                  </Button>
                )}

                <Button
                  className="tf-button lineup-page__btn--small"
                  onClick={async () => {
                    try {
                      const resetResponse = await resetSkills(
                        userProperties,
                        activeUser,
                        activeCard.asset_id
                      );
                      if (resetResponse.success) {
                        setMessage("RESET SUCCESSFUL");
                        setMessagePositive(true);
                      }

                      if (!resetResponse.success) {
                        setMessage("RESET FAILED");
                        setMessagePositive(false);
                      }
                    } catch (error) {
                      console.log("🎶🎶🎶", error);
                    }
                  }}
                >
                  RESET SKILLS
                </Button>
              </div>
            </div>

            <h4 className="lineup-page__title">SKILLS</h4>
            <div className="lineup-page__skills-container">
              <BoxDetail
                title="Assassin"
                value={activeCard?.info.AssassinSkill}
              />
              <BoxDetail
                title="Awareness"
                value={activeCard?.info.AwarenessSkill}
              />
              <BoxDetail title="Combat" value={activeCard?.info.CombatSkill} />
              <BoxDetail
                title="Defense"
                value={activeCard?.info.DefenseSkill}
              />
              <BoxDetail
                title="Intelligence"
                value={activeCard?.info.IntelligenceSkill}
              />
              <BoxDetail
                title="Manipulation"
                value={activeCard?.info.ManipulationSkill}
              />
              <BoxDetail title="Speed" value={activeCard?.info.SpeedSkill} />
              <BoxDetail
                title="Willpower"
                value={activeCard?.info.WillpowerSkill}
              />
            </div>
          </div>
        </div>
      </GnocityModal>
      {!cardLineup.length ? null : (
        <h3 className="lineup-page__title">current lineup </h3>
      )}

      <div className="lineup-page__card-container">
        <Swiper
          className="collection-1 visible lineup-page__bg--gray"
          inlist={true}
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {selectedCards
            .filter((c) => {
              return c.level;
            })
            .map((c, i) => (
              <SwiperSlide key={i}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div className="image" onClick={() => setActiveCard(c)}>
                      <img
                        className="lineup-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">
                      {!c?.lowHealth ? null : (
                        <Button
                          className="tf-button lineup-page__btn"
                          onClick={async () => {
                            try {
                              const healResponse = await healAsset(
                                userProperties,
                                activeUser,
                                c.asset_id
                              );
                              if (healResponse.success) {
                                setMessageOpen(true);
                                setMessage("HEAL SUCCESSFUL");
                                setMessagePositive(true);
                              }

                              if (!healResponse.success) {
                                setMessageOpen(true);
                                setMessage("HEAL FAILED");
                                setMessagePositive(false);
                              }
                            } catch (error) {
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          HEAL
                        </Button>
                      )}
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>

      {!allCards.length ? null : (
        <h3 className="lineup-page__title">
          eligible cards{" "}
          <div className="lineup-page__btn-container">
            <InputText
              value={cardFilter}
              placeholder="Enter Filter"
              onInputChanged={(value) => {
                setCardFilter(value);
              }}
            />
          </div>
          <div className="lineup-page__btn-container">
            <Button
              className="tf-button lineup-page__btn"
              onClick={() => setCardGrid((grid) => !grid)}
            >
              {!cardGrid ? (
                <FontAwesomeIcon icon={faThLarge} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </Button>

            {!hasAddedCards ? null : (
              <Button
                className="tf-button lineup-page__btn"
                onClick={async () => {
                  try {
                    const addedCards = selectedCards
                      .filter((sc) => sc?.assetData)
                      .map((sc) => sc.id);

                    const response = await saveLineup(
                      userProperties,
                      activeUser,
                      addedCards
                    );

                    if (response.success) {
                      setMessageOpen(true);
                      setMessage("LINEUP SUCCESSFULLY SAVED");
                      setMessagePositive(true);
                    }

                    if (!response.success) {
                      setMessageOpen(true);
                      setMessage("LINEUP SAVING FAILED");
                      setMessagePositive(false);
                    }

                    setTimeout(() => {
                      window.location.reload(false);
                    }, 3000);
                  } catch (error) {
                    console.log("🎶🎶🎶", error);
                  }
                }}
              >
                SAVE LINEUP
              </Button>
            )}
            <Button
              className="tf-button  lineup-page__btn"
              onClick={async () => {
                try {
                  const response = await withdrawLineup(
                    userProperties,
                    activeUser
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessage("LINEUP SUCCESSFULLY WITHDRAWN");
                    setMessagePositive(true);
                  }
                  if (!response.success) {
                    setMessageOpen(true);
                    setMessage("LINEUP WITHDRAWAL FAILED");
                    setMessagePositive(false);
                  }

                  setTimeout(() => {
                    window.location.reload(false);
                  }, 3000);
                } catch (error) {
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              WITHDRAW LINEUP
            </Button>
          </div>
        </h3>
      )}
      <div className="lineup-page__card-container">
        {!cardGrid ? (
          <Swiper
            className="collection-1 visible lineup-page__bg--gray"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards
              .filter((c, i) => {
                const minLevel = 1;

                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  return (
                    cardName.includes(uppercasedFilter) &&
                    c.level &&
                    c.level >= minLevel
                  );
                }

                return c.level && c.level >= minLevel;
              })
              .map((c) => {
                const isSelected = selectedCards.find(
                  (sc) => sc.asset_id === c.asset_id
                );

                return (
                  <SwiperSlide key={c.asset_id}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image" onClick={() => setActiveCard(c)}>
                          <img
                            className="lineup-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name">
                          <Button
                            className="tf-button"
                            disabled={hasMaxCards && !isSelected}
                            onClick={() => {
                              if (isSelected) {
                                const filteredCards = selectedCards.filter(
                                  (sc) => sc.asset_id !== c.asset_id
                                );
                                setSelectedCards(filteredCards);
                                return;
                              }
                              setSelectedCards((sc) => [...sc, c]);
                            }}
                          >
                            {!isSelected ? `SELECT` : `DESELECT`}
                          </Button>
                        </h6>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <div className="lineup-page__card-grid">
            {allCards
              .filter((c, i) => {
                const minLevel = 1;

                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  return (
                    cardName.includes(uppercasedFilter) &&
                    c.level &&
                    c.level >= minLevel
                  );
                }

                return c.level && c.level >= minLevel;
              })
              .map((c, i) => {
                const isSelected = selectedCards.find(
                  (sc) => sc.asset_id === c.asset_id
                );

                return (
                  <div className="tf-product" key={i}>
                    <div className="image" onClick={() => setActiveCard(c)}>
                      <img
                        className="lineup-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">
                      <Button
                        className="tf-button"
                        disabled={hasMaxCards && !isSelected}
                        onClick={() => {
                          if (isSelected) {
                            const filteredCards = selectedCards.filter(
                              (sc) => sc.asset_id !== c.asset_id
                            );
                            setSelectedCards(filteredCards);
                            return;
                          }
                          setSelectedCards((sc) => [...sc, c]);
                        }}
                      >
                        {!isSelected ? `SELECT` : `DESELECT`}
                      </Button>
                    </h6>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
