import { useState, useEffect } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faBars } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import LinkButton from "../../components/button/LinkButton";
import { Modal as GnocityModal } from "../../components/Modal";

import { getTokenByContract } from "../../services/TokenService";
import { getAllCards } from "../../services/LineupService";

import waxUrl from "../../shared/waxUrl";
import { baseUrlIpfs } from "../../shared/constants";
import petsConstants from "./constants";

import "./index.scss";

import { Button } from "react-bootstrap";
import {
  getStakedBonus,
  stakeBonus,
  unstakeAllBonus,
  unstakeBonus,
} from "../../services/BonusService";

export default function BonusPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay } = petsConstants;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [allCards, setAllCards] = useState([]);
  const [stakedBonus, setStakedBonus] = useState([]);

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const bonusResponse = await getStakedBonus(activeUser);
    const series1Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1",
      "gnomecard"
    );

    const complexResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "complexserie",
      "complexstory"
    );

    if (series1Response.success) {
      const additionalCards = series1Response.responseData;
      const filteredCards = additionalCards.filter((c) => {
        const variant = c.assetData.template.immutable_data.Variant;
        const variantIndex = "Portrait";
        return variant && variant.indexOf(variantIndex) !== -1;
      });
      setAllCards((cards) => [...cards, ...filteredCards]);
    }

    if (complexResponse.success) {
      const additionalCards = complexResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (bonusResponse.success) {
      const additionalCards = bonusResponse.responseData;
      setStakedBonus((bonus) => [...bonus, ...additionalCards]);
    }
  }

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      setStakedBonus([]);
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="bonus-page">
      <GnocityModal
        className="bonus-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <div className="tf-container">
        <article className="pets-page__header-container">
          <section className="pets-page__header-action">
            <h3 className="pets-page__header">CURRENT BONUS</h3>
            <h6 className="pets-page__header-title">PER MATCH</h6>
            <LinkButton
              className="pets-page__action-btn tf-button"
              value={`+ ${stakedBonus.length * 5}% EXP`}
            />
          </section>

          <section className="pets-page__header-action">
            <h3 className="pets-page__header">STAKED</h3>
            <h6 className="pets-page__header-title"># OF COMPLEX STORIES</h6>
            <LinkButton
              className="pets-page__action-btn tf-button"
              value={`${stakedBonus.filter((c) => !c.isPortrait).length}`}
            />
          </section>

          <section className="pets-page__header-action">
            <h3 className="pets-page__header">STAKED</h3>
            <h6 className="pets-page__header-title">No. OF Portraits</h6>
            <LinkButton
              className="pets-page__action-btn tf-button"
              value={`${stakedBonus.filter((c) => c.isPortrait).length}`}
            />
          </section>

          <section className="pets-page__header-action">
            <h3 className="pets-page__header">UNSTAKED</h3>
            <h6 className="pets-page__header-title">No. OF Bonuses</h6>
            <LinkButton
              className="pets-page__action-btn tf-button"
              value={`${allCards.length}`}
            />
          </section>
        </article>
      </div>

      <div className="tf-container">
        <h4 className="bonus-page__title">
          current lineup
          <div className="bonus-page__btn-container">
            <Button
              className="tf-button bonus-page__btn"
              onClick={() => setCardGrid((grid) => !grid)}
            >
              {!cardGrid ? (
                <FontAwesomeIcon icon={faThLarge} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </Button>

            <Button
              className="tf-button bonus-page__btn--spaced"
              onClick={async () => {
                try {
                  const assetIds = allCards.map((c) => c.asset_id);
                  const response = await stakeBonus(
                    userProperties,
                    activeUser,
                    assetIds
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`STAKE SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`STAKE FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`STAKE FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              STAKE ALL
            </Button>
            <Button
              className="tf-button bonus-page__btn--green"
              onClick={async () => {
                try {
                  const response = await unstakeAllBonus(
                    userProperties,
                    activeUser
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`UNSTAKE SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`UNSTAKE FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`UNSTAKE FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              UNSTAKE ALL
            </Button>
          </div>
        </h4>

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible bonus-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {stakedBonus.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

              return (
                <SwiperSlide key={c.asset_id}>
                  <div className="slider-item">
                    <div className="tf-product ">
                      <div className="image">
                        <img
                          className="lineup-page__slider-image"
                          src={`${imagePathPoint}${c.data.img}`}
                          alt={c.imgUrl}
                        />
                      </div>
                      <h6 className="name">
                        <Button
                          className="tf-button bonus-page__btn--green"
                          onClick={async () => {
                            try {
                              const response = await unstakeBonus(
                                userProperties,
                                activeUser,
                                [c.asset_id]
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage(`UNSTAKE SUCCESSFUL`);
                              }

                              if (!response.success) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage(`UNSTAKE FAILED`);
                              }
                            } catch (error) {
                              setMessageOpen(true);
                              setMessage(`UNSTAKE FAILED : ${error}`);
                              setMessagePositive(false);
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          UNSTAKE
                        </Button>
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}

            {allCards.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
              return (
                <SwiperSlide key={c.asset_id}>
                  <div className="slider-item">
                    <div className="tf-product ">
                      <div className="image">
                        <img
                          className="lineup-page__slider-image"
                          src={`${imagePathPoint}${c.assetData.data.img}`}
                          alt={c.imgUrl}
                        />
                      </div>
                      <h6 className="name">
                        <Button
                          className="tf-button"
                          onClick={async () => {
                            try {
                              const response = await stakeBonus(
                                userProperties,
                                activeUser,
                                [c.asset_id]
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage(`STAKE SUCCESSFUL`);
                              }

                              if (!response.success) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage(`STAKE FAILED`);
                              }
                            } catch (error) {
                              setMessageOpen(true);
                              setMessage(`STAKE FAILED : ${error}`);
                              setMessagePositive(false);
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          STAKE
                        </Button>
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className="bonus-page__card-grid">
            {stakedBonus.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

              return (
                <div className="tf-product " key={c.asset_id}>
                  <div className="image">
                    <img
                      className="lineup-page__slider-image"
                      src={`${imagePathPoint}${c.data.img}`}
                      alt={c.imgUrl}
                    />
                  </div>
                  <h6 className="name">
                    <Button
                      className="tf-button bonus-page__btn--green"
                      onClick={async () => {
                        try {
                          const response = await unstakeBonus(
                            userProperties,
                            activeUser,
                            [c.asset_id]
                          );

                          if (response.success) {
                            setMessageOpen(true);
                            setMessagePositive(true);
                            setMessage(`UNSTAKE SUCCESSFUL`);
                          }

                          if (!response.success) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage(`UNSTAKE FAILED`);
                          }
                        } catch (error) {
                          setMessageOpen(true);
                          setMessage(`UNSTAKE FAILED : ${error}`);
                          setMessagePositive(false);
                          console.log("🎶🎶🎶", error);
                        }
                      }}
                    >
                      UNSTAKE
                    </Button>
                  </h6>
                </div>
              );
            })}

            {allCards.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
              return (
                <div className="tf-product " key={c.asset_id}>
                  <div className="image">
                    <img
                      className="lineup-page__slider-image"
                      src={`${imagePathPoint}${c.assetData.data.img}`}
                      alt={c.imgUrl}
                    />
                  </div>
                  <h6 className="name">
                    <Button
                      className="tf-button"
                      onClick={async () => {
                        try {
                          const response = await stakeBonus(
                            userProperties,
                            activeUser,
                            [c.asset_id]
                          );

                          if (response.success) {
                            setMessageOpen(true);
                            setMessagePositive(true);
                            setMessage(`STAKE SUCCESSFUL`);
                          }

                          if (!response.success) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage(`STAKE FAILED`);
                          }
                        } catch (error) {
                          setMessageOpen(true);
                          setMessage(`STAKE FAILED : ${error}`);
                          setMessagePositive(false);
                          console.log("🎶🎶🎶", error);
                        }
                      }}
                    >
                      STAKE
                    </Button>
                  </h6>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
