const eggyConstants = {
  maxCards: 5,
  claimHours: 48,
  evolutionSliderBreakpoints: {
    0: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 3,
    },
    991: {
      slidesPerView: 3,
    },
  },
  sliderBreakpoints: {
    0: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 4,
    },
    991: {
      slidesPerView: 5,
    },
  },
  sliderAutoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
};

export default eggyConstants;
