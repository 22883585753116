import moment from "moment";
import transactionSigner from "./TransactionSigner";
import {
  rpcEndpoint,
  baseUrlIpfs,
  baseUrlWaxAtomicAssets,
} from "../shared/constants";
import waxUrl from "../shared/waxUrl";
import { getAssetDetails } from "./AssetService";
const { landContract, atomicContract } = waxUrl;

export async function getConfigurations(
  table = "config",
  activeUser,
  scope,
  rows = 1000
) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const CONTRACT = "gnocitylands";

  const urlBody = {
    code: CONTRACT,
    scope: scope ? scope : CONTRACT,
    json: true,
    limit: rows,
    table: table,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getStakedLands(activeUser, rows = 1000) {
  const stakedLands = [];
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
  const urlbody = {
    json: true,
    code: landContract,
    scope: landContract,
    table: "userlands",
    limit: rows,
  };

  const options = (urlBody) => {
    return {
      method: "POST",
      body: JSON.stringify(urlBody),
      mode: "cors",
      credentials: "omit",
    };
  };

  const response = await fetch(url, options(urlbody));

  if (response.status === 200) {
    const responseJson = await response.json();
    const responseData = responseJson.rows.filter(
      (r) => r.account === activeUser
    );

    for (let i = 0; i <= responseData.length; i++) {
      const cardDetailResponse = await getAssetDetails(
        responseData[i]?.land_asset_id
      );
      if (cardDetailResponse.success) {
        const detail = cardDetailResponse.responseData.data;
        const imgUrl = imagePathPoint + detail?.template?.immutable_data.img;
        stakedLands.push({
          ...detail,
          imgUrl,
        });
      }
    }
    console.log("🎶🎶", stakedLands);

    return { success: true, responseData: stakedLands };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function stakeLandAssets(userProperties, assetId, activeUser) {
  const transactionData = [
    {
      contractAccount: landContract,
      actionName: "stakeland",
      data: {
        land_assetid: parseInt(assetId),
        account: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function updateLandPrice(
  userProperties,
  assetId,
  activeUser,
  landPrice
) {
  const transactionData = [
    {
      contractAccount: landContract,
      actionName: "updatePrice",
      data: {
        land_assetid: parseInt(assetId),
        account: activeUser,
        price: landPrice,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function stakeLand(
  userProperties,
  assetId,
  addressType,
  activeUser
) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: "gnocitylands",
        asset_ids: [assetId],
        memo: "",
      },
    },
    {
      contractAccount: landContract,
      actionName: "claimland",
      data: {
        account: activeUser,
        land_assetid: assetId,
        land_type: addressType,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function unstakeLand(
  userProperties,
  assetId,
  addressType,
  activeUser
) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: "gnocitylands",
        asset_ids: [assetId],
        memo: "",
      },
    },
    {
      contractAccount: landContract,
      actionName: "claimland",
      data: {
        account: activeUser,
        land_assetid: assetId,
        land_type: addressType,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}
