import { useState, useEffect } from "react";

import { Button } from "react-bootstrap";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faBars,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import { getConfigurations } from "../../services/GamerService";
import { getTokenByContract } from "../../services/TokenService";
import { getAllCards } from "../../services/LineupService";
import {
  activateGamer,
  activatePC,
  claimReward,
  claimRewards,
  deactivateGamer,
  exchangeForBits,
  exchangeForLanbx,
  rechargeGamer,
  upgradeGamer,
  upgradePC,
} from "../../services/MiningService";
import { getUserBalance } from "../../services/PlayerService";

import { Modal as GnocityModal } from "../../components/Modal";
import { InputText } from "../../components/inputs";
import LinkButton from "../../components/button/LinkButton";

import { computeMiningPower, getCardRate, getMiningBonus } from "./helper";
import { getToFixedNumber, removeSuffx, isDecimal } from "../../shared/helpers";

import waxUrl from "../../shared/waxUrl";
import miningConstants from "./constants";

import "./index.scss";

export default function MiningPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay, cardTypes } = miningConstants;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [miningRate, setMiningRate] = useState(0);

  const [popupMessage, setPopupMessage] = useState("");
  const [message, setMessage] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messagePositive, setMessagePositive] = useState(false);

  const [exchangeModal, setExchangeModal] = useState(false);
  const [exchangeModalMessage, setExchangeModalMessage] = useState("");
  const [exchangePositive, setExchangePositive] = useState(true);

  const [gamer, setGamer] = useState(false);
  const [gamerModal, setGamerModal] = useState(false);
  const [gamersModal, setGamersModal] = useState(false);

  const [stakedGamer, setStakedGamer] = useState(null);
  const [stakedGamers, setStakedGamers] = useState([]);
  const [stakedPCs, setStakedPCs] = useState([]);

  const [balances, setBalances] = useState([]);
  const [lanbxBalances, setLanbxBalances] = useState([]);

  const [templates, setTemplates] = useState([]);
  const [config, setConfig] = useState(null);

  const [allCards, setAllCards] = useState([]);
  const [selectedHealthCards, setSelectedHealthCards] = useState([]);
  const [cardFilter, setCardFilter] = useState("");

  const [miningPower, setMiningPower] = useState(0);
  const [exchangeBits, setExchangeBits] = useState(false);
  const [exchangeValue, setExchangeValue] = useState(0);
	
	  const [bconfigs, setBconfigs] = useState([]); // State for bconfigs data

	
	

  const _gamersInit = async (activeUser) => {
    const gamers = await getConfigurations("gamers", activeUser);
    const pcs = await getConfigurations("pcs", activeUser);

    if (gamers.success) {
      setStakedGamers(gamers.responseData?.rows);
    }
    if (pcs.success) {
      setStakedPCs(pcs.responseData?.rows);
    }
  };
					  
					    const [pcLevels, setPcLevels] = useState({}); // State to hold the levels of PCs

  // Fetch PC levels once when the component mounts or when activeUser changes
  useEffect(() => {
    const fetchPcLevels = async () => {
      const response = await getConfigurations("pcs", activeUser);
      if (response.success) {
        // Create a map of asset_id to level for easy access
        const levelsMap = response.responseData.rows.reduce((acc, pc) => {
          acc[pc.asset_id] = pc.level; // Assuming 'level' is the field name
          return acc;
        }, {});
        setPcLevels(levelsMap);
      } else {
        // Handle error
        console.error('Failed to fetch PC levels:', response.responseData);
      }
    };

    fetchPcLevels();
  }, [activeUser]);
			function getNextClaimTime(activeUser, stakedGamers) {
	  console.log('getNextClaimTime called', { activeUser, stakedGamers });

  const gamerData = stakedGamers.find(gamer => gamer.owner === activeUser);

  if (gamerData) {
    const lastClaimEpoch = gamerData.last_claim; // Assuming last_claim is in epoch time (seconds)
    const lastClaimDate = new Date(lastClaimEpoch * 1000); // Convert to milliseconds
    const nextClaimDate = new Date(lastClaimDate.getTime() + 3600000); // Add 1 hour (3600000 milliseconds)

    return nextClaimDate;
  } else {
    console.error('No gamer data found for the active user.');
    return null;
  }
}

// Usage example
const nextClaimDate = getNextClaimTime(activeUser, stakedGamers);
if (nextClaimDate) {
  const currentTime = new Date();
  if (currentTime >= nextClaimDate) {
    //console.log('You can claim now. Do not upgrade or assign anything new. If you do upgrade or assign you will have to wait another hour before you can claim.');
  } else {
   // console.log(`You can claim again at ${nextClaimDate.toLocaleTimeString()}.`);
  }
}			  
					  
const [nextClaimTime, setNextClaimTime] = useState(null);

useEffect(() => {
  const calculatedNextClaimTime = getNextClaimTime(activeUser, stakedGamers);
  if (calculatedNextClaimTime) {
    setNextClaimTime(calculatedNextClaimTime.toLocaleTimeString());
  }
}, [activeUser, stakedGamers]);
					  const [nextClaimMessage, setNextClaimMessage] = useState('');

useEffect(() => {
  const updateNextClaimMessage = () => {
    const currentTime = new Date();
    const nextTime = getNextClaimTime(activeUser, stakedGamers);
    if (nextTime) {
      if (currentTime >= nextTime) {
        setNextClaimMessage('You can claim now. Do not upgrade or assign anything new. If you do upgrade or assign you will have to wait another hour before you can claim.');
      } else {
        setNextClaimMessage(`You can claim all rewards again at ${nextTime.toLocaleTimeString()}`);
      }
    } else {
      setNextClaimMessage('No claim data available');
    }
  };

  // Call the function immediately and set an interval
  updateNextClaimMessage();
  const interval = setInterval(updateNextClaimMessage, 60000); // Update every minute

  // Clear the interval on component unmount
  return () => clearInterval(interval);
}, [activeUser, stakedGamers]);

					  
  // Fetch bconfigs table data
  useEffect(() => {
    const fetchBconfigs = async () => {
      const response = await getConfigurations("bconfigs");
      if (response.success) {
        setBconfigs(response.responseData.rows);
      } else {
        console.error('Failed to fetch bconfigs:', response.responseData);
      }
    };

    fetchBconfigs();
  }, []);

  // Function to get upgrade cost
  const getUpgradeCost = (templateId, currentLevel) => {
    const templateConfig = bconfigs.find(t => t.template_id === templateId);
    if (templateConfig && templateConfig.levelcost) {
      const nextLevel = currentLevel + 1;
      const levelCost = templateConfig.levelcost.find(lc => parseInt(lc.key) === nextLevel);
      return levelCost ? getToFixedNumber(levelCost.value) : 0;
    }
    return 0;
  };


  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
					  
// Fetch data from both collections
const landboxResponse = await getAllCards(
  activeUser,
  tokens.responseData,
  "gnocityworld"
);
const gnomeseriesResponse = await getAllCards(
  activeUser,
  tokens.responseData,
  "gnomeseries1"
);
					  	  

// Combine responses while ensuring uniqueness
let combinedCards = [];
const addedAssetIds = new Set(); // To track unique asset IDs

const addToCombinedCards = (cards) => {
  cards.forEach(card => {
    if (!addedAssetIds.has(card.asset_id)) {
      combinedCards.push(card);
      addedAssetIds.add(card.asset_id);
    }
  });
};

if (landboxResponse.success) {
  //addToCombinedCards(landboxResponse.responseData);
}
if (gnomeseriesResponse.success) {
  addToCombinedCards(gnomeseriesResponse.responseData);
}

setAllCards(combinedCards);
/*
    const landboxResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries"
    );
	*/

    const templatesResponse = await getConfigurations("bconfigs");
    const landboxBalanceResponse = await getUserBalance("accounts", activeUser);
    const balanceResponse = await getConfigurations(
      "balances",
      activeUser,
      activeUser
    );
    const configurationResponse = await getConfigurations("configs");

    if (landboxResponse.success) {
      const additionalCards = landboxResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (landboxBalanceResponse.success) {
      const landbxBalance = landboxBalanceResponse.responseData?.rows;
      setLanbxBalances(landbxBalance.map((b) => b?.balance));
    }

    if (balanceResponse.success) {
      const bitsBalance = balanceResponse.responseData?.rows;
      setBalances(bitsBalance.map((b) => b?.balance));
    }

    if (templatesResponse.success) {
      setTemplates(templatesResponse.responseData?.rows);
    }

    if (configurationResponse.success) {
      setConfig(configurationResponse.responseData?.rows);
    }
  }

  useEffect(() => {
    if (activeUser) {
      _serviceInit(activeUser);
      _gamersInit(activeUser);
    }
  }, [activeUser]);

  useEffect(() => {
    const assets = allCards.map((c) => c.assetData);
    const gamersByUser = stakedGamers.filter((g) => g.owner === activeUser);
    setMiningRate(
      computeMiningPower(
        assets,
        templates,
        config,
        gamersByUser,
        stakedPCs,
        activeUser
      )
    );
  }, [allCards, templates, config, stakedGamers, stakedPCs, activeUser]);
					  
	// Function to get gamer details
const getGamerDetails = (templateId) => {
  const gamerConfig = bconfigs.find(t => t.template_id === templateId && t.type === 'gamer');
  const boost = parseFloat(gamerConfig?.boost);
  return {
    maxPcs: gamerConfig?.max_pcs || 'Unknown',
    boost: !isNaN(boost) ? boost.toFixed(2) : '0.00'
  };
};				  

  return (
    <div className="mining-page">
      <GnocityModal
        className="mining-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
          setExchangeBits(true);
          setExchangeValue(0);
        }}
      />

      <GnocityModal
        className="mining-page__message-wrapper"
        isOpen={exchangeModal}
        messagePositive={exchangePositive}
        popupMessage={exchangeModalMessage}
        onModalClose={() => {
          setExchangeModalMessage("");
          setExchangeModal(false);
        }}
      >
        <div className="mining-page__modal-body">
          <div className="header-form mining-page__modal-input">
            <form>
              <label className="mining-page__text--white">
                How much {exchangeBits ? `GNOKEN` : `GRASS`} would you like to
                sell?
              </label>
              <input
                step={`any`}
                type="text"
                className="txt"
                value={exchangeValue ? exchangeValue : ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValid = isDecimal(inputValue);
                  const isZero = e.target.value === 0;

                  if (!inputValue) setExchangeValue(0);
                  if (isValid || isZero) setExchangeValue(inputValue);
                }}
              />
            </form>
          </div>
          <div className="mining-page__btn-group">
            {exchangeBits ? (
              <Button
                disabled={!exchangeValue}
                className="mining-page__modal-btn tf-button"
                onClick={async () => {
                  const response = await exchangeForBits(
                    userProperties,
                    activeUser,
                    exchangeValue
                  );

                  if (response.success) {
                    setExchangeModalMessage("Your exchange was successful.");
                    setExchangePositive(true);
                    return;
                  }

                  setExchangeModalMessage(response?.responseData?.message);
                  setExchangePositive(false);
                }}
              >
                Submit the Exchange for Grass
              </Button>
            ) : (
              <Button
                disabled={!exchangeValue}
                className="mining-page__modal-btn tf-button"
                onClick={async () => {
                  const response = await exchangeForLanbx(
                    userProperties,
                    activeUser,
                    exchangeValue
                  );

                  if (response.success) {
                    setExchangeModalMessage("Your exchange was successful.");
                    setExchangePositive(true);
                    return;
                  }

                  setExchangeModalMessage(response?.responseData?.message);
                  setExchangePositive(false);
                }}
              >
                Submit the Exchange for GNOKEN
              </Button>
            )}
          </div>

          <div className="mining-page__btn-group">
            <Button
              className="tf-button mining-page__secondary-btn"
              onClick={() => {
                setExchangeBits(!exchangeBits);
                setExchangeModalMessage("");
                setExchangeValue(0);
              }}
            >
              {exchangeBits ? "SWITCH TO SWAP TO SELL GRASS" : "SWITCH TO SWAP TO SELL GNOKEN"}
            </Button>
          </div>
        </div>
      </GnocityModal>

      <GnocityModal
        isOpen={gamersModal}
        className="mining-page__modal-content"
        wrapperClassName="mining-page__modal-wrapper"
        popupMessage={popupMessage}
        messagePositive={messagePositive}
        onModalClose={() => {
          setPopupMessage("");
          setGamer(null);
          setGamersModal(false);
        }}
        onMessageClose={() => {
          setPopupMessage("");
        }}
      >
        {!gamer ? null : (
          <div className="mining-page__gamer">
            <div className="tf-product ">
              <div className="image">
                <img
                  className="mining-page__slider-image"
                  src={gamer.imgUrl}
                  alt={gamer.imgUrl}
				  style={{ width: '250px' }}
                />
              </div>
              <h6 className="name"></h6>
            </div>
          </div>
        )}

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible mining-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {!gamer
              ? allCards
                  .filter((a) => {
                    const cardDetail = stakedGamers.find(
                      (s) => a.assetData.asset_id === s.asset_id
                    );

                    return cardDetail && cardDetail?.pcids.length;
                  })
                  .map((c) => {
                    const isSelected = selectedHealthCards.find(
                      (sc) => sc.asset_id === c.asset_id
                    );

                    return (
                      <SwiperSlide key={c.asset_id}>
                        <div className="slider-item">
                          <div className="tf-product ">
                            <div className="image">
                              <img
                                className="mining-page__slider-image"
                                src={c.imgUrl}
                                alt={c.imgUrl}
                              />
                            </div>
                            <h6 className="name"></h6>
                            <div className="mining-page__card-container">
                              <Button
                                className="tf-button mining-page__card-btn"
                                onClick={async () => {
                                  try {
                                    const response = await claimReward(
                                      userProperties,
                                      activeUser,
                                      [c.asset_id]
                                    );

                                    if (response.success) {
                                      setMessageOpen(true);
                                      setMessagePositive(true);
                                      setPopupMessage("CLAIM SUCCESSFUL");
                                      return;
                                    }

                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("CLAIM FAILED");
                                  } catch (error) {
                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("CLAIM FAILED");
                                    console.log("🎶🎶🎶", error);
                                  }
                                }}
                              >
                                CLAIM
                              </Button>

                              <Button
                                className="tf-button  mining-page__card-btn"
                                onClick={async () => {
                                  try {
                                    const response = await rechargeGamer(
                                      userProperties,
                                      activeUser,
                                      c.asset_id
                                    );

                                    if (response.success) {
                                      setMessageOpen(true);
                                      setMessagePositive(true);
                                      setPopupMessage("HEAL SUCCESSFUL");
                                      return;
                                    }

                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("HEAL FAILED");
                                  } catch (error) {
                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("HEAL FAILED");
                                    console.log("🎶🎶🎶", error);
                                  }
                                }}
                              >
                                HEAL - 50 GRASS
                              </Button>

                              <Button
                                className="tf-button mining-page__card-btn"
                                onClick={async () => {
                                  try {
                                    const response = await upgradeGamer(
                                      userProperties,
                                      activeUser,
                                      c.asset_id
                                    );

                                    if (response.success) {
                                      setMessageOpen(true);
                                      setMessagePositive(true);
                                      setPopupMessage("UPGRADE SUCCESSFUL");
                                      return;
                                    }

                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("UPGRADE FAILED");
                                  } catch (error) {
                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("UPGRADE FAILED");
                                    console.log("🎶🎶🎶", error);
                                  }
                                }}
                              >
                                UPGRADE
                              </Button>

                              <Button
                                className="tf-button mining-page__card-btn"
                                onClick={() => {}}
                              >
                                VIEW MUSHROOMS
                              </Button>

                              <Button
                                className="tf-button mining-page__card-btn"
                                onClick={async () => {
                                  try {
                                    const response = await deactivateGamer(
                                      userProperties,
                                      activeUser,
                                      c.asset_id
                                    );

                                    if (response.success) {
                                      setMessageOpen(true);
                                      setMessagePositive(true);
                                      setPopupMessage(
                                        "DEACTIVATION SUCCESSFUL"
                                      );
                                      return;
                                    }

                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("DEACTIVATION FAILED");
                                  } catch (error) {
                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("DEACTIVATION FAILED");
                                    console.log("🎶🎶🎶", error);
                                  }
                                }}
                              >
                                DEACTIVATE
                              </Button>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })
              : allCards
                  .filter((a) => {
                    const _isStaked = stakedPCs.find(
                      (sp) =>
                        sp.asset_id === a.assetData.asset_id &&
                        sp.owner === activeUser
                    );

                    const templateData = templates.find(
                      (t) =>
                        t.template_id ===
                        parseInt(a.assetData?.template?.template_id)
                    );
                    const isAComputer = templateData?.type === cardTypes.pc;

                    return !_isStaked && templateData && isAComputer;
                  })
                  .map((c) => {
                    const isSelected = selectedHealthCards.find(
                      (sc) => sc.asset_id === c.asset_id
                    );

                    return (
                      <SwiperSlide key={c.asset_id}>
                        <div className="slider-item">
                          <div className="tf-product ">
                            <div className="image">
                              <img
                                className="mining-page__slider-image"
                                src={c.imgUrl}
                                alt={c.imgUrl}
                              />
                            </div>
                            <h6 className="name"></h6>
                            <div className="mining-page__card-container">
                              <Button
                                className="tf-button mining-page__card-btn"
                                onClick={async () => {
                                  try {
                                    const response = await activatePC(
                                      userProperties,
                                      activeUser,
                                      c.asset_id,
                                      gamer.asset_id
                                    );

                                    if (response.success) {
                                      setMessageOpen(true);
                                      setMessagePositive(true);
                                      setPopupMessage("ACTIVATION SUCCESSFUL");
                                      return;
                                    }

                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("ACTIVATION FAILED");
                                  } catch (error) {
                                    setMessageOpen(true);
                                    setMessagePositive(false);
                                    setPopupMessage("ACTIVATION FAILED");
                                    console.log("🎶🎶🎶", error);
                                  }
                                }}
                              >
                                ASSIGN
                              </Button>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
          </Swiper>
        ) : (
          <div className="mining-page__card-grid mining-page__card-grid--spaced">
            {!gamer
              ? allCards
                  .filter((a) => {
                    const cardDetail = stakedGamers.find(
                      (s) => a.assetData.asset_id === s.asset_id
                    );

                    return cardDetail && cardDetail?.pcids.length;
                  })
                  .map((c) => {
                    const isSelected = selectedHealthCards.find(
                      (sc) => sc.asset_id === c.asset_id
                    );

                    return (
                      <div className="tf-product " key={c.asset_id}>
                        <div className="image">
                          <img
                            className="mining-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name"></h6>
                        <div className="mining-page__card-container">
                          <Button
                            className="tf-button mining-page__card-btn"
                            onClick={async () => {
                              try {
                                const response = await claimReward(
                                  userProperties,
                                  activeUser,
                                  [c.asset_id]
                                );

                                if (response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(true);
                                  setPopupMessage("CLAIM SUCCESSFUL");
                                  return;
                                }

                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("CLAIM FAILED");
                              } catch (error) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("CLAIM FAILED");
                                console.log("🎶🎶🎶", error);
                              }
                            }}
                          >
                            CLAIM
                          </Button>

                          <Button
                            className="tf-button  mining-page__card-btn"
                            onClick={async () => {
                              try {
                                const response = await rechargeGamer(
                                  userProperties,
                                  activeUser,
                                  c.asset_id
                                );

                                if (response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(true);
                                  setPopupMessage("HEAL SUCCESSFUL");
                                  return;
                                }

                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("HEAL FAILED");
                              } catch (error) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("HEAL FAILED");
                                console.log("🎶🎶🎶", error);
                              }
                            }}
                          >
                            HEAL
                          </Button>

                          <Button
                            className="tf-button mining-page__card-btn"
                            onClick={async () => {
                              try {
                                const response = await upgradeGamer(
                                  userProperties,
                                  activeUser,
                                  c.asset_id
                                );

                                if (response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(true);
                                  setPopupMessage("UPGRADE SUCCESSFUL");
                                  return;
                                }

                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("UPGRADE FAILED");
                              } catch (error) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("UPGRADE FAILED");
                                console.log("🎶🎶🎶", error);
                              }
                            }}
                          >
                            UPGRADE
                          </Button>

                          <Button
                            className="tf-button mining-page__card-btn"
                            onClick={() => {}}
                          >
                            VIEW MUSHROOMS
                          </Button>

                          <Button
                            className="tf-button mining-page__card-btn"
                            onClick={async () => {
                              try {
                                const response = await deactivateGamer(
                                  userProperties,
                                  activeUser,
                                  c.asset_id
                                );

                                if (response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(true);
                                  setPopupMessage("DEACTIVATION SUCCESSFUL");
                                  return;
                                }

                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("DEACTIVATION FAILED");
                              } catch (error) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("DEACTIVATION FAILED");
                                console.log("🎶🎶🎶", error);
                              }
                            }}
                          >
                            DEACTIVATE
                          </Button>
                        </div>
                      </div>
                    );
                  })
              : allCards
                  .filter((a) => {
                    const _isStaked = stakedPCs.find(
                      (sp) =>
                        sp.asset_id === a.assetData.asset_id &&
                        sp.owner === activeUser
                    );

                    const templateData = templates.find(
                      (t) =>
                        t.template_id ===
                        parseInt(a.assetData?.template?.template_id)
                    );
                    const isAComputer = templateData?.type === cardTypes.pc;

                    return !_isStaked && templateData && isAComputer;
                  })
                  .map((c) => {
                    const isSelected = selectedHealthCards.find(
                      (sc) => sc.asset_id === c.asset_id
                    );

                    return (
                      <div className="tf-product " key={c.asset_id}>
                        <div className="image">
                          <img
                            className="mining-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name"></h6>
                        <div className="mining-page__card-container">
                          <Button
                            className="tf-button mining-page__card-btn"
                            onClick={async () => {
                              try {
                                const response = await activatePC(
                                  userProperties,
                                  activeUser,
                                  c.asset_id,
                                  gamer.asset_id
                                );

                                if (response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(true);
                                  setPopupMessage("ACTIVATION SUCCESSFUL");
                                  return;
                                }

                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("ACTIVATION FAILED");
                              } catch (error) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setPopupMessage("ACTIVATION FAILED");
                                console.log("🎶🎶🎶", error);
                              }
                            }}
                          >
                            ASSIGN
                          </Button>
                        </div>
                      </div>
                    );
                  })}
          </div>
        )}
      </GnocityModal>

      <div className="tf-container">
        <article className="mining-page__header-container">
          <section className="mining-page__header-action">
            <h4 className="mining-page__header-title  mining-page__header-title--centered">
              {`${getToFixedNumber(
                miningRate +
                  miningRate *
                    (getMiningBonus(parseInt(removeSuffx(balances, /BITS/gi))) *
                      0.01),
                4
              )} GRASS/HR`}

            </h4>

            <Button
              className="mining-page__action-btn tf-button"
              onClick={async () => {
                try {
                  const assets = stakedGamers
                    .filter((g) => g.owner === activeUser)
                    .map((a) => parseInt(a.asset_id));

                  const response = await claimRewards(userProperties, assets);

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setPopupMessage("CLAIM SUCCESSFUL");
                    return;
                  }

                  setMessageOpen(true);
                  setMessagePositive(false);
                  setPopupMessage("CLAIM FAILED");
                } catch (error) {
                  setMessageOpen(true);
                  setMessagePositive(false);
                  setPopupMessage("CLAIM FAILED");
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              CLAIM ALL REWARDS
            </Button>
          </section>

          <section className="mining-page__header-action">
            <h4 className="mining-page__header-title  mining-page__header-title--centered">
              {" "}
              {removeSuffx(lanbxBalances, /GNOKEN/gi)}
            </h4>
            <LinkButton
              className="mining-page__action-btn tf-button"
              value={`GNOKEN`}
            />
          </section>

          <section className="mining-page__header-action">
            <h4 className="mining-page__header-title mining-page__header-title--centered">
              {removeSuffx(balances, /BITS/gi)}
            </h4>
            <LinkButton
              className="mining-page__action-btn tf-button"
              value={`GRASS`}
            />
          </section>

          <section className="mining-page__header-action">
            <h4 className="mining-page__header-title  mining-page__header-title--centered">
              {`${getMiningBonus(parseInt(removeSuffx(balances, /BITS/gi)))} %`}
            </h4>
            <LinkButton
              className="mining-page__action-btn tf-button"
              value={`MINING BONUS`}
            />
          </section>
        </article>
      </div>

      <div className="tf-container">
        <h4 className="mining-page__title">
          GNOME SERIES 2 CARDS
          <Button
            className="tf-button mining-page__btn"
            onClick={() => setGamersModal((modalOpen) => !modalOpen)}
          >
            YOUR STAKED GNOMES
          </Button>
          <Button
            className="tf-button mining-page__btn"
            onClick={() => setExchangeModal((modalOpen) => !modalOpen)}
          >
            EXCHANGE
          </Button>
        </h4>

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible mining-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards
              .filter((a) => {
                const templateData =
                  templates &&
                  templates.find(
                    (t) =>
                      t.template_id ===
                      parseInt(a.assetData.template.template_id)
                  );

                const cardDetails = stakedGamers.find(
                  (g) => g.asset_id === a.assetData.asset_id
                );

                const _hasMaxPcs =
                  cardDetails && templateData
                    ? cardDetails?.pcids.length >= templateData?.max_pcs
                    : false;

                const _isAGamer = templateData
                  ? templateData.type === cardTypes.gamer
                  : false;

                return (
                  (!cardDetails || (cardDetails && !_hasMaxPcs)) &&
                  templateData &&
                  _isAGamer
                );
              })
              .map((c) => {
                const _isStaked = stakedGamers.find(
                  (sg) => sg.asset_id === c.assetData.asset_id
                );

                const cardLevel = c.assetData.mutable_data?.Level
                  ? c.assetData.mutable_data?.Level
                  : "1";

                return (
                  <SwiperSlide key={c.asset_id}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="mining-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name"></h6>

                        {_isStaked ? (
                          <div className="mining-page__card-container">
                            <Button
                              className="tf-button"
                              onClick={() => {
                                setGamersModal(true);
                                setGamer(c);
                              }}
                            >
                              ASSIGN MUSHROOM
                            </Button>
                            <Button
                              className="tf-button"
                              onClick={async () => {
                                try {
                                  const response = await deactivateGamer(
                                    userProperties,
                                    activeUser,
                                    c.asset_id
                                  );

                                  if (response.success) {
                                    setMessageOpen(true);
                                    setMessagePositive(true);
                                    setMessage("DEACTIVATION SUCCESSFUL");
                                    return;
                                  }

                                  setMessageOpen(true);
                                  setMessagePositive(false);
                                  setMessage("DEACTIVATION FAILED");
                                } catch (error) {
                                  setMessageOpen(true);
                                  setMessagePositive(false);
                                  setMessage("DEACTIVATION FAILED");
                                  console.log("🎶🎶🎶", error);
                                }
                              }}
                            >
                              DEACTIVATE
                            </Button>
                          </div>
                        ) : (
                          <Button
                            className="tf-button"
                            onClick={async () => {
                              try {
                                const response = await activateGamer(
                                  userProperties,
                                  activeUser,
                                  c.asset_id
                                );

                                if (response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(true);
                                  setMessage("ACTIVATION SUCCESSFUL");
                                  return;
                                }

                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage("ACTIVATION FAILED");
                              } catch (error) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage("ACTIVATION FAILED");
                                console.log("🎶🎶🎶", error);
                              }
                            }}
                          >
                            ACTIVATE
                          </Button>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <div className="mining-page__card-grid">
            {allCards
              .filter((a) => {
                const templateData =
                  templates &&
                  templates.find(
                    (t) =>
                      t.template_id ===
                      parseInt(a.assetData.template.template_id)
                  );

                const cardDetails = stakedGamers.find(
                  (g) => g.asset_id === a.assetData.asset_id
                );



                const _hasMaxPcs =
                  cardDetails && templateData
                    ? cardDetails?.pcids.length >= templateData?.max_pcs
                    : false;

                const _isAGamer = templateData
                  ? templateData.type === cardTypes.gamer
                  : false;

                return (
                  (!cardDetails || (cardDetails && !_hasMaxPcs)) &&
                  templateData &&
                  _isAGamer
                );
              })
              .map((c) => {
                const _isStaked = stakedGamers.find(
                  (sg) => sg.asset_id === c.assetData.asset_id
                );
const templateId = parseInt(c.assetData.template.template_id);
      const { maxPcs, boost } = getGamerDetails(templateId);

                const cardLevel = c.assetData.mutable_data?.Level
                  ? c.assetData.mutable_data?.Level
                  : "1";

                return (
                  <div className="tf-product " key={c.asset_id}>
    <div style={{
      background: 'linear-gradient(135deg, #9D50BB, #6E48AA)', // Gradient from dark to light purple
      color: 'white',
      padding: '4px 12px',
      borderRadius: '15px', // Creates the pill shape
      fontWeight: 'bold',
      textShadow: '1px 1px 2px black', // Gives depth to text
      boxShadow: '2px 2px 5px rgba(0,0,0,0.3)', // Soft shadow for 3D effect
    }}>Max Mushrooms: {maxPcs}</div>
                    <div className="image">
                      <img
                        className="mining-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>

                   
           <h2 style={{
    textAlign: 'center',
    color: '#7f21e7',
    padding: '10px',
    backgroundColor: '#f0f0f0', // Light grey background for contrast
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
    fontWeight: 'bold',
    fontSize: '1.5em',
marginBottom: '20px' // Adds space below the h2 element
}}>
    Base Boost: {boost}%
</h2>
                    {_isStaked ? (
                      <div className="mining-page__card-container">
                        <Button
                          className="tf-button"
                          onClick={() => {
                            setGamersModal(true);
                            setGamer(c);
                          }}
                        >
                          ASSIGN MUSHROOM
                        </Button>
                        <Button
                          className="tf-button"
                          onClick={async () => {
                            try {
                              const response = await deactivateGamer(
                                userProperties,
                                activeUser,
                                c.asset_id
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage("DEACTIVATION SUCCESSFUL");
                                return;
                              }

                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage("DEACTIVATION FAILED");
                            } catch (error) {
                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage("DEACTIVATION FAILED");
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          DEACTIVATE
                        </Button>
                      </div>
                    ) : (
                      <Button
                        className="tf-button"
                        onClick={async () => {
                          try {
                            const response = await activateGamer(
                              userProperties,
                              activeUser,
                              c.asset_id
                            );

                            if (response.success) {
                              setMessageOpen(true);
                              setMessagePositive(true);
                              setMessage("ACTIVATION SUCCESSFUL");
                              return;
                            }

                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage("ACTIVATION FAILED");
                          } catch (error) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage("ACTIVATION FAILED");
                            console.log("🎶🎶🎶", error);
                          }
                        }}
                      >
                        ACTIVATE
                      </Button>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>

      <div className="tf-container">
        <h4 className="mining-page__title mining-page__title-grid">
          MUSHROOM MINERS{" "}
          <div>
            <InputText
              value={cardFilter}
              placeholder="Enter Filter"
              onInputChanged={(value) => {
                setCardFilter(value);
              }}
            />
          </div>
          <Button
            className="tf-button mining-page__btn"
            onClick={() => setCardGrid((grid) => !grid)}
          >
            {!cardGrid ? (
              <FontAwesomeIcon icon={faThLarge} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </Button>
        </h4>

<h2 className="mining-page__header-title mining-page__header-title--centered">
            {nextClaimMessage}
          </h2>

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible mining-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards
              .filter((c) => {
                const templateData = templates.find(
                  (t) =>
                    t.template_id ===
                    parseInt(c.assetData?.template?.template_id)
                );
                const isAComputer = templateData?.type === cardTypes.pc;

                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard && templateData && isAComputer;
                }

                return templateData && isAComputer;
              })
              .map((c, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="mining-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name"></h6>
                        <Button
                          className="tf-button"
                          onClick={async () => {
                            const _isStaked = stakedPCs.find(
                              (sp) =>
                                sp.asset_id === c.asset_id &&
                                sp.owner === activeUser
                            );

                            try {
                              const response = await upgradePC(
                                userProperties,
                                activeUser,
                                c.asset_id
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage("UPGRADE SUCCESS");
                                return;
                              }

                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage("UPGRADE FAILED");
                            } catch (error) {
                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage("UPGRADE FAILED");
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          MINING - UPGRADE
                        </Button>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <div className="mining-page__card-grid">
            {allCards
              .filter((c) => {
                const templateData = templates.find(
                  (t) =>
                    t.template_id ===
                    parseInt(c.assetData?.template?.template_id)
                );
                const isAComputer = templateData?.type === cardTypes.pc;

                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard && templateData && isAComputer;
                }

                return templateData && isAComputer;
              })
              .map((c, i) => {
                const _isStaked = stakedPCs.find(
                  (sp) => sp.asset_id === c.asset_id && sp.owner === activeUser
                );
const pcLevel = pcLevels[c.asset_id] || "Unknown"; // Fetch PC level from state for each card
          const templateId = parseInt(c.assetData?.template?.template_id);
          const upgradeCost = getUpgradeCost(templateId, pcLevel); // Calculate upgrade cost

                return (
                  <div className="tf-product" key={i}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '4px' }}>
    <span style={{ fontWeight: 'bold', color: '#FFF', fontSize: '12px' }}>Lvl:</span>
    <div style={{
      background: 'linear-gradient(135deg, #9D50BB, #6E48AA)', // Gradient from dark to light purple
      color: 'white',
      padding: '4px 12px',
      borderRadius: '15px', // Creates the pill shape
      fontWeight: 'bold',
      textShadow: '1px 1px 2px black', // Gives depth to text
      boxShadow: '2px 2px 5px rgba(0,0,0,0.3)', // Soft shadow for 3D effect
    }}>
      {pcLevel}
    </div>
  </div>
 
</div>
                    <div className="image">
                      <img
                        className="mining-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
					 <div style={{ width: '100%', marginTop: '4px', paddingBottom: '8px' }}>
      <div style={{
        color: 'white',
        padding: '4px 12px',
        borderRadius: '15px', // Creates the pill shape
        fontWeight: 'bold',
        textShadow: '1px 1px 2px black', // Gives depth to text
        boxShadow: '2px 2px 5px rgba(0,0,0,0.3)', // Soft shadow for 3D effect
        textAlign: 'center', // Centers the text horizontally
        width: '100%', // Takes full width of the container
        display: 'block', // Makes the div a block-level element
fontSize: '12px',
      }}>
        Upgrade Cost: {upgradeCost} GRASS
      </div>
    </div>

                    {!_isStaked ? null : (
                      <Button
                        className="tf-button"
                        onClick={async () => {
                          try {
                            const response = await upgradePC(
                              userProperties,
                              activeUser,
                              c.asset_id
                            );

                            if (response.success) {
                              setMessageOpen(true);
                              setMessagePositive(true);
                              setMessage("UPGRADE SUCCESS");
                              return;
                            }

                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage("UPGRADE FAILED");
                          } catch (error) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage("UPGRADE FAILED");
                            console.log("🎶🎶🎶", error);
                          }
                        }}
                      >
                        MINING - UPGRADE
                      </Button>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
