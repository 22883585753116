import React ,{useState} from 'react';
import PropTypes from 'prop-types';
import img1 from '../../assets/images/slider/about.png'
import img2 from '../../assets/images/slider/about2.png'
import './styles.scss'

About1.propTypes = {
    data : PropTypes.array,
};

function About1(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            heading: 'ABOUT GNO CITY',
            desc: 'GNO CITY started as a digital collectible that evolved into a play and earn game. GNO CITY continues to build and expand to create the best game experience on the WAX Blockchain.'

        }
    )


    const [dataCounter] = useState([
        {
            id: 1,
            title: 'Total NFTS',
            number: '750000',
        },
        {
            id: 2,
            title: 'NFTS Burned',
            number: '400000',
        },
    ])
    return (
        <section className="tf-section tf-about">
                <div className="tf-container">
                    <div className="row ">   
                        <div className="col-md-12 ">
                            <div className="icon">
								 <img src={img1} alt="GNO City" />
                            </div>
                           <div className="icon-2">
                            <img src={img2} alt="GNO City" />
                           </div>
                                       
                            <div className="tf-heading wow fadeInUp">
                                <h2 className="heading">{dataBlock.heading}</h2>
                                <p className="sub-heading">{dataBlock.desc}</p>
                            </div>

                            <div className="counter-wrap wow fadeInUp" data-wow-delay="0.2s">
                                {
                                    dataCounter.map(idx => (
                                        <div key={idx.id} className="tf-counter ">
                                            <h6>{idx.title}</h6>
                                            <div className="content">
                                                <span className="counter-number" data-to="2240" data-speed="2000" >{idx.number}</span>+
                                            </div>
                                        </div>  
                                    ))
                                }
                               
                            </div>

                        </div>
                        {
                            data.map(idx => (
                                <div key={idx.id} className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="tf-step wow fadeInUp" data-wow-delay="0.2s">
                                        <div className="step-title">
                                            <div className="sub-number">
                                                0{idx.id}
                                            </div>
                                            <h3>{idx.title}</h3>
                                        </div>
                                        <p>{idx.text}</p>
                                    </div>
                                </div>
                            ) )
                        }
                        
                    </div>
                </div>
            </section>
    );
}

export default About1;