import React from 'react';
import PropTypes from 'prop-types';

import { Navigation , Autoplay   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import img1 from '../../assets/images/slider/about.png'
import img10 from '../../assets/images/partner/part-1.png'
import img11 from '../../assets/images/partner/part-23.png'
import img12 from '../../assets/images/partner/part6.png'
import img13 from '../../assets/images/partner/part-4.png'
import img14 from '../../assets/images/partner/part-3.png'
import img15 from '../../assets/images/partner/part7.png'
import img16 from '../../assets/images/partner/part8.png'
import img17 from '../../assets/images/partner/part5.png'

Partner.propTypes = {
    data: PropTypes.array,
};

function Partner(props) {
    const {data} = props;
    return (
        <section className=" tf-section tf-partner-sec">
            <div className="icon">
                     <img src={img1} alt="GNO City" />
            </div>
            <div className="tf-container">
                
                <div className="row">   
                    <div className="col-md-12 ">
                        <div className="tf-heading mb60 wow fadeInUp">
							<hr></hr>
                            <h2 className="heading"><span>GNO CITY</span> PARTNERS & COLLECTIONS</h2>
                        </div>
                    </div>
                    <div className="col-md-12 wow fadeInUp">
                    
	<img src={img11} alt="GNO City" width="160" />
	<img src={img12} alt="GNO City" width="160" />
	<img src={img13} alt="GNO City" width="160" />
						<img src={img14} alt="GNO City" width="160" />
						<img src={img15} alt="GNO City" width="160" />
						<img src={img16} alt="GNO City" width="160" />
						<img src={img17} alt="GNO City" width="160" />
							<img src={img10} alt="GNO City" width="160" />

                    </div>                
                    
                </div>
            </div>
        </section>
    );
}

export default Partner;