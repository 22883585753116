import { useState, useEffect, Fragment } from "react";
import { Button } from "react-bootstrap";
import { Modal as GnocityModal } from "../../components/Modal";
import { BoxDetail, BoxSlider } from "../../components/BoxDetail";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { getTokenByContract } from "../../services/TokenService";
import { getAllCards } from "../../services/LineupService";
import { saveSkills } from "../../services/SkillService";

import waxUrl from "../../shared/waxUrl";
import { baseUrlIpfs } from "../../shared/constants";
import pvpConstants from "./constants";
import { InputText } from "../../components/inputs";

import "./index.scss";

export default function CykoCardPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay } = pvpConstants;

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [loader, setLoader] = useState(true);
  const [allCards, setAllCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [activeCard, setActiveCard] = useState(null);
  const [cardFilter, setCardFilter] = useState("");

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const cykoResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "cykokobattle",
      "cykokocard"
    );

    if (cykoResponse.success) {
      const additionalCards = cykoResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }
  }

  useEffect(() => {
    if (activeUser) {
      setLoader(true);
      setAllCards([]);
      _serviceInit(activeUser);
      setLoader(false);
    }
  }, [activeUser]);

  return (
    <Fragment>
      {loader ? null : (
        <div className="skills-page">
          <GnocityModal
            isOpen={activeCard}
            className="skills-page__modal-content"
            popupMessage={message}
            messagePositive={messagePositive}
            onModalClose={() => setActiveCard(null)}
          >
            <div className="skills-page__image-container">
              <img
                className="skills-page__modal-image"
                src={activeCard?.imgUrl}
                alt={activeCard?.imgUrl}
              />
            </div>

            <div className="skills-page__container">
              <h4 className="skills-page__title">Skills Selected</h4>
              {selectedCards.length ? null : (
                <h2 className="skills-page__title">NO SKILL CARDS SELECTED</h2>
              )}

              <div className="skills-page__cards-container">
                {!selectedCards.length
                  ? null
                  : selectedCards.map((sc) => {
                      return (
                        <img
                          className="skills-page__card-image"
                          src={sc?.imgUrl}
                          alt={sc?.imgUrl}
                        />
                      );
                    })}
              </div>
              {!selectedCards.length ? null : (
                <div className="skills-page__btn-container">
                  <Button
                    className="skills-page__button tf-button"
                    onClick={async () => {
                      try {
                        const cardAssetIds = selectedCards.map((c) => {
                          return c.asset_id;
                        });

                        const activeAssetId = activeCard.assetData.asset_id;
                        const response = await saveSkills(
                          userProperties,
                          activeUser,
                          cardAssetIds,
                          activeAssetId
                        );

                        if (response.success) {
                          setSelectedCards([]);
                          setActiveCard(null);

                          setMessage("SAVE SUCCESSFUL");
                          setMessagePositive(true);
                        }

                        if (!response.success) {
                          setMessage("SAVE FAILED");
                          setMessagePositive(false);
                        }
                      } catch (error) {
                        setMessage("SAVE FAILED");
                        setMessagePositive(false);
                        console.log("🎶🎶🎶", error);
                      }
                    }}
                  >
                    APPLY
                  </Button>
                  <Button
                    className="skills-page__button tf-button"
                    onClick={() => {
                      setSelectedCards([]);
                    }}
                  >
                    RESET
                  </Button>
                </div>
              )}
            </div>
          </GnocityModal>
          <div className="tf-container">
            <h4 className="skills__title">Skill Cards</h4>
            <Swiper
              className="collection-1 visible skills__swiper"
              spaceBetween={30}
              breakpoints={sliderBreakpoints}
              loop={false}
              modules={[Autoplay]}
              autoplay={sliderAutoplay}
            >
              {allCards
                .filter((c) => {
                  const schemaName = c.assetData.schema.schema_name;
                  const skillName = "complexPack";
                  const hasSkill = c.assetData.name.search("Skill") >= 0;
                  return hasSkill && schemaName !== skillName;
                })
                .map((c) => {
                  const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
                  const isSelected = selectedCards.find(
                    (sc) => sc.asset_id === c.asset_id
                  );

                  return (
                    <SwiperSlide key={c.asset_id}>
                      <div className="slider-item">
                        <div className="tf-product ">
                          <div className="image">
                            <img
                              className="skills-page__slider-image"
                              src={c.imgUrl}
                              alt={c.imgUrl}
                            />
                          </div>
                          <h6 className="name"></h6>
                          <Button
                            className="tf-button"
                            onClick={() => {
                              if (isSelected) {
                                const filteredCards = selectedCards.filter(
                                  (sc) => sc.asset_id !== c.asset_id
                                );
                                setSelectedCards(filteredCards);
                                return;
                              }
                              setSelectedCards((selectedCards) => [
                                ...selectedCards,
                                c,
                              ]);
                            }}
                          >
                            {isSelected ? `DESELECT` : `SELECT`}
                          </Button>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>

          <div className="tf-container">
            <h4 className="skills__title">
              Gnome Cards{" "}
              <div>
                <InputText
                  value={cardFilter}
                  placeholder="Enter Filter"
                  onInputChanged={(value) => {
                    setCardFilter(value);
                  }}
                />
              </div>
            </h4>
            <Swiper
              className="collection-1 visible skills__swiper"
              spaceBetween={30}
              breakpoints={sliderBreakpoints}
              loop={false}
              modules={[Autoplay]}
              autoplay={sliderAutoplay}
            >
              {allCards
                .filter((c) => {
                  const schemaName = c.assetData.schema.schema_name;
                  const cykoCard = "cykokocard";
                  const isNotASkill = c.assetData.name.search("Skill") === -1;

                  if (cardFilter) {
                    const uppercasedFilter = cardFilter.toUpperCase();
                    const cardName = c.cardName.toUpperCase();
                    const hasCard = cardName.includes(uppercasedFilter);

                    return hasCard && schemaName === cykoCard;
                  }

                  return isNotASkill && schemaName === cykoCard;
                })
                .map((c) => {
                  const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
                  const isSelected = selectedCards.find(
                    (sc) => sc.asset_id === c.asset_id
                  );

                  return (
                    <SwiperSlide key={c.asset_id}>
                      <div className="slider-item">
                        <div className="tf-product ">
                          <div className="image">
                            <img
                              className="skills-page__slider-image"
                              src={c.imgUrl}
                              alt={c.imgUrl}
                            />
                          </div>
                          <h6 className="name"></h6>
                          <Button
                            className="tf-button"
                            onClick={() => {
                              setActiveCard(c);
                            }}
                          >
                            SELECT
                          </Button>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      )}
    </Fragment>
  );
}
