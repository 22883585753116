import moment from "moment";
import transactionSigner from "./TransactionSigner";
import waxUrl from "../shared/waxUrl";
import { baseUrlIpfs } from "../shared/constants";
import {
  getAssetDetails,
  getPlayerAssetsByTemplate,
  getPlayerAssetsByTemplateOnly,
} from "./AssetService";

const {
  baseAtomicAssets,
  rpcEndpoint,
  breedContract,
  atomicContract,
  tokenContract,
} = waxUrl;

export async function getWhitelistedEggTokens(rows = 10000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    scope: breedContract,
    code: breedContract,
    table: "whitelisted",
    limit: rows,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  }
}

export async function getEggTypeTokens(rows = 10000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    scope: breedContract,
    code: breedContract,
    table: "eggtypelist1",
    limit: rows,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);

  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  }
}

export async function getEggMints(eggType, rows = 10000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    scope: eggType,
    code: breedContract,
    table: "eggmints",
    limit: rows,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  }
}

export async function getStakedEggs(activeUser, eggTypes, rows = 10000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    scope: activeUser,
    code: breedContract,
    table: "stakes",
    limit: rows,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);

  if (response.status === 200) {
    const json = await response.json();
    const data = json.rows.map((d) => {
      const eggtype = eggTypes.find((eg) => eg.eggtypeid === d.eggtype);

      return {
        ...d,
        eggtype: eggtype.eggtypeid,
      };
    });
    return { success: true, responseData: data };
  }
}

export async function getAvailableCards(activeUser, cardWhitelist = []) {
  const assetList = [];
  const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

  for (let i = 0; i < cardWhitelist.length; i++) {
    const cardDetailResponse = await getPlayerAssetsByTemplateOnly(
      activeUser,
      cardWhitelist[i].collection,
      cardWhitelist[i].templateid
    );

    if (
      cardDetailResponse.success &&
      cardDetailResponse.responseData.data.length
    ) {
      const cardData = cardDetailResponse.responseData.data[0];
      assetList.push({
        ...cardData,
        imgUrl: `${imagePathPoint}${cardData?.data?.img}`,
      });
    }
  }

  return assetList;
}

export async function getEggAssetDetails(eggList, cardWhitelist) {
  const assetList = [];
  const eggListData = eggList.responseData;
  const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

  for (let i = 0; i < eggListData.length; i++) {
    const cardDetailResponse = await getAssetDetails(eggListData[i]?.assetid);
    const cardData = cardDetailResponse.responseData.data;

    const templateDetail = cardWhitelist.find((c) => {
      return c.templateid === parseInt(cardData.template.template_id);
    });

    const hourDifference = Math.floor(
      moment
        .duration(
          moment
            .utc(Date.now())
            .diff(moment.utc(eggListData[i]?.last_claimed_at))
        )
        .asHours()
    );

    assetList.push({
      ...cardData,
      ...templateDetail,
      ...eggListData[i],
      eggtype: eggListData[i].eggtype,
      imgUrl: imagePathPoint + cardData?.template.immutable_data.img,
      hourDifference: hourDifference.toString(),
    });
  }

  return assetList;
}

export async function evolve(
  userProperties,
  activeUser,
  currentRequirement,
  selectedEggs,
  selectedNFT
) {
  const transactionData = [
    {
      contractAccount: tokenContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: breedContract,
        quantity: currentRequirement.tokenamount,
        memo: "",
      },
    },
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: breedContract,
        asset_ids: selectedEggs.map((se) => se.asset_id),
        memo: "",
      },
    },
    {
      contractAccount: breedContract,
      actionName: "eggclone",
      data: {
        account: activeUser,
        eggtypeid: currentRequirement.eggtypeid,
        eggmint_templateid: currentRequirement.eggminttemplate_id,
        eggasset_ids: selectedEggs.map((se) => se.asset_id),
        cloneasset_id: selectedNFT.asset_id,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function unstakeEgg(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: breedContract,
      actionName: "unstake",
      data: {
        account: activeUser,
        asset_id: assetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function unstakeAllEggy(userProperties, activeUser) {
  const transactionData = [
    {
      contractAccount: breedContract,
      actionName: "unstakeall",
      data: {
        account: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function unstakeByType(userProperties, activeUser, eggyTypeId) {
  const transactionData = [
    {
      contractAccount: breedContract,
      actionName: "unstaketype",
      data: {
        account: activeUser,
        eggtype: eggyTypeId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function claimAllEggy(userProperties, activeUser, assetIds = []) {
  const transactionData = [
    {
      contractAccount: breedContract,
      actionName: "claim",
      data: {
        account: activeUser,
        asset_ids: assetIds,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  console.log("😎😎", response);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function claimByEggType(userProperties, activeUser, eggTypeId) {
  const transactionData = [
    {
      contractAccount: breedContract,
      actionName: "claimbytype",
      data: {
        account: activeUser,
        eggtype: eggTypeId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function stakeEggy(userProperties, activeUser, assetIds = []) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: breedContract,
        asset_ids: assetIds,
        memo: "",
      },
    },
    {
      contractAccount: breedContract,
      actionName: "stake",
      data: {
        account: activeUser,
        asset_ids: assetIds,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function getStakedBonus(activeUser, rows = 1000) {
  const stakedPets = [];
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlbody = {
    json: true,
    code: breedContract,
    scope: activeUser,
    table: "stakes1",
    limit: rows,
  };

  const options = (urlBody) => {
    return {
      method: "POST",
      body: JSON.stringify(urlBody),
      mode: "cors",
      credentials: "omit",
    };
  };

  const response = await fetch(url, options(urlbody));

  if (response.status === 200) {
    const responseJson = await response.json();
    const perksResponseData = responseJson.rows;

    for (let i = 0; i < perksResponseData.length; i++) {
      const cardDetailResponse = await getAssetDetails(
        perksResponseData[i]?.asset_id
      );
      if (cardDetailResponse.success) {
        const lastClaimed = perksResponseData[i].last_claimed_at;
        const perkDetails = cardDetailResponse.responseData.data;
        const hourDifference = Math.floor(
          moment
            .duration(moment.utc(Date.now()).diff(moment.utc(lastClaimed)))
            .asHours()
        );
        const hourRange = hourDifference > 0 ? hourDifference : 0;
        const hourlyExp = parseInt(perkDetails.data.hourly_exp);
        const isPortrait = perksResponseData[i]?.portrait === 1 ? true : false;

        stakedPets.push({
          ...perkDetails,
          hourRange,
          hourlyExp,
          hourDifference,
          lastClaimed,
          isPortrait,
        });
      }
    }
    return { success: true, responseData: stakedPets };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
