



const dataRoadmap = [
    {
        id: 1,
        title: 'PHASE 1',
        class: 'left',
        list : [
            {
                id: 1,
                text: 'Launch of GNO CITY v1',
            },
            {
                id: 2,
                text: 'Skills and Level Ups',
            },
            {
                id: 3,
                text: 'PvP Launch',
            },
            {
                id: 4,
                text: 'PvP Bug Fixes',
            },
        ]
    
    },
    {
        id: 2,
        title: 'PHASE 2',
        class: 'right',
        list : [
            {
                id: 1,
                text: 'Self Hosted API',
            },
            {
                id: 2,
                text: 'Server Speed Increase',
            },
            {
                id: 3,
                text: 'Achievements',
            },
            {
                id: 4,
                text: 'Racing Mode Launch',
            },
        ]
    
    },  
    {
        id: 3,
        title: 'PHASE 3',
        class: 'left',
        list :  [
            {
                id: 1,
                text: 'Perks Launch',
            },
            {
                id: 2,
                text: 'Egg Evolution Launch',
            },
            {
                id: 3,
                text: 'Tournaments Launch',
            },
            {
                id: 4,
                text: 'Bug Fixes',
            },
        ]
    
    },      
    {
        id: 4,
        title: 'PHASE 4 - CURRENT',
        class: 'right',
        list : [
            {
                id: 1,
                text: 'GNO CITY v2 Launch',
            },
            {
                id: 2,
                text: '3D Map Launch',
            },
            {
                id: 3,
                text: 'Smart Contract Modifications',
            },
            {
                id: 4,
                text: 'New Smart Contracts',
            },
        ]
    
    },  
    {
        id: 5,
        title: 'PHASE 5 - FUTURE',
        class: 'left',
        list : [
            {
                id: 1,
                text: 'Potential Crosschain Technology',
            },
            {
                id: 2,
                text: 'Establish Partnerships',
            },
            {
                id: 3,
                text: 'New Features + Utility',
            },
            {
                id: 4,
                text: '3D Mode Expansions',
            },
        ]
    
    },  
    {
        id: 6,
        title: 'PHASE 6 - FUTURE',
        class: 'right',
        list : [
            {
                id: 1,
                text: 'TBD',
            },
            {
                id: 2,
                text: 'TBD',
            },
            {
                id: 3,
                text: 'TBD',
            },
            {
                id: 4,
                text: 'TBD',
            },
        ]
    
    },  

]

export default dataRoadmap;