import Page404 from "../pages/404";
import About01 from "../pages/About01";
import About02 from "../pages/About02";
import Blog from "../pages/Blog";

import BlogDetails from "../pages/BlogDetails";

import Collection from "../pages/Collection";
import CommingSoon from "../pages/CommingSoon";

import Contact from "../pages/Contact";

import Faq01 from "../pages/Faq01";
import Faq02 from "../pages/Faq02";

import LandingPage from "../pages/LandingPage";
import Home01 from "../pages/Home01";
import Home02 from "../pages/Home02";
import Home03 from "../pages/Home03";
import ItemDetails from "../pages/ItemDetails";

import Login from "../pages/Login";
import OurTeam from "../pages/OurTeam";

import RoadMap01 from "../pages/RoadMap01";
import RoadMap02 from "../pages/RoadMap02";
import RoadMap03 from "../pages/RoadMap03";
import SignUp from "../pages/SignUp";
import Testimonials from "../pages/Testimonials";

const routeComponents = [
  { path: "/", component: <LandingPage /> },
  { path: "/home-v2", component: <Home02 /> },
  { path: "/home-v3", component: <Home03 /> },
  { path: "/about-v1", component: <About01 /> },
  { path: "/about-v2", component: <About02 /> },
  { path: "/roadmap-v1", component: <RoadMap01 /> },
  { path: "/roadmap-v2", component: <RoadMap02 /> },
  { path: "/roadmap-v3", component: <RoadMap03 /> },
  { path: "/signin", component: <Login /> },
  { path: "/signup", component: <SignUp /> },
  { path: "/faq-v1", component: <Faq01 /> },
  { path: "/faq-v2", component: <Faq02 /> },
  { path: "/our-team", component: <OurTeam /> },
  { path: "/collection", component: <Collection /> },
  { path: "/testimonial", component: <Testimonials /> },
  { path: "/item-details", component: <ItemDetails /> },
  { path: "/comming-soon", component: <CommingSoon /> },
  { path: "/page-404", component: <Page404 /> },
  { path: "/blog", component: <Blog /> },
  { path: "/blog-details", component: <BlogDetails /> },
  { path: "/contact", component: <Contact /> },
];

const routes = {
  landing: "/",
  lineup: "/line-up",
  pvp: "/pvp",
  health: "/health-center",
  skills: "/apply-skills",
  gnomeSkills: "/gnome-skills",
  gnomeSkills2: "/gnome-skills2",
  complexSkills: "/complex-skills",
  cykoSkills: "/cykoko-skills",
  perks: "/perks",
  pets: "/pets",
  petsExperience: "/pets-experience",
  racing: "/racing",
  bonus: "/bonus",
  eggy: "/eggy",
  mining: "/mining",
  racingVehicles: "/racing-vehicles",
  racingCharacters: "/racing-drivers",
  racingAddOns: "/racing-upgrades",
  marketplace: "/marketplace",
  evolution: "/evolution",
  land: "/gnome-land",
  miningTest: "/mining-test",
};

export default routes;
