import { useState, useEffect, Fragment } from "react";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal as GnocityModal } from "../../components/Modal";

import LinkButton from "../../components/button/LinkButton";

import { getTokenByContract } from "../../services/TokenService";
import {
  getUserLineUpList,
  getCardLineupDetails,
  getUserLineupCards,
  getUserStakedCards,
  getWeatherDetails,
} from "../../services/LineupService";
import {
  disableNFT,
  enableNFT,
  getMatchData,
  getMatchesByAccountName,
  setMint,
  userFindMatch,
} from "../../services/PvpService";

import route from "../../routes/routes";
import waxUrl from "../../shared/waxUrl";
import pvpConstants from "./constants";

import "./index.scss";
import { getPlayerDetails } from "../../services/PlayerService";
import PvpResultsModal from "./PvpResultsModal";

export default function PvpPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { maxCards, sliderBreakpoints, sliderAutoplay } = pvpConstants;

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [cardLoading, setCardLoading] = useState(null);
  const [playerMatch, setPlayerMatch] = useState(false);
  const [cardLineup, setCardLineup] = useState([]);
  const [matches, setMatches] = useState([]);

  const [matchResult, setMatchResult] = useState(null);

  async function _serviceInit(activeUser) {
    try {
      const tokens = await getTokenByContract(pvpContract);
      const userLineupResponse = await getUserLineupCards(activeUser);
      const stakedCardsResponse = await getUserStakedCards(activeUser);
      const weatherResponse = await getWeatherDetails(activeUser);

      const userMatchResponse = await getMatchesByAccountName(activeUser, 3);
      const userOpponentMatchResponse = await getMatchesByAccountName(
        activeUser,
        4
      );

      if (userLineupResponse.success) {
        const lineupCards = userLineupResponse.responseData;
        const tokensData = tokens.responseData;
        const stakedCards = stakedCardsResponse.responseData;
        const weatherBonus = weatherResponse.responseData;

        const response = await getCardLineupDetails(
          lineupCards,
          tokensData,
          stakedCards,
          weatherBonus
        );

        if (response.success) {
          setCardLineup([...response.responseData.lineup]);
        }
      }

      if (userMatchResponse.success) {
        const matches = userMatchResponse.responseData.rows;
        let detailedMatches = [];
        for (let match of matches) {
          const matchDataResponse = await getMatchData(match.match_id);
          if (matchDataResponse.success) {
            const matchResultData = matchDataResponse.responseData.rows[0];
            detailedMatches.push({ ...match, ...matchResultData });
          }
        }
        setMatches((m) => [...m, ...detailedMatches]);
      }

      if (userOpponentMatchResponse.success) {
        const matches = userOpponentMatchResponse.responseData.rows;
        let detailedMatches = [];
        for (let match of matches) {
          const matchDataResponse = await getMatchData(match.match_id);
          if (matchDataResponse.success) {
            const matchResultData = matchDataResponse.responseData.rows[0];
            detailedMatches.push({ ...match, ...matchResultData });
          }
        }
        setMatches((m) => [...m, ...detailedMatches]);
      }
    } catch (error) {
      console.log("🎶🎶🎶", error);
    }
  }

  async function _handleFindMatch(activeUser) {
    try {
      const userAttacked = await userFindMatch(activeUser);

      if (!userAttacked.success) {
        await setMint(activeUser);
        await userFindMatch(activeUser);
      }

      if (userAttacked.success) {
        await setTimeout(2000);
        const matchResponse = await getMatchesByAccountName(activeUser);
        const matchRows = matchResponse.responseData.rows;
        const matchData = matchResponse.responseData.rows[matchRows.length - 1];
        const matchDataResponse = await getMatchData(matchData?.match_id);

        if (
          matchDataResponse.success &&
          matchDataResponse.responseData.rows.length
        ) {
          const matchResultData = matchDataResponse.responseData.rows[0];
          const matchDetails = {
            ...matchData,
            ...matchResultData,
          };
          setMatchResult(matchDetails);
        }
      }

      if (!userAttacked.success) {
        setMessageOpen(true);
        setMessagePositive(false);
      }

      if (!userAttacked.success) {
        setMessage(
          `PVP ATTACK FAILED ${JSON.stringify(
            userAttacked.responseData?.error?.message
          )}`
        );
      }
    } catch (error) {
      console.log("🎶🎶🎶", error);
    }
  }

  async function _handleDisabledNFT(userProperties, activeUser, asset) {
    try {
      const response = await enableNFT(
        userProperties,
        activeUser,
        asset.asset_id
      );

      if (response.success) {
        const filteredNFTS = cardLineup.filter(
          (c) => c.asset_id !== asset.asset_id
        );
        const assetDetail = {
          ...asset,
          enabled: 1,
        };
        setCardLineup([...filteredNFTS, assetDetail]);

        setMessage(`${asset.cardName} IS ENABLED`);
        setMessageOpen(true);
        setMessagePositive(false);
      }

      if (!response.success) {
        setMessage(`${asset.cardName} CANNOT BE ENABLED`);
        setMessageOpen(true);
        setMessagePositive(false);
      }

      return response;
    } catch (error) {
      console.log("🎶🎶🎶🎂", error);
    }
  }

  async function _handleEnabledNFT(userProperties, activeUser, asset) {
    try {
      const response = await disableNFT(
        userProperties,
        activeUser,
        asset.asset_id
      );

      if (response.success) {
        const filteredNFTS = cardLineup.filter(
          (c) => c.asset_id !== asset.asset_id
        );
        const assetDetail = {
          ...asset,
          enabled: 0,
        };
        setCardLineup([...filteredNFTS, assetDetail]);

        setMessage(`${asset.cardName} IS DISABLED`);
        setMessageOpen(true);
        setMessagePositive(false);
      }

      if (!response.success) {
        setMessage(`${asset.cardName} CANNOT BE DISABLED`);
        setMessageOpen(true);
        setMessagePositive(false);
      }

      return response;
    } catch (error) {
      console.log("🎶🎶🎶🤷‍♀️", error);
    }
  }

  useEffect(() => {
    if (activeUser) {
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <Fragment>
      <PvpResultsModal
        isModalOpen={matchResult}
        wrapperClassName="pvp-page__modal-wrapper"
        currentPlayer={activeUser}
        matchData={matchResult}
        onModalClose={() => setMatchResult(null)}
      />

      <GnocityModal
        isOpen={messageOpen}
        className="pvp-page__message-wrapper"
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessageOpen(false);
          setMessage("");
        }}
      />

      <div className="pvp-page">
        <div className="tf-container">
          <article className="pvp-page__header-container">
            <section className="pvp-page__header-action">
              <h4 className="pvp-page__header-title">SET LINEUP</h4>
              <LinkButton
                url={route.lineup}
                className="pvp-page__action-btn tf-button"
                value={`VIEW MY LINEUP`}
              />
            </section>

            <section className="pvp-page__header-action">
              <h4 className="pvp-page__header-title">HEALTH CENTER</h4>
              <LinkButton
                url={route.health}
                className="pvp-page__action-btn tf-button"
                value={`FEED THEM`}
              />
            </section>

            <section className="pvp-page__header-action">
              <h4 className="pvp-page__header-title">SKILL RESET?</h4>
              <button className="pvp-page__action-btn tf-button">
                CLICK HERE
              </button>
            </section>

            <section className="pvp-page__header-action">
              <h4 className="pvp-page__header-title">START MATCHMAKING</h4>
              <button
                className="pvp-page__action-btn tf-button"
                onClick={async () => {
                  setPlayerMatch(true);
                  await _handleFindMatch(activeUser);
                  setPlayerMatch(false);
                }}
              >
                {playerMatch ? "LOADING" : "FIND A MATCH"}
              </button>
            </section>
          </article>
        </div>

        <div className="tf-container">
          <h4 className="pvp-page__title">current lineup</h4>
          <Swiper
            className="collection-1 visible pvp-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {cardLineup
              .filter((c) => {
                return c.level;
              })
              .map((c) => {
                console.log("🎶", c);
                const isEnabled = c.enabled;
                return (
                  <SwiperSlide
                    key={c.asset_id}
                    onClick={async () => {
                      if (!isEnabled) {
                        const response = await _handleDisabledNFT(
                          userProperties,
                          activeUser,
                          c
                        );
                      }

                      if (isEnabled) {
                        const response = await _handleEnabledNFT(
                          userProperties,
                          activeUser,
                          c
                        );
                      }
                    }}
                  >
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="lineup-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name">
                          {!c?.enabled ? (
                            <h6 className="pvp-page__text pvp-page__text--red">
                              DISABLED
                            </h6>
                          ) : (
                            <h6 className="pvp-page__text pvp-page__text--green">
                              ENABLED
                            </h6>
                          )}
</h6>

                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>

        <div className="tf-container">
          <h4 className="pvp-page__title">recent matches</h4>
          <div className="pvp-page__match-container">
            {matches.map((m, i) => {
              const matchIsDone = m.done === 1;
              const matchId = m.match_id;
              const matchDate = m.date;
              const didUserWin = activeUser === m.winner;
              const fromCurrentUser = activeUser === m.player;

              return (
                <div className="pvp-page__match">
                  {/* <img
                  className="pvp-page__match-card"
                  src={`${baseUrlWaxAtomicAssets}/atomicassets/v1/assets/${m?.player_card}`}
                  alt="match-img"
                />

                <img
                  className="pvp-page__match-card"
                  src={`${baseUrlWaxAtomicAssets}/atomicassets/v1/assets/${m?.opponent_card}`}
                  alt="match-img"
                /> */}

                  <div
                    className="pvp-page__detail-container"
                    onClick={() => setMatchResult(m)}
                  >
                    <h4 className="pvp-page__match-result">
                      {didUserWin ? "WIN" : `LOST`}
                    </h4>
                    <div className="pvp-page__match-red">{m.opponent}</div>
                    <div className="pvp-page__match-blue">{m.player}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
