import {
  baseUrlWaxAtomicAssets,
  baseUrlWaxAtomicAssets2,
  baseUrlWaxCheckList,
} from "../shared/constants";

export async function getTemplateDetails(collectionId, templateId) {
  const url = `${baseUrlWaxAtomicAssets}/atomicassets/v1/templates/${collectionId}/${templateId}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getAssetDetails(assetId) {
  const url = `${baseUrlWaxAtomicAssets}/atomicassets/v1/assets/${assetId}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getPlayerAssets(accountName) {
  const url = `${baseUrlWaxAtomicAssets}/atomicassets/v1/accounts/${accountName}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getAssetInfo(assetId) {
  const url = `${baseUrlWaxCheckList}/v1/assets/${assetId}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getPlayerAssetsByTemplate(
  accountName,
  collectionName,
  schemaName,
  templateId,
  sortBy,
  orderBy = "desc",
  page = 1,
  limit = 2000
) {
  const url = `${baseUrlWaxAtomicAssets}/atomicassets/v1/assets?collection_name=${collectionName}&schema_name=${schemaName}&template_id=${templateId}&owner=${accountName}&page=${page}&limit=${limit}&order=${orderBy}&sort=${sortBy}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getPlayerAssetsByTemplateOnly(
  accountName,
  collectionName,
  templateId,
  sortBy = "asset_id",
  orderBy = "desc",
  page = 1,
  limit = 5000
) {
  const url = `${baseUrlWaxAtomicAssets2}/atomicassets/v1/assets?collection_name=${collectionName}&template_id=${templateId}&owner=${accountName}&page=${page}&limit=${limit}&order=${orderBy}&sort=${sortBy}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getCardAssets(
  accountName,
  collectionName,
  page = 1,
  limit = 1000,
  order = "asc",
  sortBy = "asset_id"
) {
  const url = `${baseUrlWaxCheckList}/v1/assets?owner=${accountName}&collection_name=${collectionName}&page=${page}&limit=${limit}&order=${order}&sort=${sortBy}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getCardAssetsBySchema(
  accountName,
  collectionName,
  schemaName,
  page,
  limit,
  order = "desc",
  sortBy = "asset_id"
) {
  const url = `${baseUrlWaxCheckList}/v1/assets?owner=${accountName}&collection_name=${collectionName}&schema_name=${schemaName}&page=${page}&limit=${limit}&order=${order}&sort=${sortBy}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
