import moment from "moment";

import { getCardAssetsBySchema } from "./AssetService";
import { getAssetDetails } from "./AssetService";
import transactionSigner from "./TransactionSigner";

import {
  getBonusExpByMint,
  getBonusExpByVariant,
} from "../shared/bonusExpUtil";

import { baseUrlIpfs } from "../shared/constants";
import waxUrl from "../shared/waxUrl";

const { contract, rpcEndpoint, pvpContract, bonusContract } = waxUrl;

const STATUS_OK = 200;
const HEALTH_BASELINE = 21;

export async function saveLineup(userProperties, activeUser, addedAssetIds) {
  const transactionData = [
    {
      contractAccount: contract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: pvpContract,
        asset_ids: addedAssetIds,
        memo: "",
      },
    },
    {
      contractAccount: pvpContract,
      actionName: "addcards",
      data: {
        account: activeUser,
        asset_ids: addedAssetIds,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function withdrawLineup(userProperties, activeUser) {
  const transactionData = [
    {
      contractAccount: pvpContract,
      actionName: "withdraw",
      data: {
        account: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function healAsset(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: pvpContract,
      actionName: "regainhealth",
      data: {
        account: activeUser,
        asset_id: assetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function getAllCards(
  accountName,
  token = [],
  collectionName = "",
  schemaName = "",
  continuousLimit = 1000,
  format = true,
  continous = true
) {
  try {
    let page = 1;
    const cards = [];

    const _getCardAssets = async (
      accountName,
      collectionName,
      schemaName,
      page,
      continuousLimit
    ) => {
      const cardsResponse = await getCardAssetsBySchema(
        accountName,
        collectionName,
        schemaName,
        page,
        continuousLimit
      );
      const additionalCards = cardsResponse.responseData.data;
      cards.push(...additionalCards);

      if (cardsResponse.success && additionalCards.length >= continuousLimit) {
        page += 1;
        await _getCardAssets(
          accountName,
          collectionName,
          schemaName,
          page,
          continuousLimit
        );
      }
    };

    await _getCardAssets(
      accountName,
      collectionName,
      schemaName,
      page,
      continuousLimit
    );

    if (format) {
      const formattedCards = cards.map((c) => {
        const gnokenPerWin = _getGnokenPerWin(c, token);

        return {
          gnokenPerWin,
          level: c.mutable_data?.level ? c.mutable_data?.level : null,
          timestamp: c.data?.timestamp ? c.data?.timestamp : null,
          mint_number: c?.template_mint ? c.template_mint : null,
          assetData: c,
          ..._getLineUpFormat(c),
        };
      });
      return { success: true, responseData: formattedCards };
    }

    return { success: true, responseData: { cards } };
  } catch (e) {
    console.log("🎶🎶", e);
    return { success: false, responseData: e };
  }
}

export async function getUserLineupCards(accountName, rows = 100) {
  try {
    const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
    const cardsUrlBody = {
      json: true,
      scope: pvpContract,
      code: pvpContract,
      table: "cards",
      upper_bound: accountName,
      lower_bound: accountName,
      show_payer: false,
      key_type: "name",
      index_position: 3,
      limit: rows,
      reverse: false,
    };

    const options = (urlBody) => {
      return {
        method: "POST",
        body: JSON.stringify(urlBody),
        mode: "cors",
        credentials: "omit",
      };
    };

    const response = await fetch(url, options(cardsUrlBody));
    if (response.status === 200) {
      const responseJson = await response.json();
      const rows = responseJson?.rows;

      return { success: true, responseData: rows };
    } else {
      const json = await response.json();
      return { success: false, responseData: json };
    }
  } catch (error) {}
}

export async function getUserStakedCards(accountName, rows = 100) {
  try {
    const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
    const stakingUrlBody = {
      json: true,
      code: bonusContract,
      scope: accountName,
      table: "stakes1",
      limit: rows,
    };

    const options = (urlBody) => {
      return {
        method: "POST",
        body: JSON.stringify(urlBody),
        mode: "cors",
        credentials: "omit",
      };
    };

    const response = await fetch(url, options(stakingUrlBody));
    if (response.status === 200) {
      const responseJson = await response.json();
      const rows = responseJson?.rows;

      return { success: true, responseData: rows };
    }
    if (response.status > 200 && response.status <= 299) {
      const responseJson = await response.json();
      const rows = responseJson?.rows;

      return { success: true, responseData: rows };
    } else {
      const json = await response.json();
      return { success: false, responseData: json };
    }
  } catch (error) {}
}

export async function getWeatherDetails(accountName, rows = 100) {
  try {
    const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
    const weatherUrlBody = {
      json: true,
      scope: bonusContract,
      code: bonusContract,
      table: "weather",
      limit: 1,
    };

    const options = (urlBody) => {
      return {
        method: "POST",
        body: JSON.stringify(urlBody),
        mode: "cors",
        credentials: "omit",
      };
    };

    const response = await fetch(url, options(weatherUrlBody));
    if (response.status === 200) {
      const responseJson = await response.json();
      const rows = responseJson?.rows;
      const weatherBonus = parseInt(parseFloat(rows[0].boost) * 100);

      return { success: true, responseData: weatherBonus };
    }
    if (response.status > 200 && response.status <= 299) {
      const responseJson = await response.json();
      const rows = responseJson?.rows;
      const weatherBonus = parseInt(parseFloat(rows[0].boost) * 100);

      return { success: true, responseData: weatherBonus };
    } else {
      const json = await response.json();
      return { success: false, responseData: json };
    }
  } catch (error) {}
}

export async function getCardLineupDetails(
  lineupCards,
  token,
  stakedCards,
  weatherBonus,
  rows = 100
) {
  try {
    let cards = [];
    let lineup = [];

    for (let i = 0; i <= lineupCards.length; i++) {
      let card = lineupCards[i];
      const cardDetailResponse = await getAssetDetails(card?.asset_id);

      const cardData = cardDetailResponse.responseData.data;

      const bonusByMint = getBonusExpByMint(parseInt(cardData?.template_mint));
      const bonusByStaking = stakedCards.length * 5;

      const isComplexCard =
        cardData?.collection.collection_name === "gnomeseries1" ? false : true;
      const cardType = isComplexCard
        ? cardData?.template.immutable_data.Rarity
        : cardData?.template.immutable_data.Variant;
      const bonusByVariant = getBonusExpByVariant(cardType, isComplexCard);
      const gnokenPerWin = _getGnokenPerWin(cardData, token);

      const regen = cardData?.data.timestamp;
      const swap = card?.created_at;
      const nextHealthDate = _getLastRegenDiff(regen);
      const nextSwapDate = _getLastSwapDiff(swap);

      if (cardData) {
        const detail = {
          ...card,
          gnokenPerWin,
          next_health_date_in_hour: nextHealthDate,
          next_swap_data_in_hour: nextSwapDate,
          mint_number: cardData?.template_mint,
          bonusExp:
            bonusByVariant + bonusByStaking + bonusByMint + weatherBonus,
          lowHealth:
            !cardData.mutable_data.health ||
            cardData?.mutable_data?.health < 21,
          ..._getLineUpFormat(cardData),
        };
        const lineupDetail = {
          ...card,
          gnokenPerWin,
          assetData: cardData,
          next_health_date_in_hour: nextHealthDate,
          next_swap_data_in_hour: nextSwapDate,
          mint_number: cardData?.template_mint,
          bonusExp:
            bonusByVariant + bonusByStaking + bonusByMint + weatherBonus,
          lowHealth:
            !cardData?.mutable_data.health ||
            cardData?.mutable_data?.health < HEALTH_BASELINE,
          ..._getLineUpFormat(cardData),
        };
        cards.push(detail);
        lineup.push(lineupDetail);
      }
    }

    return { success: true, responseData: { cards, lineup } };
  } catch (error) {
    return { success: false, responseData: null };
  }
}

export async function getUserLineUpList(accountName, token = [], rows = 100) {
  const _getJson = async (response) => {
    return await response.json();
  };

  try {
    const url = `${rpcEndpoint}/v1/chain/get_table_rows`;

    const cardsUrlBody = {
      json: true,
      scope: pvpContract,
      code: pvpContract,
      table: "cards",
      upper_bound: accountName,
      lower_bound: accountName,
      show_payer: false,
      key_type: "name",
      index_position: 3,
      limit: rows,
      reverse: false,
    };

    const stakingUrlBody = {
      json: true,
      code: bonusContract,
      scope: accountName,
      table: "stakes1",
      limit: rows,
    };

    const weatherUrlBody = {
      json: true,
      scope: bonusContract,
      code: bonusContract,
      table: "weather",
      limit: 1,
    };

    const options = (urlBody) => {
      return {
        method: "POST",
        body: JSON.stringify(urlBody),
        mode: "cors",
        credentials: "omit",
      };
    };

    let weatherBonus = 0;

    const cardsResponse = await fetch(url, options(cardsUrlBody));
    const stakesResponse = await fetch(url, options(stakingUrlBody));
    const weatherResponse = await fetch(url, options(weatherUrlBody));

    // const [cardsResponse, tokenResponse, stakesResponse, weatherResponse] =
    //   await Promise.all([
    //     await fetch(url, options(cardsUrlBody)),
    //     await fetch(url, options(stakingUrlBody)),
    //     await fetch(url, options(weatherUrlBody)),
    //   ]);

    const cardsJson =
      cardsResponse.status === STATUS_OK && (await _getJson(cardsResponse));

    const stakesJson =
      stakesResponse.status === STATUS_OK && (await _getJson(stakesResponse));

    const weatherJson =
      weatherResponse.status === STATUS_OK && (await _getJson(weatherResponse));

    if (cardsResponse.status !== STATUS_OK)
      return {
        success: false,
        responseData: { cards: [], lineup: [] },
        block: "cards",
      };

    if (stakesResponse.status !== STATUS_OK)
      return {
        success: false,
        responseData: { cards: [], lineup: [] },
        block: "stakes",
      };

    if (weatherResponse.status !== STATUS_OK)
      return {
        success: false,
        responseData: { cards: [], lineup: [] },
        block: "weather",
      };

    const bonusByStaking = stakesJson.rows.length * 5;

    if (weatherJson?.rows && weatherJson?.rows.length > 0) {
      weatherBonus = parseInt(parseFloat(weatherJson.rows[0].boost) * 100);
    }

    if (cardsJson?.rows && cardsJson?.rows.length) {
      let cards = [];
      let lineup = [];

      const latestCards = cardsJson.rows.slice(0, 5);

      for (let i = 0; i <= latestCards.length; i++) {
        let card = latestCards[i];
        const cardDetailResponse = await getAssetDetails(card?.asset_id);
        const cardData = cardDetailResponse.responseData.data;
        const bonusByMint = getBonusExpByMint(
          parseInt(cardData?.template_mint)
        );
        const isComplexCard =
          cardData?.collection.collection_name === "gnomeseries1"
            ? false
            : true;
        const cardType = isComplexCard
          ? cardData?.template.immutable_data.Rarity
          : cardData?.template.immutable_data.Variant;
        const bonusByVariant = getBonusExpByVariant(cardType, isComplexCard);
        const gnokenPerWin = _getGnokenPerWin(cardData, token);

        const regen = cardData?.data.timestamp;
        const swap = card?.created_at;
        const nextHealthDate = _getLastRegenDiff(regen);
        const nextSwapDate = _getLastSwapDiff(swap);

        if (cardData) {
          const detail = {
            ...card,
            gnokenPerWin,
            next_health_date_in_hour: nextHealthDate,
            next_swap_data_in_hour: nextSwapDate,
            mint_number: cardData?.template_mint,
            bonusExp:
              bonusByVariant + bonusByStaking + bonusByMint + weatherBonus,
            lowHealth:
              !cardData.mutable_data.health ||
              cardData?.mutable_data?.health < 21,
            ..._getLineUpFormat(cardData),
          };
          const lineupDetail = {
            ...card,
            gnokenPerWin,
            assetData: cardData,
            next_health_date_in_hour: nextHealthDate,
            next_swap_data_in_hour: nextSwapDate,
            mint_number: cardData?.template_mint,
            bonusExp:
              bonusByVariant + bonusByStaking + bonusByMint + weatherBonus,
            lowHealth:
              !cardData?.mutable_data.health ||
              cardData?.mutable_data?.health < HEALTH_BASELINE,
            ..._getLineUpFormat(cardData),
          };
          cards.push(detail);
          lineup.push(lineupDetail);
        }
      }

      return { success: true, responseData: { cards, lineup } };
    }
  } catch (e) {
    console.log("🎶🎶", e);
    return { success: false, responseData: e };
  }
}

function _getLineUpFormat(cardData) {
  const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

  return {
    id: cardData.asset_id,
    asset_id: cardData.asset_id,
    templateData: cardData.template,
    imgUrl: imagePathPoint + cardData?.template.immutable_data.img,
    cardName: !cardData?.name ? "" : cardData?.name,
    title: cardData.name,
    health: cardData?.mutable_data.health
      ? parseInt(cardData?.mutable_data.health)
      : 0,
    info: {
      Level: `${
        cardData.mutable_data?.level ? cardData.mutable_data?.level : "0"
      }`,
      SpeedSkill: `${
        cardData?.mutable_data?.speed_skill
          ? cardData?.mutable_data?.speed_skill.toFixed(2)
          : 0
      }`,
      AssassinSkill: `${
        cardData?.mutable_data?.assassin_skill
          ? cardData?.mutable_data?.assassin_skill.toFixed(2)
          : 0
      }`,
      CombatSkill: `${
        cardData?.mutable_data?.combat_skill
          ? cardData?.mutable_data?.combat_skill.toFixed(2)
          : 0
      }`,
      AwarenessSkill: `${
        cardData?.mutable_data?.awareness_skill
          ? cardData?.mutable_data?.awareness_skill.toFixed(2)
          : 0
      }`,
      ManipulationSkill: `${
        cardData?.mutable_data?.manipulation_skill
          ? cardData?.mutable_data?.manipulation_skill.toFixed(2)
          : 0
      }`,
      DefenseSkill: `${
        cardData?.mutable_data?.defense_skill
          ? cardData?.mutable_data?.defense_skill.toFixed(2)
          : 0
      }`,
      WillpowerSkill: `${
        cardData?.mutable_data?.willpower_skill
          ? cardData?.mutable_data?.willpower_skill.toFixed(2)
          : 0
      }`,
      IntelligenceSkill: `${
        cardData?.mutable_data?.intelligence_skill
          ? cardData?.mutable_data?.intelligence_skill.toFixed(2)
          : 0
      }`,
    },
  };
}

function _getGnokenPerWin(asset, arrayVariants) {
  const gnokenPerWin = "3.00 GNOKEN";
  const gnomeCollectionName = "gnomeseries1";
  const complexCollectionName = "complexserie";
  const cykoCollectionName = "cykokobattle";

  const variant = asset?.template?.immutable_data?.Variant;
  const rarity = asset?.template?.immutable_data?.Rarity;

  const collection_name = asset?.collection ? asset?.collection : "";

  if (arrayVariants.length === 0) return gnokenPerWin;

  if (collection_name === gnomeCollectionName && variant) {
    const variantKey = arrayVariants.find((v) => v.key === variant);
    if (variantKey) return variantKey.value;
  }

  if (collection_name === gnomeCollectionName && rarity) {
    const rarityKey = arrayVariants.find((v) => v.key === rarity);
    if (rarityKey) return rarityKey.value;
  }

  if (collection_name === complexCollectionName && rarity) {
    const rarityKey = arrayVariants.find((v) => v.key === rarity);
    if (rarityKey) return rarityKey.value;
  }

  return gnokenPerWin;
}

function _getLastRegenDiff(lastRegen) {
  let lastRegenDiff = 0;
  const dailyHours = 24;
  const secondInMilli = 1000;
  const parsedRegen = parseInt(lastRegen);

  const hourDiff = Math.floor(
    moment
      .duration(moment.utc(Date.now()).diff(parsedRegen * secondInMilli))
      .asHours()
  );
  if (hourDiff < dailyHours) {
    lastRegenDiff = dailyHours - hourDiff;
  }

  return lastRegenDiff;
}

function _getLastSwapDiff(lastSwap) {
  let lastSwapDiff = 0;
  const swapHour = 2;

  const hourDiff = Math.floor(
    moment.duration(moment.utc(Date.now()).diff(moment.utc(lastSwap))).asHours()
  );
  if (hourDiff < swapHour) {
    lastSwapDiff = swapHour - hourDiff;
  }

  return lastSwapDiff;
}
