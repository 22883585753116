import waxUrl from "../shared/waxUrl";
import { apiBaseUrl } from "../shared/constants";
const { rpcEndpoint, storyContract, tokenContract } = waxUrl;

export async function getuserToken(activeUser) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    limit: 1,
    code: tokenContract,
    scope: activeUser,
    table: "accounts",
    lower_bound: "GNOKEN",
    upper_bound: "GNOKEN",
    json: true,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);

  if (response.status === 200) {
    const json = await response.json();
    const data = json.rows[0].variants;
    return { success: true, responseData: data };
  }
}

export async function getTokenByContract(contract, rows = 100) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    scope: contract,
    code: contract,
    table: "tokenrewards",
    limit: rows,
    reverse: false,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);

  if (response.status === 200) {
    const json = await response.json();
    const data = json.rows[0].variants;
    return { success: true, responseData: data };
  }
}

export async function getAllotedExp(activeUser, rows = 1) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    limit: 1,
    code: storyContract,
    scope: storyContract,
    table: "allotedexp",
    lower_bound: activeUser,
    upper_bound: activeUser,
    json: true,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
