const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "PvP",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "PvP Arena",
        links: "/pvp",
      },
      {
        id: 2,
        sub: "Lineup",
        links: "/line-up",
      },
      {
        id: 3,
        sub: "Health Center",
        links: "/health-center",
      },
      {
        id: 4,
        sub: "Perks",
        links: "/perks",
      },
      {
        id: 5,
        sub: "Pets",
        links: "/pets",
      },
      {
        id: 6,
        sub: "Skills",
        links: "/apply-skills",
      },
      {
        id: 7,
        sub: "Bonus Area",
        links: "/bonus",
      },
    ],
  },
  {
    id: 4,
    name: "Racing",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Racing Hub",
        links: "/racing",
      },
      {
        id: 2,
        sub: "Perks",
        links: "/perks",
      },
      {
        id: 3,
        sub: "Pets",
        links: "/pets",
      },
      {
        id: 4,
        sub: "Skills",
        links: "/apply-skills",
      },
      {
        id: 5,
        sub: "Bonus Area",
        links: "/bonus",
      },
    ],
  },
  {
    id: 5,
    name: "Mining",
    links: "/mining-test",
  },
  {
    id: 6,
    name: "Evolution",
    links: "/evolution",
    namesub: [
      {
        id: 1,
        sub: "Egg Staking",
        links: "/eggy",
      },
    ],
  },
  {
    id: 7,
    name: "Marketplace",
    links: "/marketplace",
  },
];

export default menus;
