import React from "react";
import classNames from "classnames";

import dataAbout from "../../assets/fake-data/data-about";
import dataBanner from "../../assets/fake-data/data-banner";
import dataCollection from "../../assets/fake-data/data-collection";
import dataFaqs from "../../assets/fake-data/data-faq";
import dataPartner from "../../assets/fake-data/data-partner";
import dataRoadmap from "../../assets/fake-data/data-roadmap";
import dataTeam from "../../assets/fake-data/data-team";
import dataWork from "../../assets/fake-data/data-work";

import About1 from "../../components/about/About1";
import Banner01 from "../../components/banner/Banner01";

import Collection from "../../components/collection/Collection";

import Faqs from "../../components/faqs/Faqs";
import Footer from "../../components/footer";

import Logo from "../../components/logo/Logo";
import Partner from "../../components/partner/Partner";
import RoadMap from "../../components/roadmap/RoadMap";
import Team from "../../components/team/Team";

import Work from "../../components/work/Work";

export default function LandingPage({
  type = 1,
  activeUser,
  showWaxModal,
  onUserLogout,
}) {
  const landingClass = classNames({
    "home-1": type === 1,
    "home-2": type === 2,
    "home-3": type === 3,
  });
  return (
    <div className={landingClass}>
      <Banner01
        data={dataBanner}
        showWaxModal={showWaxModal}
        activeUser={activeUser}
        onUserLogout={onUserLogout}
      />

      <Logo />

      <About1 data={dataAbout} />

      <Collection data={dataCollection} />

      <RoadMap data={dataRoadmap} />

      <Partner data={dataPartner} />

      <Footer />
    </div>
  );
}
