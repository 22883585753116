import { useEffect, useState } from "react";

function getSavedValue(key, initialValue) {
  const savedValue = JSON.parse(localStorage.getItem(key));

  if (savedValue) return savedValue;
  if (initialValue instanceof Function) initialValue();

  return initialValue;
}

export default function useLocalStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    return getSavedValue(key, initialValue);
  });

  useEffect(() => {
    const storageValue = JSON.stringify(value);
    localStorage.setItem(key, storageValue);
  }, [value]);

  return [value, setValue];
}
