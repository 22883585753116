import React, { useState, useEffect, Fragment } from "react";

import { Link, NavLink } from "react-router-dom";
import menus from "../../pages/menu";
import { Dropdown } from "react-bootstrap";
import { Modal as GnocityModal } from "../Modal";

import "./styles.scss";
import logo from "../../assets/images/gno-city-nft-game.png";
import logodark from "../../assets/images/gno-city-nft-game.png";

import DarkMode from "./DarkMode";

export default function Header({
  userProperties,
  activeUser,
  showWaxModal,
  onUserLogout,
  messageOpen = false,
  messagePositive = true,
  message = "",
  onMessageClose,
}) {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  return (
    <Fragment>
      <GnocityModal
        messagePositive={messagePositive}
        modalMessage={message}
        isOpen={messageOpen}
        onModalClose={() => onMessageClose}
      />

      <header className={`header ${scroll ? "is-fixed" : ""}`}>
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div id="site-header-inner">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="main-logo">
                      <img
                        id="logo_header"
                        className="logo-dark"
                        src={logodark}
                        width="150"
                        alt="GNO City"
                      />
                      <img
                        id="logo_header"
                        className="logo-light"
                        src={logo}
                        width="150"
                        alt="GNO City"
                      />
                    </Link>
                  </div>
                </div>

                <div className="header-center">
                  <nav
                    id="main-nav"
                    className={`main-nav ${menuActive ? "active" : ""}`}
                  >
                    <ul id="menu-primary-menu" className="menu">
                      {menus.map((data, idx) => (
                        <li
                          key={idx}
                          onClick={() => handleDropdown(idx)}
                          className={`menu-item ${
                            data.namesub ? "menu-item-has-children" : ""
                          } ${activeIndex === idx ? "active" : ""}`}
                        >
                          <Link to={data.links}>{data.name}</Link>
                          {data.namesub && (
                            <ul className="sub-menu">
                              {data.namesub.map((submenu) => (
                                <li key={submenu.id} className="menu-item">
                                  <NavLink to={submenu.links}>
                                    {submenu.sub}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>

                <div className="header-right">
                  <DarkMode />

                  <a
                    href="https://discord.com/invite/gWzsd5efcM"
                    className="tf-button discord"
                  >
                    <i className="icon-fl-vt"></i>
                    <span>DISCORD</span>
                  </a>

                  {!activeUser ? (
                    <Link
                      to="#"
                      className="tf-button connect"
                      data-toggle="modal"
                      data-target="#popup_bid"
                      onClick={() => showWaxModal()}
                    >
                      {" "}
                      <i className="icon-fl-wallet"></i>
                      <span>PLAY NOW</span>
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className="tf-button connect"
                      data-toggle="modal"
                      data-target="#popup_bid"
                      onClick={() => onUserLogout()}
                    >
                      {" "}
                      <span>{activeUser}</span>
                    </Link>
                  )}

                  <div
                    className={`mobile-button ${menuActive ? "active" : ""}`}
                    onClick={handleMenuActive}
                  >
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
}
