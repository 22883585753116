import transactionSigner from "./TransactionSigner";
import waxUrl from "../shared/waxUrl";
import { getAssetDetails, getTemplateDetails } from "./AssetService";
import { baseUrlIpfs } from "../shared/constants";

const {
  rpcEndpoint,
  salesContract,
  atomicContract,
  tokenContract,
  shopContract,
} = waxUrl;

export async function buyNFT(
  userProperties,
  activeUser,
  collectionId,
  nftTemplate,
  price = 0.0
) {
  const transactionData = [
    {
      contractAccount: collectionId,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: "gnocityshops",
        quantity: price,
        memo: nftTemplate,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function buyCard(
  userProperties,
  activeUser,
  assetId,
  price = 0.0
) {
  const transactionData = [
    {
      contractAccount: tokenContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: salesContract,
        quantity: price,
        memo: assetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function changeCardPrice(
  userProperties,
  activeUser,
  assetId,
  price = 0.0
) {
  const parsedAmount = parseInt(price).toFixed(4);
  const transactionData = [
    {
      contractAccount: salesContract,
      actionName: "modprice",
      data: {
        owner: activeUser,
        asset_id: assetId,
        amount: parsedAmount + " GNOKEN",
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function delistCard(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: salesContract,
      actionName: "dellist",
      data: {
        owner: activeUser,
        asset_id: assetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function listCard(
  userProperties,
  activeUser,
  assetId,
  price = 0.0
) {
  const parsedAmount = parseInt(price).toFixed(4);
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: salesContract,
        asset_ids: [assetId],
        memo: "",
      },
    },
    {
      contractAccount: salesContract,
      actionName: "addlist",
      data: {
        owner: activeUser,
        asset_id: assetId,
        amount: parsedAmount + " GNOKEN",
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function getMarketListings(rows = 10) {
  const marketListings = [];
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
  const urlbody = {
    json: true,
    code: salesContract,
    scope: salesContract,
    table: "assetlist",
    limit: rows,
  };

  const options = (urlBody) => {
    return {
      method: "POST",
      body: JSON.stringify(urlBody),
      mode: "cors",
      credentials: "omit",
    };
  };

  const response = await fetch(url, options(urlbody));

  if (response.status === 200) {
    const responseJson = await response.json();
    const perksResponseData = responseJson.rows;

    for (let i = 0; i < perksResponseData.length; i++) {
      const cardDetailResponse = await getAssetDetails(
        perksResponseData[i]?.asset_id
      );
      if (cardDetailResponse.success) {
        const lastupdate = perksResponseData[i].last_claimed_at;
        const listingDetail = cardDetailResponse.responseData.data;
        const imgUrl =
          imagePathPoint + listingDetail?.template?.immutable_data.img;
        const amount = perksResponseData[i].amount;
        const owner = perksResponseData[i].owner;

        marketListings.push({
          ...listingDetail,
          lastupdate,
          amount,
          owner,
          imgUrl,
        });
      }
    }
    return { success: true, responseData: marketListings };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getShopListings(rows = 10) {
  const marketListings = [];
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
  const urlbody = {
    json: true,
    code: shopContract,
    scope: shopContract,
    table: "nftstore",
    limit: rows,
  };

  const options = (urlBody) => {
    return {
      method: "POST",
      body: JSON.stringify(urlBody),
      mode: "cors",
      credentials: "omit",
    };
  };

  const response = await fetch(url, options(urlbody));

  if (response.status === 200) {
    const responseJson = await response.json();
    const perksResponseData = responseJson.rows;

    for (let i = 0; i < perksResponseData.length; i++) {
      const cardDetailResponse = await getTemplateDetails(
        perksResponseData[i]?.collection,
        perksResponseData[i]?.nft_template
      );
      if (cardDetailResponse.success) {
        const lastupdate = perksResponseData[i].last_claimed_at;
        const listingDetail = cardDetailResponse.responseData.data;
        const imgUrl = imagePathPoint + listingDetail.immutable_data.img;
        const amount = perksResponseData[i].amount;
        const owner = perksResponseData[i].owner;

        marketListings.push({
          ...listingDetail,
          lastupdate,
          amount,
          owner,
          imgUrl,
        });
      }
    }
    return { success: true, responseData: marketListings };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
