import img1 from '../images/product/complex3.png'
import img2 from '../images/product/racing1.png'
import img3 from '../images/product/racing2.png'
import img4 from '../images/product/gnome1.png'
import img5 from '../images/product/gnome2.png'
import img6 from '../images/product/gnome3.png'
import img7 from '../images/product/gnocity1.png'
import img8 from '../images/product/gnocity2.png'
import img9 from '../images/product/gnocity3.png'
import img10 from '../images/product/gnocity4.png'
import img11 from  '../images/product/gnocity5.png'
import img12 from '../images/product/bb1.png'
import img13 from '../images/product/bb2.png'
import img14 from '../images/product/complex1.png'
import img15 from '../images/product/complex2.png'
import img16 from '../images/product/racing1.png'
import img17 from '../images/product/racing1.png'
import img18 from '../images/product/racing1.png'
import img19 from '../images/product/racing1.png'
import img20 from '../images/product/racing1.png'
import img21 from '../images/product/racing1.png'
import img22 from '../images/product/racing1.png'
import img23 from '../images/product/racing1.png'
import img24 from '../images/product/racing1.png'
import img25 from '../images/product/racing1.png'
import img26 from '../images/product/racing1.png'


const dataCollection = [
    {
        id: 1,
        img: img1,
        title: 'Collectibles',
    },
    {
        id: 2,
        img: img2,
        title: 'Racing Vehicles',
    },
    {
        id: 3,
        img: img3,
        title: 'Racing Upgrades',
    },
    {
        id: 4,
        img: img4,
        title: 'Gnome Characters Series 1',
    },
    {
        id: 5,
        img: img5,
        title: 'Gnome Characters Series 2',
    },
    {
        id: 6,
        img: img6,
        title: 'Skill Upgrades',
    },
    {
        id: 7,
        img: img7,
        title: 'Perk Upgrades',
    },
    {
        id: 8,
        img: img8,
        title: 'Land Ownership',
    },
    {
        id: 9,
        img: img9,
        title: 'Evolution Eggs',
    },
    {
        id: 10,
        img: img10,
        title: 'Net Gun',
    },
    {
        id: 11,
        img: img11,
        title: 'GNO CITY Healing & Food',
    },
    {
        id: 12,
        img: img12,
        title: 'Materials',
    },
    {
        id: 13,
        img: img13,
        title: 'Homes',
    },
    {
        id: 14,
        img: img14,
        title: 'Complex Series Pets',
    },
    {
        id: 15,
        img: img15,
        title: 'Complex Series Characters',
    },
    {
        id: 16,
        img: img16,
        title: 'sKISIRS#2',
    },
    {
        id: 17,
        img: img17,
        title: 'sKISIRS#2',
    },
    {
        id: 18,
        img: img18,
        title: 'sKISIRS#2',
    },
    {
        id: 19,
        img: img19,
        title: 'sKISIRS#2',
    },
    {
        id: 20,
        img: img20,
        title: 'sKISIRS#2',
    },
    {
        id: 21,
        img: img21,
        title: 'sKISIRS#2',
    },
    {
        id: 22,
        img: img22,
        title: 'sKISIRS#2',
    },
    {
        id: 23,
        img: img23,
        title: 'sKISIRS#2',
    },
    {
        id: 24,
        img: img24,
        title: 'sKISIRS#2',
    },
    {
        id: 25,
        img: img25,
        title: 'sKISIRS#2',
    },
    {
        id: 26,
        img: img26,
        title: 'sKISIRS#2',
    },


   
]

export default dataCollection;