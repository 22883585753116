import React from "react";
import { Link } from "react-router-dom";

function Button({ url, value, className = "", customStyles }) {
  return (
    <Link to={url} className={`tf-button ${className}`} style={customStyles}>
      {value}
    </Link>
  );
}

export default Button;
