const numberRegEx = new RegExp(/\B(?=(\d{3})+(?!\d))/g);

export function removeSuffx(phrase, removalPhrase) {
  const regEx = new RegExp(removalPhrase);
  return phrase.toString().replace(regEx, "");
}

export const handleLongString = (name, limit) =>
  name && (name.length > limit ? `${name.substring(0, limit)}...` : name);

export const numberWithCommas = (number) => {
  // return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const numParts = number.toString().split(".");
  const wholeNumbers = numParts[0].replace(numberRegEx, ",");
  const decimalNumbers = numParts[1];
  return `${wholeNumbers}.${decimalNumbers}`;
};

export const randomNumberOneToTen = () => {
  return Math.floor(Math.random() * (10 - 1)) + 1;
};

export const ordinal_suffix_of_number = (i) => {
  const num = parseInt(i);
  if (!Number(num)) return "";

  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

export const formatExp = (n, separate = ",") => {
  if (typeof n !== "number") return n;
  let s = n.toString();
  if (s.length <= 3) return n;
  // in some screen, we need to format the number of exp, from '5000' => '5,000' and yeah, this regex is for that purpose
  var regex = /\B(?=(\d{3})+(?!\d))/g;
  var ret = s.replace(regex, separate);
  return ret;
};

export const getToFixedNumber = (number, decimalPlaces = 2) => {
  const value = parseFloat(number);
  if (!value) return 0;
  return value ? value.toFixed(decimalPlaces) : 0;
};

export const devideWinLose = (win, lose) => {
  if (win && lose) {
    const devide = win / lose;
    return getToFixedNumber(devide);
  }
  return 0;
};

export const calculateDigit = (num) => {
  let numberString = num && num.toString().slice(-8);
  let lengthMain = num && num.length;
  if (lengthMain >= 8) {
    let newNum = num.substring(0, lengthMain - 8) + "." + numberString;
    return newNum && getToFixedNumber(newNum);
  }
  return num;
};

export const getWholeNumber = (number) => {
  const numberParts = number.toString().split(".");
  return numberParts[0];
};

export const removeGnokenSuffix = (phrase) => {
  const gnokenRegex = new RegExp(/ GNOKEN/i);
  return phrase.replace(gnokenRegex, "");
};

export function isDecimal(value) {
  const decimalRegEx = /^\d*\.?\d{0,4}$/;
  return value && value.match(decimalRegEx) ? true : false;
}

function _chunk(str, size) {
  return str.match(new RegExp(".{1," + size + "}", "g"));
}

export function parseWeights(weightsHex) {
  const HEX_SIZE = 2;
  const bytes = _chunk(weightsHex, HEX_SIZE);
  const weightValues = bytes.map((b) => parseInt(b, 16));

  const weights = {
    likeability: weightValues.shift(),
    mintnum: weightValues.shift(),
    random: weightValues.shift(),
    health: weightValues.shift(),
    experience: weightValues.shift(),
    issued_supply: weightValues.shift(),
    assassin_skill: weightValues.shift(),
    combat_skill: weightValues.shift(),
    manipulation_skill: weightValues.shift(),
    awareness_skill: weightValues.shift(),
    defense_skill: weightValues.shift(),
    speed_skill: weightValues.shift(),
    intelligence_skill: weightValues.shift(),
    willpower_skill: weightValues.shift(),
  };

  console.log("😎😎", weights.issued_supply, "😎👌", weights);
  return weights;
}
