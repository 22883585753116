import transactionSigner from "./TransactionSigner";
import waxUrl from "../shared/waxUrl";

const { atomicContract } = waxUrl;

export async function healCard(
  userProperties,
  activeUser,
  healthAssetIds,
  cardAssetId
) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: "gnocityheals",
        asset_ids: healthAssetIds,
        memo: cardAssetId.toString(),
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}
