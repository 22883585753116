import React, { useState, useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";

import "./index.scss";

export default function GnocityDropdown({
  list = [],
  title = "SELECT",
  onDropdownChanged,
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  useEffect(() => {
    if (list && selectedItem) {
      const itemDetails = list.find((li) => li.value === selectedItem);
      setSelectedItemDetails(itemDetails);
    }
  }, [list, selectedItem]);

  return (
    <Dropdown className="gnocity-dropdown gnocity-dropdown--spaced">
      <Dropdown.Toggle className="gnocity-dropdown__toggle">
        {!selectedItem ? title : selectedItemDetails?.label}
      </Dropdown.Toggle>

      <Dropdown.Menu className="gnocity-dropdown__menu">
        {list.map((li) => {
          return (
            <Dropdown.Item
              className="gnocity-dropdown__menu-item"
              key={li.value}
              onClick={() => {
                setSelectedItem(li.value);
                if (onDropdownChanged) onDropdownChanged(li.value);
              }}
            >
              {li.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
