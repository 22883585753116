import { useState, useEffect, Fragment } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "react-bootstrap";
import { Modal as GnocityModal } from "../../components/Modal";
import { BoxDetail, BoxSlider } from "../../components/BoxDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faBars } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import { getAllotedExp, getTokenByContract } from "../../services/TokenService";
import { getAllCards, healAsset } from "../../services/LineupService";

import waxUrl from "../../shared/waxUrl";
import { baseUrlIpfs } from "../../shared/constants";
import pvpConstants from "./constants";

import "./index.scss";
import { allocateSkills, resetSkills } from "../../services/SkillService";
import { InputText } from "../../components/inputs";

export default function ApplySkillsPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const {
    gnomePack,
    gnomeCard,
    sliderBreakpoints,
    sliderAutoplay,
    viableStatusProperties,
  } = pvpConstants;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [loader, setLoader] = useState(true);
  const [allCards, setAllCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [activeCard, setActiveCard] = useState(null);
  const [skillExp, setSkillExp] = useState(0);
  const [cardFilter, setCardFilter] = useState("");

  const [skillMaxed, setSkillMaxed] = useState(false);
  const [assassinSkill, setAssassinSkill] = useState(0);
  const [awarenessSkill, setAwarenessSkill] = useState(0);
  const [combatSkill, setCombatSkill] = useState(0);
  const [defenseSkill, setDefenseSkill] = useState(0);
  const [intelligenceSkill, setIntelligenceSkill] = useState(0);
  const [manipulationSkill, setManipulationSkill] = useState(0);
  const [speedSkill, setSpeedSkill] = useState(0);
  const [willPowerSkill, setWillpowerSkill] = useState(0);

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const storyExpResponse = await getAllotedExp(activeUser);
    const gnomeSeries1Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1"
    );
    const gnomeSeries2Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1",
      "series2cards"
    );
    const complexResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "complexserie",
      "complexcard"
    );
    const cykoResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "cykokobattle",
      "cykokocard"
    );

    if (storyExpResponse.success) {
      const storyDetail = storyExpResponse.responseData.rows[0];
      const allotedExp = parseInt(storyDetail.alloted_exp);
      setSkillExp(allotedExp);
    }

    if (gnomeSeries1Response.success) {
      const additionalCards = gnomeSeries1Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (gnomeSeries2Response.success) {
      const additionalCards = gnomeSeries2Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (complexResponse.success) {
      const additionalCards = complexResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (cykoResponse.success) {
      const additionalCards = cykoResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }
  }

  useEffect(() => {
    const maxSkill = parseInt(skillExp);
    const totalSkillSpent =
      assassinSkill +
      awarenessSkill +
      combatSkill +
      defenseSkill +
      intelligenceSkill +
      manipulationSkill +
      speedSkill +
      willPowerSkill;

    console.log("🤷‍♂️", totalSkillSpent, maxSkill);

    if (totalSkillSpent >= maxSkill) {
      setSkillMaxed(true);
      return;
    }
    setSkillMaxed(false);
  }, [
    assassinSkill,
    awarenessSkill,
    combatSkill,
    defenseSkill,
    intelligenceSkill,
    manipulationSkill,
    speedSkill,
    willPowerSkill,
  ]);

  useEffect(() => {
    if (activeUser) {
      setLoader(true);
      setAllCards([]);
      setSkillMaxed(false);
      _serviceInit(activeUser);
      setLoader(false);
    }
  }, [activeUser]);

  const _handleApplySkills = async (
    userProperties,
    activeUser,
    activeCard,
    assassinSkill,
    awarenessSkill,
    combatSkill,
    defenseSkill,
    intelligenceSkill,
    manipulationSkill,
    speedSkill,
    willPowerSkill
  ) => {
    const responses = [];
    if (assassinSkill > 0) {
      const response = await allocateSkills(
        userProperties,
        activeUser,
        activeCard.asset_id,
        assassinSkill,
        viableStatusProperties.assassin
      );
      responses.push(response);
    }

    if (awarenessSkill > 0) {
      const response = await allocateSkills(
        userProperties,
        activeUser,
        activeCard.asset_id,
        awarenessSkill,
        viableStatusProperties.awareness
      );
      responses.push(response);
    }

    if (combatSkill > 0) {
      const response = await allocateSkills(
        userProperties,
        activeUser,
        activeCard.asset_id,
        combatSkill,
        viableStatusProperties.combat
      );
      responses.push(response);
    }

    if (defenseSkill > 0) {
      const response = await allocateSkills(
        userProperties,
        activeUser,
        activeCard.asset_id,
        defenseSkill,
        viableStatusProperties.defense
      );
      responses.push(response);
    }

    if (intelligenceSkill > 0) {
      const response = await allocateSkills(
        userProperties,
        activeUser,
        activeCard.asset_id,
        intelligenceSkill,
        viableStatusProperties.intelligence
      );
      responses.push(response);
    }

    if (manipulationSkill > 0) {
      const response = await allocateSkills(
        userProperties,
        activeUser,
        activeCard.asset_id,
        manipulationSkill,
        viableStatusProperties.manipulation
      );
      responses.push(response);
    }

    if (speedSkill > 0) {
      const response = await allocateSkills(
        userProperties,
        activeUser,
        activeCard.asset_id,
        speedSkill,
        viableStatusProperties.speed
      );
      responses.push(response);
    }

    if (willPowerSkill > 0) {
      const response = await allocateSkills(
        userProperties,
        activeUser,
        activeCard.asset_id,
        willPowerSkill,
        viableStatusProperties.willPowerSkill
      );
      responses.push(response);
    }

    return responses;
  };

  return (
    <Fragment>
      {loader ? null : (
        <div className="skills-page">
          <GnocityModal
            className="skills-page__message-wrapper"
            isOpen={messageOpen}
            messagePositive={messagePositive}
            modalMessage={message}
            onModalClose={() => {
              setMessage("");
              setMessageOpen(false);
            }}
          />

          <GnocityModal
            isOpen={activeCard}
            className="skills-page__modal-content"
            popupMessage={message}
            messagePositive={messagePositive}
            onModalClose={() => {
              setMessage("");
              setActiveCard(null);
            }}
            onMessageClose={() => {
              setMessage("");
            }}
          >
            <div className="skills-page__image-container">
              <img
                className="skills-page__modal-image"
                src={activeCard?.imgUrl}
                alt={activeCard?.imgUrl}
              />
            </div>

            <div className="skills-page__container">
              <div className="skills-page__details-container">
                <div className="skills-page__detail-title">
                  Available Points
                </div>
                <div className="skills-page__detail-value skills-page__detail-value--green">
                  {skillExp}
                </div>
              </div>

              {!activeCard?.mint_number ? null : (
                <div className="skills-page__details-container">
                  <div className="skills-page__detail-title">Mint No.</div>
                  <div className="skills-page__detail-value">
                    {activeCard?.mint_number}
                  </div>
                </div>
              )}

              <div className="skills-page__details-container">
                <div className="skills-page__detail-title">Name</div>
                <div className="skills-page__detail-value">
                  {activeCard?.cardName}
                </div>
              </div>

              <div className="skills-page__details-container">
                <div className="skills-page__detail-title">Level</div>
                <div className="skills-page__detail-value">
                  {activeCard?.level ? activeCard?.level : 0}
                </div>
              </div>

              <div className="skills-page__details-container">
                <div className="skills-page__detail-title">Gnoken/WIN</div>
                <div className="skills-page__detail-value">
                  {activeCard?.gnokenPerWin}
                </div>
              </div>

              <div className="skills-page__details-container">
                <div className="skills-page__detail-title">Status</div>
                <div className="skills-page__detail-action">
                  {!activeCard?.lowHealth ? null : (
                    <Button
                      className="tf-button lineup-page__btn--small"
                      onClick={async () => {
                        try {
                          const healResponse = await healAsset(
                            userProperties,
                            activeUser,
                            activeCard.asset_id
                          );
                          if (healResponse.success) {
                            setMessage("HEAL SUCCESSFUL");
                            setMessagePositive(true);
                          }

                          if (!healResponse.success) {
                            setMessage("HEAL FAILED");
                            setMessagePositive(false);
                          }
                        } catch (error) {
                          setMessage("HEAL FAILED");
                          setMessagePositive(false);
                          console.log("🎶🎶🎶", error);
                        }
                      }}
                    >
                      HEAL
                    </Button>
                  )}

                  <Button
                    className="tf-button lineup-page__btn--small"
                    onClick={async () => {
                      try {
                        const resetResponse = await resetSkills(
                          userProperties,
                          activeUser,
                          activeCard.asset_id
                        );
                        if (resetResponse.success) {
                          setMessage("RESET SUCCESSFUL");
                          setMessagePositive(true);
                        }

                        if (!resetResponse.success) {
                          setMessage("RESET FAILED");
                          setMessagePositive(false);
                        }
                      } catch (error) {
                        setMessage("RESET FAILED");
                        setMessagePositive(false);
                        console.log("🎶🎶🎶", error);
                      }
                    }}
                  >
                    RESET SKILLS
                  </Button>
                </div>
              </div>

              <h4 className="skills-page__title">SKILLS</h4>
              <div className="skills-page__skills-container">
                <BoxSlider
                  title="Assassin"
                  disabled={skillMaxed}
                  value={assassinSkill}
                  maxValue={skillExp}
                  onSliderValueChanged={(sliderValue) =>
                    setAssassinSkill(sliderValue)
                  }
                />
                <BoxSlider
                  title="Awareness"
                  disabled={skillMaxed}
                  value={awarenessSkill}
                  maxValue={skillExp}
                  onSliderValueChanged={(sliderValue) =>
                    setAwarenessSkill(sliderValue)
                  }
                />
                <BoxSlider
                  title="Combat"
                  disabled={skillMaxed}
                  value={combatSkill}
                  maxValue={skillExp}
                  onSliderValueChanged={(sliderValue) =>
                    setCombatSkill(sliderValue)
                  }
                />

                <BoxSlider
                  title="Defense"
                  disabled={skillMaxed}
                  value={defenseSkill}
                  maxValue={skillExp}
                  onSliderValueChanged={(sliderValue) =>
                    setDefenseSkill(sliderValue)
                  }
                />

                <BoxSlider
                  title="Intelligence"
                  disabled={skillMaxed}
                  value={intelligenceSkill}
                  maxValue={skillExp}
                  onSliderValueChanged={(sliderValue) =>
                    setIntelligenceSkill(sliderValue)
                  }
                />

                <BoxSlider
                  title="Manipulations"
                  disabled={skillMaxed}
                  value={manipulationSkill}
                  maxValue={skillExp}
                  onSliderValueChanged={(sliderValue) =>
                    setManipulationSkill(sliderValue)
                  }
                />

                <BoxSlider
                  title="Speed"
                  disabled={skillMaxed}
                  value={speedSkill}
                  maxValue={skillExp}
                  onSliderValueChanged={(sliderValue) =>
                    setSpeedSkill(sliderValue)
                  }
                />

                <BoxSlider
                  title="Willpower"
                  disabled={skillMaxed}
                  value={willPowerSkill}
                  maxValue={skillExp}
                  onSliderValueChanged={(sliderValue) =>
                    setWillpowerSkill(sliderValue)
                  }
                />
              </div>
              <div className="skills-page__btn-container">
                <Button
                  className="skills-page__button tf-button"
                  onClick={async () => {
                    const response = await _handleApplySkills(
                      userProperties,
                      activeUser,
                      activeCard,
                      assassinSkill,
                      awarenessSkill,
                      combatSkill,
                      defenseSkill,
                      intelligenceSkill,
                      manipulationSkill,
                      speedSkill,
                      willPowerSkill
                    );
                  }}
                >
                  APPLY
                </Button>
                <Button
                  className="skills-page__button tf-button"
                  onClick={() => {
                    setAssassinSkill(0);
                    setAwarenessSkill(0);
                    setCombatSkill(0);
                    setDefenseSkill(0);
                    setIntelligenceSkill(0);
                    setManipulationSkill(0);
                    setSpeedSkill(0);
                    setWillpowerSkill(0);
                    setSkillMaxed(false);
                  }}
                >
                  RESET
                </Button>
              </div>
            </div>
          </GnocityModal>
          <div className="tf-container">
            <h4 className="skills-page__title skills-page__title-grid">
              Available Skill Points: {skillExp}
              <div>
                <InputText
                  value={cardFilter}
                  placeholder="Enter Filter"
                  onInputChanged={(value) => {
                    setCardFilter(value);
                  }}
                />
              </div>
              <Button
                className="tf-button lineup-page__btn"
                onClick={() => setCardGrid((grid) => !grid)}
              >
                {!cardGrid ? (
                  <FontAwesomeIcon icon={faThLarge} />
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </Button>
            </h4>

            {!cardGrid ? (
              <Swiper
                className="collection-1 visible skills__swiper"
                spaceBetween={30}
                breakpoints={sliderBreakpoints}
                loop={false}
                modules={[Autoplay]}
                autoplay={sliderAutoplay}
              >
                {allCards
                  .filter((c) => {
                    const schemaName = c.assetData.schema.schema_name;

                    if (cardFilter) {
                      const uppercasedFilter = cardFilter.toUpperCase();
                      const cardName = c.cardName.toUpperCase();
                      const hasCard = cardName.includes(uppercasedFilter);

       return (
        hasCard &&
        ((c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
             (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity))
      );
    }

    return (
     (c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
        (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity)
    );
  })
                  .map((c) => {
                    const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
                    const isSelected = selectedCards.find(
                      (sc) => sc.asset_id === c.asset_id
                    );

                    return (
                      <SwiperSlide key={c.asset_id}>
                        <div className="slider-item">
                          <div className="tf-product ">
                            <div className="image">
                              <img
                                className="skills-page__slider-image"
                                src={c.imgUrl}
                                alt={c.imgUrl}
                              />
                            </div>
                            <h6 className="name"></h6>
                            <Button
                              className="tf-button"
                              onClick={() => {
                                setActiveCard(c);
                              }}
                            >
                              SELECT
                            </Button>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            ) : (
              <div className="pet-experience-page__card-grid">
                {allCards
                  .filter((c) => {
                    const schemaName = c.assetData.schema.schema_name;

                    if (cardFilter) {
                      const uppercasedFilter = cardFilter.toUpperCase();
                      const cardName = c.cardName.toUpperCase();
                      const hasCard = cardName.includes(uppercasedFilter);

          return (
        hasCard &&
        ((c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
             (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity))
      );
    }

    return (
     (c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
        (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity)
    );
  })



                  .map((c) => {
                    const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
                    const isSelected = selectedCards.find(
                      (sc) => sc.asset_id === c.asset_id
                    );

                    return (
                      <div className="tf-product " key={c.asset_id}>
                        <div className="image">
                          <img
                            className="skills-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name"></h6>
                        <Button
                          className="tf-button"
                          onClick={() => {
                            setActiveCard(c);
                          }}
                        >
                          SELECT
                        </Button>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}
