import moment from "moment";
import transactionSigner from "./TransactionSigner";
import waxUrl from "../shared/waxUrl";
import { getAssetDetails } from "./AssetService";

const { rpcEndpoint, petsContract, atomicContract } = waxUrl;

export async function claimPet(userProperties, activeUser, assetId, skillId) {
  const transactionData = [
    {
      contractAccount: petsContract,
      actionName: "claim",
      data: {
        account: activeUser,
        toasset_id: assetId,
        attribute_name: skillId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function unstakePet(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: petsContract,
      actionName: "unstake",
      data: {
        account: activeUser,
        asset_id: assetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function unstakeAllPet(userProperties, activeUser) {
  const transactionData = [
    {
      contractAccount: petsContract,
      actionName: "unstakeall",
      data: {
        account: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function stakePets(userProperties, activeUser, assetIds = []) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: petsContract,
        asset_ids: assetIds,
        memo: "",
      },
    },
    {
      contractAccount: petsContract,
      actionName: "stake",
      data: {
        account: activeUser,
        asset_ids: assetIds,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    const responseMessage = response.responseData;
    return { success: true, responseData: responseMessage };
  } else {
    const responseMessage = response.responseData;
    return { success: false, responseData: responseMessage };
  }
}

export async function getStakedPets(activeUser, rows = 1000) {
  const stakedPets = [];
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlbody = {
    json: true,
    code: petsContract,
    scope: activeUser,
    table: "stakes",
    limit: rows,
  };

  const options = (urlBody) => {
    return {
      method: "POST",
      body: JSON.stringify(urlBody),
      mode: "cors",
      credentials: "omit",
    };
  };

  const response = await fetch(url, options(urlbody));

  if (response.status === 200) {
    const responseJson = await response.json();
    const perksResponseData = responseJson.rows;

    for (let i = 0; i < perksResponseData.length; i++) {
      const cardDetailResponse = await getAssetDetails(
        perksResponseData[i]?.assetid
      );
      if (cardDetailResponse.success) {
        const lastClaimed = perksResponseData[i].last_claimed_at;
        const perkDetails = cardDetailResponse.responseData.data;
        const hourDifference = Math.floor(
          moment
            .duration(moment.utc(Date.now()).diff(moment.utc(lastClaimed)))
            .asHours()
        );
        const hourRange = hourDifference > 0 ? hourDifference : 0;
        const hourlyExp = parseInt(perkDetails.data.hourly_exp);

        stakedPets.push({
          ...perkDetails,
          hourRange,
          hourlyExp,
          hourDifference,
          lastClaimed,
        });
      }
    }
    return { success: true, responseData: stakedPets };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
