



const dataAbout = [

    {
        id: 1,
        title : 'GNOME SERIES',
        text : 'Most of Gnome Series NFT Collection is playable in GNO CITY.'
    },
    {
        id: 2,
        title : 'BUILDERS BLOCK',
        text : 'All of Builders Block NFT Collection is playable in GNO CITY.'
    },
    {
        id: 3,
        title : 'COMPLEX SERIES',
        text : 'Most of Complex Series NFT Collection is playable in GNO CITY.'
    },
    {
        id: 4,
        title : 'CRYPTO RACING CLUB',
        text : 'All of Crypto Racing Club NFT Collection is playable in GNO CITY.'
    },
    

]

export default dataAbout;