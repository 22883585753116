const miningConstants = {
  maxCards: 5,
  cardTypes: {
    gamer: "gamer",
    pc: "pc",
  },
  sliderBreakpoints: {
    0: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    991: {
      slidesPerView: 5,
    },
  },
  sliderAutoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  cost: {
    recharge: 50,
  },
  miningBonus: [
    {
      bitValue: 1000,
      bonus: 1,
    },
    {
      bitValue: 2500,
      bonus: 2.5,
    },
    {
      bitValue: 7500,
      bonus: 7.5,
    },
    {
      bitValue: 15000,
      bonus: 15,
    },
    {
      bitValue: 50000,
      bonus: 20,
    },
    {
      bitValue: 100000,
      bonus: 25,
    },
    {
      bitValue: 250000,
      bonus: 30,
    },
    {
      bitValue: 500000,
      bonus: 35,
    },
    {
      bitValue: 1000000,
      bonus: 40,
    },
  ],
};

export default miningConstants;
