const pvpConstants = {
  gnomePack: "gnomepack",
  gnomeCard: "gnomecard",
  maxCards: 5,
  sliderBreakpoints: {
    0: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    991: {
      slidesPerView: 5,
    },
  },
  sliderAutoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
};

export default pvpConstants;
