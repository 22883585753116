import { useState, useEffect } from "react";
import { Button, Form, FormText } from "react-bootstrap";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BoxDetail } from "../../components/BoxDetail";
import { Modal as GnocityModal } from "../../components/Modal";
import { InputText } from "../../components/inputs";

import { getAllCards, getUserLineUpList } from "../../services/LineupService";
import { getTokenByContract } from "../../services/TokenService";

import waxUrl from "../../shared/waxUrl";
import marketConstants from "./constants";

import "./index.scss";
import {
  buyCard,
  changeCardPrice,
  delistCard,
  getMarketListings,
  listCard,
} from "../../services/MarketService";
import { isDecimal } from "../../shared/helpers";
import { Fragment } from "react";
import {
  getConfigurations,
  getStakedLands,
  stakeLandAssets,
  unstakeLand,
  updateLandPrice,
} from "../../services/LandService";
import { address } from "faker/lib/locales/az";

const ADDRESS_TYPE = {
  residential: "residential",
  commercial: "commercial",
};

export default function GnomeLandPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { maxCards, sliderBreakpoints, sliderAutoplay, marketAction } =
    marketConstants;

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [addressType, setAddressType] = useState(ADDRESS_TYPE.residential);

  const [isLoading, setIsLoading] = useState(false);
  const [marketplaceAction, setMarketAction] = useState("");
  const [activeCard, setActiveCard] = useState(null);
  const [isListing, setIsListing] = useState(false);
  const [price, setPrice] = useState(0);
  const [stakedCards, setStakedCards] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [listings, setListings] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [cardLineup, setCardLineup] = useState([]);
  const [cardFilter, setCardFilter] = useState("");

  const hasMaxCards = selectedCards.length >= maxCards;
  const hasAddedCards = selectedCards.filter((sc) => sc?.assetData).length > 0;

  async function _getListings(activeUser) {
    const stakedLandsResponse = await getStakedLands(activeUser);
    if (stakedLandsResponse.success) {
      const additionalCards = stakedLandsResponse.responseData;
      setListings((cards) => [...cards, ...additionalCards]);
    }
  }

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const stakedLandsResponse = await getConfigurations(
      "userlands",
      activeUser
    );
    const landAssetsResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "landboxgames"
    );
    const landPlotsResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnocityworld",
      "land"
    );

    if (stakedLandsResponse.success) {
      const additionalCards = stakedLandsResponse.responseData.rows;
      setStakedCards((cards) => [...cards, ...additionalCards]);
    }

    if (landAssetsResponse.success) {
      const additionalCards = landAssetsResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (landPlotsResponse.success) {
      const additionalCards = landPlotsResponse.responseData;
      // setAllCards((cards) => [...cards, ...additionalCards]);
    }
  }

  useEffect(() => {
    // console.log("😎", listings);
  }, [addressType]);

  useEffect(() => {
    if (activeUser && allCards.length && !isLoading) {
      _getListings(activeUser);
    }
  }, [activeUser, isLoading, allCards]);

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      setIsLoading(true);
      _serviceInit(activeUser);
      setIsLoading(false);
    }
  }, [activeUser]);

  return (
    <div className="lands-page">
      <GnocityModal
        className="lands-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <GnocityModal
        isOpen={activeCard}
        messagePositive={messagePositive}
        popupMessage={message}
        className="lands-page__modal-content"
        onModalClose={() => {
          setMessage("");
          setActiveCard(null);
        }}
        onMessageClose={() => {
          setMessage("");
        }}
      >
        <div className="lands-page__image-container">
          <img
            className="lands-page__modal-image"
            src={activeCard?.imgUrl}
            alt={activeCard?.imgUrl}
          />
        </div>

        <div className="lands-page__container">
          {!activeCard?.mint_number ? null : (
            <div className="lands-page__details-container">
              <div className="lands-page__detail-title">Mint No.</div>
              <div className="lands-page__detail-value">
                {activeCard?.mint_number}
              </div>
            </div>
          )}

          <div className="lands-page__details-container">
            <div className="lands-page__detail-title">Name</div>
            <div className="lands-page__detail-value">
              {activeCard?.cardName}
            </div>
          </div>

          <div className="lands-page__details-container">
            <div className="lands-page__detail-title">Level</div>
            <div className="lands-page__detail-value">{activeCard?.level}</div>
          </div>

          <div className="lands-page__details-container">
            <div className="lands-page__detail-title">Status</div>
            <div className="lands-page__detail-action lands-page__detail-form"></div>
          </div>

          {/* <h4 className="lands-page__title">SKILLS</h4>
          <div className="lands-page__skills-container">
            <BoxDetail
              title="Assassin"
              value={activeCard?.info.AssassinSkill}
            />
            <BoxDetail
              title="Awareness"
              value={activeCard?.info.AwarenessSkill}
            />
            <BoxDetail title="Combat" value={activeCard?.info.CombatSkill} />
            <BoxDetail title="Defense" value={activeCard?.info.DefenseSkill} />
            <BoxDetail
              title="Intelligence"
              value={activeCard?.info.IntelligenceSkill}
            />
            <BoxDetail
              title="Manipulation"
              value={activeCard?.info.ManipulationSkill}
            />
            <BoxDetail title="Speed" value={activeCard?.info.SpeedSkill} />
            <BoxDetail
              title="Willpower"
              value={activeCard?.info.WillpowerSkill}
            />
          </div> */}
        </div>
      </GnocityModal>

      <h3 className="lands-page__title lands-page__title--between">
        GNOME LANDS{" "}
        <div className="lands-page__btn-container">
          <Button
            className="tf-button  lands-page__btn"
            onClick={() => setIsListing((listing) => !listing)}
          >
            {!isListing ? `GO TO STAKING` : `GNOME LANDS`}
          </Button>
        </div>
        Filter Lands{" "}
        <div className="lands-page__btn-container">
          <div>
            <InputText
              value={cardFilter}
              placeholder="Enter Filter"
              onInputChanged={(value) => {
                setCardFilter(value);
              }}
            />
          </div>
        </div>
      </h3>

      {!isListing ? (
        <Fragment>
          <div className="lands-page__card-container">
            <h3 className="lands-page__title lands-page__title--spaced">
              <div className="lands-page__input-container">
                <div>ZONING TYPE: {` `}</div>

                <div className="lands-page__input-container">
                  <input
                    type="radio"
                    className="lands-page__input-radio"
                    name="address_type"
                    value={ADDRESS_TYPE.residential}
                    checked={addressType === ADDRESS_TYPE.residential}
                    onClick={() => setAddressType(ADDRESS_TYPE.residential)}
                  />
                  <h5>RESIDENTIAL</h5>
                </div>

                <div className="lands-page__input-container lands-page__input-container--centered">
                  <input
                    type="radio"
                    className="lands-page__input-radio"
                    name="address_type"
                    value={ADDRESS_TYPE.commercial}
                    checked={addressType === ADDRESS_TYPE.commercial}
                    onClick={() => setAddressType(ADDRESS_TYPE.commercial)}
                  />
                  <h5>COMMERCIAL</h5>
                </div>
              </div>
            </h3>

            <div className="lands-page__card-container">
              <Swiper
                className="collection-1 visible lands-page__bg--gray"
                spaceBetween={30}
                breakpoints={sliderBreakpoints}
                loop={false}
                modules={[Autoplay]}
                autoplay={sliderAutoplay}
              >
                {listings
                  .filter((c) => {
                    const isEqualToAddress =
                      c.mutable_data?.zoning === addressType;

                    if (cardFilter) {
                      const uppercasedFilter = cardFilter.toUpperCase();
                      const cardName = c.name.toUpperCase();
                      const hasCard = cardName.includes(uppercasedFilter);

                      return hasCard && isEqualToAddress;
                    }

                    return isEqualToAddress;
                  })
                  .map((c) => {
                    return (
                      <SwiperSlide key={c.asset_id}>
                        <div className="slider-item">
                          <div className="tf-product ">
                            <div
                              className="image"
                              onClick={() => setActiveCard(c)}
                            >
                              <img
                                className="lands-page__slider-image"
                                src={c.imgUrl}
                                alt={c.imgUrl}
                              />
                            </div>
                            <h6 className="lands-page__card-name">
                              {c.amount}
                            </h6>
                            <h6 style={{ display: "grid" }}>
                              <Button
                                className="tf-button"
                                onClick={async () => {
                                  const response = await unstakeLand(
                                    userProperties,
                                    c.asset_id,
                                    addressType,
                                    activeUser
                                  );
                                }}
                              >
                                UNSTAKE
                              </Button>
                            </h6>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="lands-page__card-container">
          <Swiper
            className="collection-1 visible lands-page__bg--gray"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards
              .filter((c) => {
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard;
                }

                return true;
              })
              .map((c) => {
                const isStaked = stakedCards.find(
                  (sc) => sc.land_asset_id === c.asset_id
                );

                return (
                  <SwiperSlide key={c.asset_id}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="lands-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                            onClick={() => setActiveCard(c)}
                          />
                        </div>
                        <h6 className="name">{c.cardName}</h6>
                        <h6 className="name">
                          {!isStaked ? (
                            <Button
                              className="tf-button"
                              onClick={async () => {
                                const stakeResponse = await stakeLandAssets(
                                  userProperties,
                                  c.asset_id,
                                  activeUser
                                );
                              }}
                            >
                              STAKE
                            </Button>
                          ) : (
                            <Button
                              className="tf-button"
                              onClick={async () => {
                                const PRICE = 100.1001;
                                const updatePriceResponse =
                                  await updateLandPrice(
                                    userProperties,
                                    c.asset_id,
                                    activeUser,
                                    PRICE
                                  );
                              }}
                            >
                              UPDATE PRICE
                            </Button>
                          )}
                        </h6>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      )}
    </div>
  );
}
