import transactionSigner from "./TransactionSigner";
import waxUrl from "../shared/waxUrl";
import eosjsName from "eosjs-account-name";
import { rpcEndpoint } from "../shared/constants";
import { getToFixedNumber } from "../shared/helpers";

const { miningContract, landboxContract } = waxUrl;

export async function exchangeForBits(userProperties, activeUser, value) {
  const transactionData = [
    {
      contractAccount: landboxContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: miningContract,
        quantity: `${getToFixedNumber(value, 4)} GNOKEN`,
        memo: "swap",
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function exchangeForLanbx(userProperties, activeUser, value) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "swaptkn",
      data: {
        player: activeUser,
        quantity: `${getToFixedNumber(value, 4)} BITS`,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function claimReward(userProperties, assetIds) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "claimgs",
      data: {
        asset_ids: assetIds,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function activateGamer(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "reggs",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function deactivateGamer(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "dereggs",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function activatePC(userProperties, activeUser, assetId, gamerId) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "regpc",
      data: {
        asset_ids: [parseInt(assetId)],
        gamer_id: gamerId,
        player: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function deactivatePC(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "deregpc",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function rechargeGamer(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "rechargegs",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function upgradeGamer(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "upgradegs",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function upgradePC(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "upgradepcs",
      data: {
        asset_ids: [parseInt(assetId)],
        player: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function claimRewards(userProperties, assetIds) {
  const transactionData = [
    {
      contractAccount: miningContract,
      actionName: "claimgs",
      data: {
        asset_ids: assetIds,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function getConfigurations(
  table = "bconfigs",
  activeUser,
  scope,
  rows = 10000
) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const contract = "gnomining.gm";
  const bounds =
    table === "gamers" || table === "pcs"
      ? eosjsName.nameToUint64(activeUser)
      : "";
  const key_type = table === "gamers" || table === "pcs" ? "i64" : "";
  const index_position = table === "gamers" || table === "pcs" ? 2 : "";

  const urlBody = {
    code: contract,
    scope: scope ? scope : contract,
    json: true,
    upperBound: bounds,
    lowerBound: bounds,
    key_type: key_type,
    index_position: index_position,
    limit: rows,
    table: table,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
