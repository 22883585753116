export function getBonusExpByMint(cardMint) {
  if (!cardMint) return 0;
  if (cardMint < 10) return 50;
  if (cardMint < 50) return 35;
  if (cardMint < 250) return 20;
  return 5;
}

export function getBonusExpByVariant(cardType, isComplexCard = true) {
  if (isComplexCard) {
    switch (cardType) {
      case "Common":
        return 25;
      case "Uncommon":
        return 35;
      case "Rare":
        return 40;
      case "Legendary":
        return 45;
      case "Mythic":
        return 50;
      case "Sketch":
        return 50;
      case "Gold Mythic":
        return 60;
    }
  } else {
    switch (cardType) {
      case "Gold Parallel":
        return 60;
      case "Rainbow Parallel":
      case "Elite":
        return 50;
      case "Portraits":
      case "Shiny Legendary":
      case "Insanity":
      case "Red Parallel":
        return 45;
      case "Epic":
      case "Dark Epic":
      case "Rainbow Epic":
      case "Allure":
        return 40;
      case "Green Parallel":
        return 38;
      case "Purple Parallel":
        return 36;
      case "Fragment":
      case "Prismatic":
      case "Reveal":
      case "Flash":
      case "LE Founders":
      case "Defect":
      case "Gold Plated":
      case "Prismatic":
      case "Whiteout":
      case "Tipsy":
      case "Flaw":
        return 35;
      case "Blue Parallel":
        return 34;
      case "Pink Parallel":
        return 30;
      case "Base":
        return 10;
      // case "Promo" :
      //   return 0  }
      default:
        return 0;
    }
  }
}
