const pvpConstants = {
  maxCards: 5,
  sliderBreakpoints: {
    0: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    991: {
      slidesPerView: 5,
    },
  },
  sliderAutoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  viableStatusProperties: {
    assassin: "assassin_skill",
    awareness: "awareness_skill",
    combat: "combat_skill",
    defense: "defense_skill",
    speed: "speed_skill",
    willpower: "willpower_skill",
    manipulation: "manipulation_skill",
    intelligence: "intelligence_skill",
  },
};

export default pvpConstants;
