import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BoxDetail } from "../../components/BoxDetail";
import { Modal as GnocityModal } from "../../components/Modal";
import { InputText } from "../../components/inputs";

import {
  getAllCards,
  getUserLineUpList,
  healAsset,
  saveLineup,
  withdrawLineup,
} from "../../services/LineupService";
import { getStakedPerks, stakePerk } from "../../services/PerkService";
import { getTokenByContract } from "../../services/TokenService";

import { baseUrlIpfs } from "../../shared/constants";
import waxUrl from "../../shared/waxUrl";
import perkConstants from "./constants";

import "./index.scss";

export default function PerksPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay } = perkConstants;

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [activeCard, setActiveCard] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [allCards, setAllCards] = useState([]);
  const [stakedPerks, setStakedPerks] = useState([]);
  const [perkCards, setPerkCards] = useState([]);
  const [cardLineup, setCardLineup] = useState([]);
  const [cardFilter, setCardFilter] = useState("");

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const lineupResponse = await getUserLineUpList(
      activeUser,
      tokens.responseData
    );

    const perksResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnocityworld",
      "perks"
    );

    const gnomeSeries1Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1"
    );

    const complexResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "complexserie",
      "complexcard"
    );

    const stakedPerksResponse = await getStakedPerks(activeUser);

    if (lineupResponse.success) {
      setCardLineup([...lineupResponse.responseData.lineup]);
    }

    if (stakedPerksResponse.success) {
      const stakedPerks = stakedPerksResponse.responseData;
      setStakedPerks(stakedPerks);
    }

    if (perksResponse.success) {
      const additionalCards = perksResponse.responseData;
      setPerkCards((cards) => [...cards, ...additionalCards]);
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (gnomeSeries1Response.success) {
      const additionalCards = gnomeSeries1Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (complexResponse.success) {
      const additionalCards = complexResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }
  }

  useEffect(() => {
    console.log("😎", stakedPerks);
  }, [stakedPerks]);

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      setStakedPerks([]);
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="perks-page">
      <GnocityModal
        className="perks-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <GnocityModal
        isOpen={activeCard}
        className="perks-page__modal-content"
        onModalClose={() => setActiveCard(null)}
      >
        <div className="perks-page__image-container">
          <img
            className="perks-page__modal-image"
            src={activeCard?.imgUrl}
            alt={activeCard?.imgUrl}
          />
        </div>

        <div className="perks-page__container">
          {!activeCard?.mint_number ? null : (
            <div className="perks-page__details-container">
              <div className="perks-page__detail-title">Mint No.</div>
              <div className="perks-page__detail-value">
                {activeCard?.mint_number}
              </div>
            </div>
          )}

          <div className="perks-page__details-container">
            <div className="perks-page__detail-title">Name</div>
            <div className="perks-page__detail-value">
              {activeCard?.cardName}
            </div>
          </div>

          <div className="perks-page__details-container">
            <div className="perks-page__detail-title">Level</div>
            <div className="perks-page__detail-value">{activeCard?.level}</div>
          </div>

          <div className="perks-page__details-container">
            <div className="perks-page__detail-title">Gnoken/WIN</div>
            <div className="perks-page__detail-value">
              {activeCard?.gnokenPerWin}
            </div>
          </div>

          <h4 className="perks-page__title">SKILLS</h4>
          <div className="perks-page__skills-container">
            <BoxDetail
              title="Assassin"
              value={activeCard?.info.AssassinSkill}
            />
            <BoxDetail
              title="Awareness"
              value={activeCard?.info.AwarenessSkill}
            />
            <BoxDetail title="Combat" value={activeCard?.info.CombatSkill} />
            <BoxDetail title="Defense" value={activeCard?.info.DefenseSkill} />
            <BoxDetail
              title="Intelligence"
              value={activeCard?.info.IntelligenceSkill}
            />
            <BoxDetail
              title="Manipulation"
              value={activeCard?.info.ManipulationSkill}
            />
            <BoxDetail title="Speed" value={activeCard?.info.SpeedSkill} />
            <BoxDetail
              title="Willpower"
              value={activeCard?.info.WillpowerSkill}
            />
          </div>
        </div>
      </GnocityModal>

      {!cardLineup.length ? null : (
        <h3 className="perks-page__title">Your Lineup Cards </h3>
      )}

      <div className="perks-page__card-container">
        <Swiper
          className="collection-1 visible perks-page__bg--gray"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {cardLineup
            .filter((c) => {
              if (selectedCard) {
                return selectedCard.asset_id === c.asset_id && c.level;
              }
              return c.level;
            })
            .map((c) => {
              return (
                <SwiperSlide key={c.asset_id}>
                  <div className="slider-item">
                    <div className="tf-product ">
                      <div className="image" onClick={() => setActiveCard(c)}>
                        <img
                          className="perks-page__slider-image"
                          src={c.imgUrl}
                          alt={c.imgUrl}
                        />
                      </div>
                      <h6 className="name">
                        <Button
                          className="tf-button"
                          onClick={async () => {
                            if (selectedCard) {
                              setSelectedCard(null);
                              return;
                            }

                            setSelectedCard(c);
                          }}
                        >
                          {selectedCard ? `UNSELECT` : `SELECT`}
                        </Button>
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>

      <div className="perks-page__card-container">
        <Swiper
          className="collection-1 visible perks-page__bg--gray"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {selectedCard &&
            stakedPerks
              .filter((c, i) => {
                return true;
              })
              .map((c) => {
                const imagePathPoint = `${baseUrlIpfs}/ipfs/`;

                return (
                  <SwiperSlide key={`${c.perk_asset_id}-${c.char_asset_id}`}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image" onClick={() => setActiveCard(c)}>
                          <img
                            className="perks-page__slider-image"
                            src={`${imagePathPoint}${c.details.data.img}`}
                            alt={`${imagePathPoint}${c.details.data.img}`}
                          />
                        </div>
                        <h6 className="name">
                          <Button
                            className="tf-button"
                            onClick={async () => {
                              try {
                                const response = await stakePerk(
                                  userProperties,
                                  activeUser,
                                  c.asset_id
                                );

                                if (response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(true);
                                  setMessage("UNSTAKE SUCCESSFUL");
                                }

                                if (!response.success) {
                                  setMessageOpen(true);
                                  setMessagePositive(false);
                                  setMessage(`UNSTAKE FAILED`);
                                }
                              } catch (error) {
                                setMessageOpen(true);
                                setMessage(`UNSTAKE FAILED : ${error}`);
                                setMessagePositive(false);
                                console.log("🎶🎶🎶", error);
                              }
                            }}
                          >
                            UNSTAKE
                          </Button>
                        </h6>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>

      <div className="perks-page__card-container">
        <Swiper
          className="collection-1 visible perks-page__bg--gray"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {perkCards
            .filter((c, i) => {
              const isStaked = stakedPerks.find(
                (sp) => sp.perks_asset_id === c.asset_id
              );

              return !isStaked && selectedCard;
            })
            .map((c) => {
              return (
                <SwiperSlide key={c.asset_id}>
                  <div className="slider-item">
                    <div className="tf-product ">
                      <div className="image" onClick={() => setActiveCard(c)}>
                        <img
                          className="perks-page__slider-image"
                          src={c.imgUrl}
                          alt={c.imgUrl}
                        />
                      </div>
                      <h6 className="name">
                        <Button
                          className="tf-button"
                          onClick={async () => {
                            try {
                              const response = await stakePerk(
                                userProperties,
                                activeUser,
                                selectedCard.asset_id,
                                c.asset_id
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage("STAKE SUCCESSFUL");
                              }

                              if (!response.success) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage(`STAKE FAILED`);
                              }
                            } catch (error) {
                              setMessageOpen(true);
                              setMessage(`STAKE FAILED : ${error}`);
                              setMessagePositive(false);
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          STAKE
                        </Button>
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
}
