// original configs for production
// export const apiBaseUrl = "http://ec2-35-183-198-90.ca-central-1.compute.amazonaws.com";
// export const apiBaseUrl = "http://ec2-99-79-82-226.ca-central-1.compute.amazonaws.com";
// export const apiBaseUrl = "https://rootapi.gnocity.com";
// export const gnoCityBaseUrl = "https://gnocity.com";
// // here is configs for production
// export const defaultWaxAvatar =
//   "https://wax.api.atomichub.io/v1/preview/avatar/e3qsa.wam";
// export const baseUrlWaxAvatar =
//   "https://wax.api.atomichub.io/v1/preview/avatar";
// export const rpcEndpoint = "https://wax-api.gnocity.io";
// export const baseUrlWax = "https://wax.atomichub.io";
// export const baseUrlWaxCheckList = "https://wax-aa.gnocity.io/atomicassets";
// export const baseUrlIpfs = "https://ipfs.io";
// export const baseUrlWaxAtomicAssets = "https://wax-aa.gnocity.io";
// export const baseUrlGnomeseries = "https://gnomeseries.com";
// export const baseUrlGnoMyPinataCloud = "https://gno.mypinata.cloud";
// export const imagePathPoint = "https://ipfs.io/ipfs/";

// here is configs for development - using ovh server
// export const defaultWaxAvatar =
//   "https://wax.api.atomichub.io/v1/preview/avatar/e3qsa.wam";
// export const baseUrlWaxAvatar =
//   "https://wax.api.atomichub.io/v1/preview/avatar";
// export const baseUrlWax = "http://wax-aa.gnocity.io:9898";
// export const baseUrlWaxCheckList = "http://wax-aa.gnocity.io:9898/atomicassets";
// export const baseUrlIpfs = "https://ipfs.io";
// export const baseUrlWaxAtomicAssets = "http://wax-aa.gnocity.io:9898";
// export const baseUrlGnomeseries = "https://gnomeseries.com";
// export const baseUrlGnoMyPinataCloud = "https://gno.mypinata.cloud";
// export const imagePathPoint = "https://ipfs.io/ipfs/";

// here is configs for development - using if ovh server is down
export const apiBaseUrl = "https://rootapi.gnocity.com";
export const gnoCityBaseUrl = "https://gnocity.com";
export const defaultWaxAvatar =
  "https://wax.api.atomichub.io/v1/preview/avatar/e3qsa.wam";
export const baseUrlWaxAvatar =
  "https://wax.api.atomichub.io/v1/preview/avatar";
export const rpcEndpoint = "https://wax-api.gnocity.io";
export const baseUrlWax = "https://wax.atomichub.io";
export const baseUrlWaxCheckList =
  "https://wax-aa.gnocity.io/atomicassets";
export const baseUrlIpfs = "https://atomichub-ipfs.com";
export const baseUrlWaxAtomicAssets = "https://wax-aa.gnocity.io";
// export const baseUrlWaxAtomicAssets2 = "http://wax-aa.gnocity.io:9898";
export const baseUrlWaxAtomicAssets2 = "https://wax-aa.gnocity.io";
export const baseUrlGnomeseries = "https://gnomeseries.com";
export const baseUrlGnoMyPinataCloud = "https://gno.mypinata.cloud";
export const imagePathPoint = "https://ipfs.ledgerwise.io/";
