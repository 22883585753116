import transactionSigner from "./TransactionSigner";
import waxUrl from "../shared/waxUrl";
import eosjsName from "eosjs-account-name";
import { rpcEndpoint } from "../shared/constants";

const { atomicContract } = waxUrl;
export async function getConfigurations(
  table = "bconfigs",
  activeUser,
  scope,
  rows = 10000
) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const contract = "gnomining.gm";
  const bounds =
    table === "gamers" || table === "pcs"
      ? eosjsName.nameToUint64(activeUser)
      : "";
  const key_type = table === "gamers" || table === "pcs" ? "i64" : "";
  const index_position = table === "gamers" || table === "pcs" ? 2 : "";

  const urlBody = {
    code: contract,
    scope: scope ? scope : contract,
    json: true,
    upperBound: bounds,
    lowerBound: bounds,
    key_type: key_type,
    index_position: index_position,
    limit: rows,
    table: table,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
