const waxUrl = {
  contract: "atomicassets",
  pvpContract: "gnocitygmpvp",
  bonusContract: "gnocitybonus",
  miningContract: "gnomining.gm",
  collectionName: "gnocityworld",
  atomicContract: "atomicassets",
  gnomeContract: "gnocitygames",
  storyContract: "gnocitystory",
  perksContract: "gnocityperks",
  petsContract: "gnocitypetsg",
  raceContract: "gnocityraces",
  salesContract: "gnocitysales",
  tokenContract: "gnokentokens",
  breedContract: "gnocitybreed",
  landContract: "gnocitylands",
  shopContract: "gnocityshops",
  landboxContract: "gnokentokens",
  gnocityBaseUrl: "https://gnocity.com",
  assetImageUrl: "https://wax.atomichub.io/explorer/asset",
  IpfsImageUrl: "https://atomichub-ipfs.com/ipfs",
  nodeApiUrl: "https://nodeapi.gnocity.io",
  //// FOR DEVELOPMENT
  // rpcEndpoint: "https://wax.cryptolions.io",
  // baseAtomicAssets: "https://wax-aa.eu.eosamsterdam.net",
  //// FOR PRODUCTION
  rpcEndpoint: "https://wax-api.gnocity.io",
  baseAtomicAssets: "https://wax-aa.gnocity.io",
  waxBlocks: "https://wax.bloks.io",
  graymassTableRows: "https://wax.greymass.com",
};

export default waxUrl;
