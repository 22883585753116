import React, { useState, useEffect, Fragment } from "react";

import { Button } from "react-bootstrap";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { BoxSlider } from "../../components/BoxDetail";
import GnocityDropdown from "../../components/Dropdown";
import { Modal as GnocityModal } from "../../components/Modal";

import { baseUrlIpfs } from "../../shared/constants";
import waxUrl from "../../shared/waxUrl";
import eggyConstants from "./constants";
import { InputText } from "../../components/inputs";

import EggIcon from "../../assets/images/badge/eggy-gnocity.png";
import { getToFixedNumber } from "../../shared/helpers";
import "./index.scss";

import {
  claimAllEggy,
  claimByEggType,
  evolve,
  getAvailableCards,
  getEggAssetDetails,
  getEggMints,
  getEggTypeTokens,
  getStakedEggs,
  getWhitelistedEggTokens,
  stakeEggy,
  unstakeAllEggy,
  unstakeByType,
  unstakeEgg,
} from "../../services/EggyService";
import { getPlayerAssetsByTemplateOnly } from "../../services/AssetService";

const SELECTIONS = {
  egg: "egg",
  nft: "nft",
};

export default function EvolutionPage({ userProperties, activeUser }) {
  const { evolutionSliderBreakpoints, sliderBreakpoints, sliderAutoplay } =
    eggyConstants;

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [loading, setLoading] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [selectedEggy, setSelectedEggy] = useState(null);
  const [allCards, setAllCards] = useState([]);
  const [stakedCards, setStakedCards] = useState([]);
  const [eggTypes, setEggTypes] = useState([]);
  const [eggTypeList, setEggTypeList] = useState([]);
  const [eggTypeFilter, setEggTypeFilter] = useState("");
  const [selectedEggType, setSelectedEggType] = useState("");
  const [whiteListedEggs, setWhiteListedEggs] = useState([]);
  const [cardFilter, setCardFilter] = useState("");

  const [selectionMode, setSelectionMode] = useState(SELECTIONS.egg);
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedCards, setSelectedCards] = useState([]);
  const [claimedCards, setClaimedCards] = useState([]);
  const [whitelistedCards, setWhiteListedCards] = useState([]);
  const [eggRequirement, setEggRequirement] = useState(0);
  const [availableEvolution, setAvailableEvolution] = useState(0);
  const [eggTotal, setEggTotal] = useState(0);

  async function _serviceInit(activeUser) {
    const eggTypeResponse = await getEggTypeTokens();
    const whitelistResponse = await getWhitelistedEggTokens();

    if (eggTypeResponse.success) {
      const eggTypes = eggTypeResponse.responseData.rows;
      const eggTypesDropdownList = eggTypes.map((e) => {
        return { label: e.eggtype, value: e.eggtypeid };
      });

      setEggTypes(eggTypes);
      setEggTypeList(eggTypesDropdownList);
    }

    if (whitelistResponse.success) {
      const eggWhitelist = whitelistResponse.responseData.rows;
      setWhiteListedEggs(eggWhitelist);
    }
  }

  async function _dataInit(eggTypes) {
    try {
      let eggMintList = [];
      for (let eggytype of eggTypes) {
        const eggMintResponse = await getEggMints(eggytype.eggtypeid);
        if (eggMintResponse.success) {
          const eggMintData = eggMintResponse.responseData.rows.map((ec) => {
            return {
              ...ec,
              eggtypeid: eggytype.eggtypeid,
              eggtype: eggytype.eggtype,
            };
          });
          eggMintList.push(...eggMintData);
        }
      }
      setAvailableEvolution(eggMintList);
    } catch (error) {
      console.log("🎶🎶", error);
    }
  }

  async function _getAssetsByWhitelistedEggs(list, activeUser) {
    try {
      let additionalCards = [];
      for (let li of list) {
        const assetsResponse = await getPlayerAssetsByTemplateOnly(
          activeUser,
          "",
          li.templateid
        );
        if (assetsResponse.success) {
          const data = assetsResponse.responseData.data;
          if (data.length) {
            additionalCards.push(...data);
          }
        }
      }
      setWhiteListedCards((cards) => [...cards, ...additionalCards]);
    } catch (error) {
      console.log("🎶🎶", error);
    }
  }

  async function _getAssetsByEggType(eggTypeDetail, activeUser) {
    try {
      let additionalCards = [];
      const assetsResponse = await getPlayerAssetsByTemplateOnly(
        activeUser,
        "gnocityworld",
        eggTypeDetail.eggtypeid
      );

      if (assetsResponse.success) {
        const data = assetsResponse.responseData.data;
        if (data.length) {
          additionalCards.push(...data);
        }
      }

      setClaimedCards((cards) => [...cards, ...additionalCards]);
    } catch (error) {
      console.log("🎶🎶", error);
    }
  }

  const _handleEvoComputation = (eggQuantity, eggRequirement) => {
    if (!eggQuantity || !eggRequirement) return 0;
    const eggTotalAmount = eggQuantity
      .map((e) => {
        const amount = e.data?.amount;
        return !amount ? 0 : amount;
      })
      .reduce((a, b) => a + b, 0);

    const eggRequirementAmount = eggRequirement.amount;
    let _eggQuantity = parseFloat(eggTotalAmount);
    let _eggRequirement = parseFloat(eggRequirementAmount);

    if (_eggRequirement === 0) return 0;
    const chanceComputation =
      _eggQuantity < _eggRequirement
        ? (_eggQuantity / _eggRequirement) * 100
        : 100;
    return getToFixedNumber(chanceComputation);
  };

  useEffect(() => {
    if (eggTypeFilter) {
      setWhiteListedCards([]);
      const filteredWhiteListedEggs = whiteListedEggs.filter(
        (e) => e.eggtype === eggTypeFilter
      );
      const eggTypeDetails = eggTypes.find(
        (e) => e.eggtypeid === eggTypeFilter
      );

      _getAssetsByEggType(eggTypeDetails, activeUser);
      _getAssetsByWhitelistedEggs(filteredWhiteListedEggs, activeUser);
    }
  }, [eggTypeFilter]);

  useEffect(() => {
    if (availableEvolution && eggTypeFilter) {
      const requirement = availableEvolution.find(
        (ae) => ae.eggtypeid === eggTypeFilter
      );
      setEggRequirement(requirement);
    }
  }, [eggTypeFilter, availableEvolution]);

  useEffect(() => {
    setLoading(true);
    if (eggTypes.length && whiteListedEggs.length) {
      _dataInit(eggTypes);
    }
    setLoading(false);
  }, [eggTypes, whiteListedEggs]);

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="evolution-page">
      <GnocityModal
        className="pets-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <div className="tf-container">
        <h4 className="evolution-page__title evolution-page__btn-grp-container">
          Evolution Page
          {` - ${selectionMode}`}
          <GnocityDropdown
            list={eggTypeList}
            title={`SELECT EGG TYPE`}
            onDropdownChanged={(value) => {
              setSelectedEggType(value);
              setEggTypeFilter(value);
            }}
          />
        </h4>

        <div className="evolution-page__evolve-btn-container">
          <div className="evolution-page__evolve-title-container">
            <h4 className="evolution-screen__header">{`${_handleEvoComputation(
              selectedCards,
              eggRequirement
            )} %`}</h4>
            <Button className="tf-button" onClick={async () => {}}>
              EVOLVE
            </Button>
          </div>
          <div>
            <Button
              className="tf-button"
              disabled={!eggTypeFilter || !selectedNFT || !selectedCards.length}
              onClick={async () => {
                try {
                  const response = await evolve(
                    userProperties,
                    activeUser,
                    eggRequirement,
                    selectedCards,
                    selectedNFT
                  );

                  if (response.success) {
                    setMessageOpen(true);
                    setMessagePositive(true);
                    setMessage(`EVOLUTION SUCCESSFUL`);
                  }

                  if (!response.success) {
                    setMessageOpen(true);
                    setMessagePositive(false);
                    setMessage(`EVOLUTION FAILED`);
                  }
                } catch (error) {
                  setMessageOpen(true);
                  setMessage(`EVOLUTION FAILED : ${error}`);
                  setMessagePositive(false);
                  console.log("🎶🎶🎶", error);
                }
              }}
            >
              Click to Attempt Evolution - WARNING! Eggs will be deducted or
              burned.
            </Button>
          </div>
        </div>

        <Swiper
          className="collection-1 visible evolution-page__swiper"
          spaceBetween={30}
          breakpoints={evolutionSliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {!selectedEggy ? (
            <Fragment>
              <SwiperSlide key={`def-eggy`}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div
                      className="image"
                      onClick={() => setSelectionMode(SELECTIONS.egg)}
                    >
                      <img
                        className="lineup-page__slider-image"
                        src={EggIcon}
                        alt={EggIcon}
                      />
                    </div>
                    <h6 className="name"></h6>
                  </div>
                </div>
              </SwiperSlide>
            </Fragment>
          ) : (
            <Fragment>
              <SwiperSlide key={`def-eggy`}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div
                      className="image"
                      onClick={() => setSelectionMode(SELECTIONS.egg)}
                    >
                      <img
                        className="lineup-page__slider-image"
                        src={`${baseUrlIpfs}/ipfs/${selectedEggy?.data?.img}`}
                        alt={EggIcon}
                      />
                    </div>
                    <h6 className="name">
                      <BoxSlider
                        title="Quantity"
                        value={sliderValue}
                        maxValue={10}
                        onSliderValueChanged={(sliderValue) =>
                          setSliderValue(sliderValue)
                        }
                      />
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            </Fragment>
          )}

          <SwiperSlide key={`computation`}></SwiperSlide>

          {!selectedNFT ? (
            <Fragment>
              <SwiperSlide key={`def-nft`}>
                <div className="slider-item">
                  <div className="tf-product">
                    <div
                      className="image"
                      onClick={() => setSelectionMode(SELECTIONS.nft)}
                    >
                      <img
                        className="lineup-page__slider-image"
                        src={EggIcon}
                        alt={EggIcon}
                      />
                    </div>
                    <h6 className="name"></h6>
                  </div>
                </div>
              </SwiperSlide>
            </Fragment>
          ) : (
            <Fragment>
              <SwiperSlide key={`def-nft`}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div
                      className="image"
                      onClick={() => setSelectionMode(SELECTIONS.nft)}
                    >
                      <img
                        className="lineup-page__slider-image"
                        src={`${baseUrlIpfs}/ipfs/${selectedNFT?.data?.img}`}
                        alt={EggIcon}
                      />
                    </div>
                    <h6 className="name"></h6>
                  </div>
                </div>
              </SwiperSlide>
            </Fragment>
          )}
        </Swiper>
      </div>

      <div className="tf-container">
        <h4 className="evolution-page__title evolution-page__btn-grp-container">
          {selectionMode !== SELECTIONS.egg ? null : `SELECT EGGS`}
          {selectionMode !== SELECTIONS.nft ? null : `SELECT NFT CHARACTERS`}
        </h4>
        <div>
          <InputText
            value={cardFilter}
            placeholder="Enter Filter"
            onInputChanged={(value) => {
              setCardFilter(value);
            }}
          />
        </div>
        <Swiper
          className="collection-1 visible evolution-page__swiper"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {selectionMode === SELECTIONS.egg &&
            claimedCards
              .filter((c) => {
                if (cardFilter) {
                  const uppercasedFilter = cardFilter.toUpperCase();
                  const cardName = c.cardName.toUpperCase();
                  const hasCard = cardName.includes(uppercasedFilter);

                  return hasCard;
                }

                return true;
              })
              .map((c) => {
                const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
                const imgUrl = imagePathPoint + c?.data?.img;
                const isSelected = selectedCards.find(
                  (sc) => sc.asset_id === c.asset_id
                );

                return (
                  <SwiperSlide key={c.asset_id}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="lineup-page__slider-image"
                            src={imgUrl}
                            alt={imgUrl}
                          />
                        </div>
                        <h6 className="name">
                          <Button
                            className="tf-button evolution-page__btn--green"
                            onClick={async () => {
                              if (isSelected) {
                                const filteredCards = selectedCards.filter(
                                  (sc) => sc.asset_id !== c.asset_id
                                );
                                setSelectedCards(filteredCards);
                                return;
                              }

                              setSelectedCards((cards) => [...cards, c]);
                            }}
                          >
                            {!isSelected ? `SELECT` : `UNSELECT`}
                          </Button>
                        </h6>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}

          {selectionMode === SELECTIONS.nft &&
            whitelistedCards.map((c) => {
              const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
              const imgUrl = imagePathPoint + c?.data?.img;

              return (
                <SwiperSlide key={c.asset_id}>
                  <div className="slider-item">
                    <div className="tf-product ">
                      <div className="image">
                        <img
                          className="lineup-page__slider-image"
                          src={imgUrl}
                          alt={imgUrl}
                        />
                      </div>
                      <h6 className="name">
                        <Button
                          className="tf-button evolution-page__btn--green"
                          onClick={async () => {
                            setSelectedNFT(c);
                          }}
                        >
                          SELECT
                        </Button>
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
}
