import transactionSigner from "./TransactionSigner";
import { baseUrlWaxAtomicAssets, baseUrlIpfs } from "../shared/constants";
import { getAssetDetails } from "./AssetService";
import waxUrl from "../shared/waxUrl";

const { rpcEndpoint, raceContract, atomicContract } = waxUrl;

export async function raceSetReady(
  userProperties,
  activeUser,
  racerReady = true
) {
  const transactionData = [
    {
      contractAccount: raceContract,
      actionName: "setready",
      data: {
        account: activeUser,
        ready: racerReady,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function raceTimeTrial(userProperties, activeUser) {
  const transactionData = [
    {
      contractAccount: raceContract,
      actionName: "timetrial",
      data: {
        account: activeUser,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function unstakeCharacter(userProperties, activeUser, cardAsset) {
  const transactionData = [
    {
      contractAccount: raceContract,
      actionName: "rmcharacters",
      data: {
        account: activeUser,
        asset_ids: [cardAsset.asset_id],
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function unstakeVehicle(userProperties, activeUser, cardAsset) {
  const transactionData = [
    {
      contractAccount: raceContract,
      actionName: "rmvehicles",
      data: {
        account: activeUser,
        asset_ids: [cardAsset.asset_id],
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function unstakeAddOn(userProperties, activeUser, cardAsset) {
  const transactionData = [
    {
      contractAccount: raceContract,
      actionName: "rmaddons",
      data: {
        account: activeUser,
        asset_ids: [cardAsset.asset_id],
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function stakeCharacter(userProperties, activeUser, cardAsset) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: raceContract,
        asset_ids: [parseInt(cardAsset.asset_id)],
        memo: "",
      },
    },
    {
      contractAccount: raceContract,
      actionName: "addcharacter",
      data: {
        account: activeUser,
        asset_id: parseInt(cardAsset.asset_id),
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function stakeVehicle(userProperties, activeUser, cardAsset) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: raceContract,
        asset_ids: [parseInt(cardAsset.asset_id)],
        memo: "",
      },
    },
    {
      contractAccount: raceContract,
      actionName: "addvehicle",
      data: {
        account: activeUser,
        asset_id: parseInt(cardAsset.asset_id),
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function stakeAddOn(userProperties, activeUser, cardAsset) {
  const transactionData = [
    {
      contractAccount: atomicContract,
      actionName: "transfer",
      data: {
        from: activeUser,
        to: raceContract,
        asset_ids: [parseInt(cardAsset.asset_id)],
        memo: "",
      },
    },
    {
      contractAccount: raceContract,
      actionName: "addaddon",
      data: {
        account: activeUser,
        asset_id: parseInt(cardAsset.asset_id),
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function getUserGarage(activeUser, rows = 1000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    code: raceContract,
    scope: raceContract,
    table: "users",
    index_position: 1,
    key_type: "name",
    lower_bound: activeUser,
    upper_bound: activeUser,
    limit: rows,
    reverse: false,
  };
  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function getUserVehicles(activeUser, rows = 1000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    code: raceContract,
    scope: raceContract,
    table: "vehicles",
    index_position: 2,
    key_type: "name",
    lower_bound: activeUser,
    upper_bound: activeUser,
    limit: rows,
    reverse: false,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === 200) {
    const racingJson = await response.json();

    if (racingJson?.rows && racingJson?.rows.length) {
      let cards = [];
      let lineup = [];
      const latestCards = racingJson.rows;

      for (let i = 0; i < latestCards.length; i++) {
        let card = latestCards[i];
        const cardDetailResponse = await getAssetDetails(card?.asset_id);
        const cardData = cardDetailResponse.responseData.data;

        if (cardDetailResponse.success && cardData) {
          const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
          const detail = {
            ...cardData,
            imgUrl: `${imagePathPoint}${cardData?.template.immutable_data.img}`,
          };
          cards.push(detail);
        }
      }

      return { success: true, responseData: cards };
    }
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getUserCharacters(activeUser, rows = 1000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    code: raceContract,
    scope: raceContract,
    table: "characters",
    index_position: 2,
    key_type: "name",
    lower_bound: activeUser,
    upper_bound: activeUser,
    limit: rows,
    reverse: false,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === 200) {
    const racingJson = await response.json();

    if (racingJson?.rows && racingJson?.rows.length) {
      let cards = [];
      let lineup = [];
      const latestCards = racingJson.rows;

      for (let i = 0; i < latestCards.length; i++) {
        let card = latestCards[i];
        const cardDetailResponse = await getAssetDetails(card?.asset_id);
        const cardData = cardDetailResponse.responseData.data;

        if (cardDetailResponse.success && cardData) {
          const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
          const detail = {
            ...cardData,
            imgUrl: `${imagePathPoint}${cardData?.template.immutable_data.img}`,
          };
          cards.push(detail);
        }
      }

      return { success: true, responseData: cards };
    }
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getUserDetails(activeUser, rows = 1000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    code: raceContract,
    scope: raceContract,
    table: "users",
    index_position: 1,
    key_type: "name",
    lower_bound: activeUser,
    upper_bound: activeUser,
    limit: rows,
    reverse: false,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getUserAddOns(activeUser, userDetail, rows = 1000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    code: raceContract,
    scope: raceContract,
    table: "addons",
    index_position: 2,
    key_type: "name",
    lower_bound: activeUser,
    upper_bound: activeUser,
    limit: rows,
    reverse: false,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === 200) {
    const racingJson = await response.json();
    const userInformation = userDetail.responseData.rows[0];

    if (racingJson?.rows && racingJson?.rows.length) {
      let cards = [];
      let lineup = [];
      const latestCards = racingJson.rows;

      for (let i = 0; i < latestCards.length; i++) {
        let card = latestCards[i];
        const cardDetailResponse = await getAssetDetails(card?.asset_id);
        const cardData = cardDetailResponse.responseData.data;

        if (cardDetailResponse.success && cardData) {
          const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
          const detail = {
            ...card,
            ...cardData,
            chose:
              userInformation.fuel_upgrade === card.asset_id ||
              userInformation.nitro_upgrade === card.asset_id ||
              userInformation.tire_upgrade === card.asset_id,
            imgUrl: `${imagePathPoint}${cardData?.template.immutable_data.img}`,
          };
          cards.push(detail);
        }
      }

      return { success: true, responseData: cards };
    }
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getRaceLogs(activeUser, rows = 1000) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    code: raceContract,
    scope: raceContract,
    table: "races",
    index_position: 2,
    key_type: "name",
    lower_bound: activeUser,
    upper_bound: activeUser,
    limit: rows,
    reverse: false,
  };

  const urlBody2 = {
    json: true,
    code: raceContract,
    scope: raceContract,
    table: "races",
    index_position: 3,
    key_type: "name",
    lower_bound: activeUser,
    upper_bound: activeUser,
    limit: rows,
    reverse: false,
  };

  const fetchOptions = (urlBody) => {
    return {
      method: "POST",
      body: JSON.stringify(urlBody),
      mode: "cors",
      credentials: "omit",
    };
  };

  const response = await fetch(url, fetchOptions(urlBody));
  const response2 = await fetch(url, fetchOptions(urlBody2));

  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getStakedVehicles(
  activeUser,
  page = 1,
  rows = 1000,
  order = "desc",
  sortBy = "asset_id"
) {
  const url = `${baseUrlWaxAtomicAssets}/atomicassets/v1/assets?collection_name=cryptoracecl&owner=${activeUser}&page=${page}&limit=${rows}&order=${order}&sort=${sortBy}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
