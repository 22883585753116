import { useState, useEffect } from "react";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal as GnocityModal } from "../../components/Modal";
import { InputText } from "../../components/inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faBars } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../shared/useLocalStorage";

import { getTokenByContract } from "../../services/TokenService";
import { getAllCards, getUserLineUpList } from "../../services/LineupService";

import waxUrl from "../../shared/waxUrl";
import { baseUrlIpfs } from "../../shared/constants";
import pvpConstants from "./constants";

import "./index.scss";
import { getCardAssetsBySchema } from "../../services/AssetService";
import { Button } from "react-bootstrap";
import { healCard } from "../../services/HealthService";

export default function HealthCenterPage({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { sliderBreakpoints, sliderAutoplay } = pvpConstants;

  const [cardGrid, setCardGrid] = useLocalStorage("card-grid", "");

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [healthCards, setHealthCards] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [selectedHealthCards, setSelectedHealthCards] = useState([]);
  const [cardFilter, setCardFilter] = useState("");

  async function _serviceInit(activeUser) {
    const tokens = await getTokenByContract(pvpContract);
    const lineupResponse = await getUserLineUpList(
      activeUser,
      tokens.responseData
    );
    const gnomeSeries1Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1"
    );
    const gnomeSeries2Response = await getAllCards(
      activeUser,
      tokens.responseData,
      "gnomeseries1",
      "series2cards"
    );
    const complexResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "complexserie",
      "complexcard"
    );
    const cykoResponse = await getAllCards(
      activeUser,
      tokens.responseData,
      "cykokobattle",
      "cykokocard"
    );
    const healthCardsResponse = await getCardAssetsBySchema(
      activeUser,
      "gnocityfood1",
      "gnocityfood",
      1,
      1000
    );

    if (lineupResponse.success) {
      const additionalCards = lineupResponse.responseData.lineup;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (gnomeSeries1Response.success) {
      const additionalCards = gnomeSeries1Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (gnomeSeries2Response.success) {
      const additionalCards = gnomeSeries2Response.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (complexResponse.success) {
      const additionalCards = complexResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }

    if (cykoResponse.success) {
      const additionalCards = cykoResponse.responseData;
      setAllCards((cards) => [...cards, ...additionalCards]);
    }
    if (healthCardsResponse.success) {
      const healthCards = healthCardsResponse.responseData.data;
      setHealthCards(healthCards);
    }
	  console.log('Complex Response:', complexResponse);
  }

  useEffect(() => {
    if (activeUser) {
      _serviceInit(activeUser);
    }
  }, [activeUser]);

  return (
    <div className="health-page">
      <GnocityModal
        className="health-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <div className="tf-container">
        <h4 className="health-page__title">eligible health cards</h4>
        <Swiper
          className="collection-1 visible health-page__swiper"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {healthCards.map((c) => {
            const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
            const isSelected = selectedHealthCards.find(
              (sc) => sc.asset_id === c.asset_id
            );

            return (
              <SwiperSlide key={c.asset_id}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div className="image">
                      <img
                        className="lineup-page__slider-image"
                        src={imagePathPoint + c?.template.immutable_data.img}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name"></h6>
                    <Button
                      className="tf-button"
                      onClick={() => {
                        if (isSelected) {
                          const filteredHealthCards =
                            selectedHealthCards.filter(
                              (sc) => sc.asset_id !== c.asset_id
                            );

                          setSelectedHealthCards(filteredHealthCards);
                          return;
                        }
                        setSelectedHealthCards((shc) => [...shc, c]);
                      }}
                    >
                      {isSelected ? "DESELECT" : "SELECT"}
                    </Button>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="tf-container">
        <h4 className="health-page__title health-page__title-grid">
          cards{" "}
          <div>
            <InputText
              value={cardFilter}
              placeholder="Enter Filter"
              onInputChanged={(value) => {
                setCardFilter(value);
              }}
            />
          </div>
          <Button
            className="tf-button lineup-page__btn"
            onClick={() => setCardGrid((grid) => !grid)}
          >
            {!cardGrid ? (
              <FontAwesomeIcon icon={faThLarge} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </Button>
        </h4>

        {!cardGrid ? (
          <Swiper
            className="collection-1 visible health-page__swiper"
            spaceBetween={30}
            breakpoints={sliderBreakpoints}
            loop={false}
            modules={[Autoplay]}
            autoplay={sliderAutoplay}
          >
            {allCards
  .filter((c) => {
    if (cardFilter) {
      const uppercasedFilter = cardFilter.toUpperCase();
      const cardName = c.cardName.toUpperCase();
      const hasCard = cardName.includes(uppercasedFilter);

      return (
        hasCard &&
        ((c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
             (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity))
      );
    }

    return (
     (c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
        (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity)
    );
  })


              .map((c, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className="slider-item">
                      <div className="tf-product ">
                        <div className="image">
                          <img
                            className="lineup-page__slider-image"
                            src={c.imgUrl}
                            alt={c.imgUrl}
                          />
                        </div>
                        <h6 className="name"></h6>
                        <Button
                          className="tf-button"
                          onClick={async () => {
                            try {
                              const cardAssetIds = selectedHealthCards.map(
                                (hc) => {
                                  return hc.asset_id;
                                }
                              );
                              const filteredCards = healthCards.filter((c) => {
                                const isSelected = cardAssetIds.find(
                                  (ca) => ca === c.asset_id
                                );
                                return !isSelected;
                              });
                              const selectedCard = c.asset_id;

                              const response = await healCard(
                                userProperties,
                                activeUser,
                                cardAssetIds,
                                selectedCard
                              );

                              if (response.success) {
                                setMessageOpen(true);
                                setMessagePositive(true);
                                setMessage(`HEALING SUCCESSFUL`);

                                setHealthCards(filteredCards);
                                setSelectedHealthCards([]);
                              }

                              if (!response.success) {
                                setMessageOpen(true);
                                setMessagePositive(false);
                                setMessage(`HEALING FAILED`);
                              }
                            } catch (error) {
                              setMessageOpen(true);
                              setMessage(`HEALING FAILED : ${error}`);
                              setMessagePositive(false);
                              console.log("🎶🎶🎶", error);
                            }
                          }}
                        >
                          HEAL
                        </Button>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <div className="health-page__card-grid">
            {allCards
  .filter((c) => {
    if (cardFilter) {
      const uppercasedFilter = cardFilter.toUpperCase();
      const cardName = c.cardName.toUpperCase();
      const hasCard = cardName.includes(uppercasedFilter);
return (
        hasCard &&
        ((c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
             (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity))
      );
    }

    return (
     (c.assetData.schema?.schema_name === "gnomecard" && c.templateData?.immutable_data?.Variant) ||
        (c.assetData.schema?.schema_name === "complexcard" && c.templateData?.immutable_data?.Rarity) || (c.assetData.schema?.schema_name === "series2cards" && c.templateData?.immutable_data?.Rarity)
    );
  })


              .map((c, i) => {
                return (
                  <div className="tf-product ">
                    <div className="image">
                      <img
                        className="lineup-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name"></h6>
                    <Button
                      className="tf-button"
                      onClick={async () => {
                        try {
                          const cardAssetIds = selectedHealthCards.map((hc) => {
                            return hc.asset_id;
                          });
                          const filteredCards = healthCards.filter((c) => {
                            const isSelected = cardAssetIds.find(
                              (ca) => ca === c.asset_id
                            );
                            return !isSelected;
                          });
                          const selectedCard = c.asset_id;

                          const response = await healCard(
                            userProperties,
                            activeUser,
                            cardAssetIds,
                            selectedCard
                          );

                          if (response.success) {
                            setMessageOpen(true);
                            setMessagePositive(true);
                            setMessage(`HEALING SUCCESSFUL`);

                            setHealthCards(filteredCards);
                            setSelectedHealthCards([]);
                          }

                          if (!response.success) {
                            setMessageOpen(true);
                            setMessagePositive(false);
                            setMessage(`HEALING FAILED`);
                          }
                        } catch (error) {
                          setMessageOpen(true);
                          setMessage(`HEALING FAILED : ${error}`);
                          setMessagePositive(false);
                          console.log("🎶🎶🎶", error);
                        }
                      }}
                    >
                      HEAL
                    </Button>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
