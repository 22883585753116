import React, { useState, useEffect, Fragment } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Modal as GnocityModal } from "../../components/Modal";
import { getAssetDetails } from "../../services/AssetService";
import { getTokenRewards } from "../../services/PvpService";
import { getToFixedNumber, parseWeights } from "../../shared/helpers";
import { baseUrlIpfs } from "../../shared/constants";

import pvpConstants from "./constants";

export default function PvpResultsModal({
  isModalOpen,
  wrapperClassName = "",
  currentPlayer,
  matchData,
  onModalClose,
}) {
  const [playerCard, setPlayerCard] = useState(null);
  const [opponentCard, setOpponentCard] = useState(null);
  const [playerWeights, setPlayerWeights] = useState(null);
  const [opponentWeights, setOpponentWeights] = useState(null);
  const [tokenRewards, setTokenRewards] = useState(null);
  const [gnokenEarned, setGnokenEarned] = useState(0);

  const imagePathPoint = `${baseUrlIpfs}/ipfs/`;
  const { resultSliderBreakpoints, sliderAutoplay, skillsDetail } =
    pvpConstants;

  async function _matchDataInit(matchData) {
    try {
      const tokenResponse = await getTokenRewards();
      const playerCardResponse = await getAssetDetails(matchData.player_card);
      const opponentCardResponse = await getAssetDetails(
        matchData.opponent_card
      );

      if (tokenResponse.success) {
        const tokenData = tokenResponse.responseData.rows[0]?.variants;
        setTokenRewards(tokenData);
      }

      if (playerCardResponse.success) {
        const playerCardData = playerCardResponse.responseData.data;
        setPlayerCard(playerCardData);
      }

      if (opponentCardResponse.success) {
        const opponentCardData = opponentCardResponse.responseData.data;
        setOpponentCard(opponentCardData);
      }

      if (matchData.winner === currentPlayer) {
        const dataTarget =
          matchData.opponent === matchData.winner ? opponentCard : playerCard;
        const gnokenEarned = _getGnokenPerWin(dataTarget, tokenRewards);
      }
    } catch (error) {}
  }

  function _getSkillDetail(skill) {
    return skillsDetail.find((s) => s.attribute === skill);
  }

  function _weightInit(matchData) {
    const playerWeights = parseWeights(matchData.player_weights);
    const opponentWeights = parseWeights(matchData.opponent_weights);
    const playerWeightData = _handleWeightData(playerWeights);
    const opponentWeightData = _handleWeightData(opponentWeights);

    setPlayerWeights(playerWeightData);
    setOpponentWeights(opponentWeightData);
  }

  function _handleWeightData(playerParsed) {
    return {
      "Likeability Comparison": playerParsed.likeability,
      "Mint Comparison": playerParsed.mintnum,
      "Random Comparison": playerParsed.random,
      "Health Comparison": playerParsed.health,
      "Experience Comparison": playerParsed.experience,
      "Issued Supply Comparison": playerParsed.issued_supply,
      "Assassin Comparison": playerParsed.assassin_skill,
      "Combat Comparison": playerParsed.manipulation_skill,
      "Manipulation Comparison": playerParsed.speed_skill,
      "Awareness Comparison": playerParsed.combat_skill,
      "Defense Comparison": playerParsed.awareness_skill,
      "Speed Comparison": playerParsed.intelligence_skill,
      "Intelligence Comparison": playerParsed.defense_skill,
      "Willpower Comparison": playerParsed.willpower_skill,
    };
  }

  const _getGnokenPerWin = (asset, arrayVariants) => {
    const gnokenPerWin = "3.00 GNOKEN";
    const gnomeCollectionName = "gnomeseries1";
    const complexCollectionName = "complexserie";
    const variant = asset.template?.immutable_data?.Variant;
    const rarity = asset.template?.immutable_data?.Rarity;
    const { collection_name } = asset?.collection;

    if (arrayVariants.length === 0) return gnokenPerWin;

    if (collection_name === gnomeCollectionName && variant) {
      const variantKey = arrayVariants.find((v) => v.key === variant);
      if (variantKey) return variantKey.value;
    }

    if (collection_name === complexCollectionName && rarity) {
      const rarityKey = arrayVariants.find((v) => v.key === rarity);
      if (rarityKey) return rarityKey.value;
    }
    return gnokenPerWin;
  };

  useEffect(() => {
    if (matchData) {
      _matchDataInit(matchData);
      _weightInit(matchData);
    }
  }, [matchData]);
  return (
    <GnocityModal
      wrapperClassName={wrapperClassName}
      isOpen={isModalOpen}
      onModalClose={onModalClose}
    >
      <div className="pvp-page__result-swiper-container">
        <Swiper
          className="collection-1 visible pvp-page__swiper"
          spaceBetween={30}
          breakpoints={resultSliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {!playerCard ? null : (
            <SwiperSlide key={`playerCard`}>
              <div className="slider-item">
                <div className="tf-product ">
                  <div className="image">
                    <img
                      className="lineup-page__slider-image"
                      src={`${imagePathPoint}${playerCard.data.img}`}
                    />
                  </div>
                  <h6 className="name"></h6>
                </div>
              </div>
            </SwiperSlide>
          )}

          {!opponentCard ? null : (
            <SwiperSlide key={`playerCard`}>
              <div className="slider-item">
                <div className="tf-product ">
                  <div className="image">
                    <img
                      className="lineup-page__slider-image"
                      src={`${imagePathPoint}${opponentCard.data.img}`}
                    />
                  </div>
                  <h6 className="name"></h6>
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>

      <div className="pvp-page__status-container">
        {/* <div className="pvp-page__player-status-container">
          <h6 className="pvp-page__player-status pvp-page__player-status--bordered">
            <span className="pvp-page__player-status">TOTAL POINTS</span>
            <span className="pvp-page__player-status pvp-page__player-status--green">
              {matchData && matchData?.winner === currentPlayer
                ? Object.values(playerWeights).reduce(
                    (a, b) => parseInt(a) + parseInt(b),
                    0
                  )
                : Object.values(opponentWeights).reduce(
                    (a, b) => parseInt(a) + parseInt(b),
                    0
                  )}
            </span>
          </h6>
        </div> */}
      </div>
      <div className="pvp-page__status-container">
        <h5>
          {currentPlayer}
          {matchData?.winner === currentPlayer ? (
            <span className="pvp-page__player-status--spaced pvp-page__player-status--green">
              WINNER!
            </span>
          ) : (
            <span className="pvp-page__player-status--spaced pvp-page__player-status--red">
              LOSER!
            </span>
          )}
        </h5>
        <div className="pvp-page__player-status-container">
          {playerWeights && (
            <Fragment>
              <h6 className="pvp-page__player-status pvp-page__player-status--bordered">
                <span className="pvp-page__player-status">
                  NFT Health Decrease
                </span>
                <span className="pvp-page__player-status pvp-page__player-status--red">
                  -{matchData?.player_health_loss}
                </span>
              </h6>

              {matchData?.winner !== currentPlayer ? null : (
                <Fragment>
                  <h6 className="pvp-page__player-status pvp-page__player-status--bordered">
                    <span className="pvp-page__player-status">
                      NFT Level Increase
                    </span>
                    <span className="pvp-page__player-status pvp-page__player-status--green">
                      +{matchData?.winner_level_increase}
                    </span>
                  </h6>

                  <h6 className="pvp-page__player-status pvp-page__player-status--bordered">
                    <span className="pvp-page__player-status">
                      Experience Increase
                    </span>
                    <span className="pvp-page__player-status pvp-page__player-status--green">
                      +{getToFixedNumber(matchData?.winner_exp_increase)}
                    </span>
                  </h6>

                  <h6 className="pvp-page__player-status pvp-page__player-status--bordered">
                    <span className="pvp-page__player-status">
                      Skill Increased
                    </span>
                    <span className="pvp-page__player-status pvp-page__player-status--green">
                      {_getSkillDetail(matchData?.exp_skill)?.title}
                    </span>
                  </h6>
                </Fragment>
              )}

              {Object.keys(playerWeights).map((key) => {
                if (playerWeights[key] === 0) return null;
                return (
                  <h6
                    key={key}
                    className="pvp-page__player-status  pvp-page__player-status--bordered"
                  >
                    <span className="pvp-page__player-status">{key}</span>
                    <span className="pvp-page__player-status pvp-page__player-status--green">
                      +{playerWeights[key]} Pts
                    </span>
                  </h6>
                );
              })}
            </Fragment>
          )}
        </div>
        <br />
        <h5>
          {matchData?.opponent}
          {matchData?.winner === matchData?.opponent ? (
            <span className="pvp-page__player-status--spaced pvp-page__player-status--green">
              WINNER!
            </span>
          ) : (
            <span className="pvp-page__player-status--spaced pvp-page__player-status--red">
              LOSER!
            </span>
          )}
        </h5>
        <div className="pvp-page__player-status-container">
          {opponentWeights && (
            <Fragment>
              <h6 className="pvp-page__player-status pvp-page__player-status--bordered">
                <span className="pvp-page__player-status">
                  NFT Health Decrease
                </span>
                <span className="pvp-page__player-status pvp-page__player-status--red">
                  -{matchData?.opponent_health_loss}
                </span>
              </h6>

              {matchData?.winner !== matchData?.opponent ? null : (
                <Fragment>
                  <h6 className="pvp-page__player-status pvp-page__player-status--bordered">
                    <span className="pvp-page__player-status">
                      NFT Level Increase
                    </span>
                    <span className="pvp-page__player-status pvp-page__player-status--green">
                      +{matchData?.winner_level_increase}
                    </span>
                  </h6>

                  <h6 className="pvp-page__player-status pvp-page__player-status--bordered">
                    <span className="pvp-page__player-status">
                      Experience Increase
                    </span>
                    <span className="pvp-page__player-status pvp-page__player-status--green">
                      +{getToFixedNumber(matchData?.winner_exp_increase)}
                    </span>
                  </h6>

                  <h6 className="pvp-page__player-status pvp-page__player-status--bordered">
                    <span className="pvp-page__player-status">
                      Skill Increased
                    </span>
                    <span className="pvp-page__player-status pvp-page__player-status--green">
                      {_getSkillDetail(matchData?.exp_skill)?.title}
                    </span>
                  </h6>
                </Fragment>
              )}

              {Object.keys(opponentWeights).map((key) => {
                if (opponentWeights[key] === 0) return null;
                return (
                  <h6
                    key={key}
                    className="pvp-page__player-status  pvp-page__player-status--bordered"
                  >
                    <span className="pvp-page__player-status">{key}</span>
                    <span className="pvp-page__player-status pvp-page__player-status--green">
                      +{opponentWeights[key]} Pts
                    </span>
                  </h6>
                );
              })}
            </Fragment>
          )}
        </div>
      </div>
    </GnocityModal>
  );
}
