import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal as GnocityModal } from "../../components/Modal";

import { getAllCards } from "../../services/LineupService";
import {
  getUserAddOns,
  getUserDetails,
  stakeAddOn,
  unstakeAddOn,
} from "../../services/RacingService";
import { getTokenByContract } from "../../services/TokenService";

import waxUrl from "../../shared/waxUrl";
import racingConstants from "./constants";

import "./index.scss";

export default function RacingAddOns({ userProperties, activeUser }) {
  const { pvpContract } = waxUrl;
  const { addOns, sliderBreakpoints, sliderAutoplay } = racingConstants;

  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messagePositive, setMessagePositive] = useState(false);

  const [serviceLoaded, setServiceloaded] = useState(false);
  const [fuels, setFuels] = useState([]);
  const [nitros, setNitros] = useState([]);
  const [tires, setTires] = useState([]);

  const [fuelInfor, setFuelInfor] = useState({
    mpg: 0,
    tire: 0,
    engine: 0,
  });

  const [tireInfor, setTireInfor] = useState({
    mpg: 0,
    tire: 0,
    engine: 0,
  });

  const [nitroInfor, setNitroInfor] = useState({
    mpg: 0,
    tire: 0,
    engine: 0,
  });

  const [selectedCard, setSelectedCard] = useState(null);
  const [stakedCards, setStakedCards] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [cardLineup, setCardLineup] = useState([]);

  async function _serviceInit(activeUser) {
    const userDetailResponse = await getUserDetails(activeUser);
    const racingAddOnsResponse = await getUserAddOns(
      activeUser,
      userDetailResponse
    );

    if (racingAddOnsResponse.success) {
      const additionalCards = racingAddOnsResponse.responseData;
      setStakedCards((cards) => [...cards, ...additionalCards]);
    }
  }

  async function _upgradesInit(activeUser) {
    try {
      const tokens = await getTokenByContract(pvpContract);
      const racingUpgradesResponse = await getAllCards(
        activeUser,
        tokens.responseData,
        "cryptoracecl",
        "upgrades"
      );

      if (racingUpgradesResponse.success) {
        const additionalCards = racingUpgradesResponse.responseData;
        const upgradeCards = additionalCards
          .filter((c) => c.template.immutable_data.type)
          .filter((c) => c.schema.schema_name === addOns.upgrades);

        const fuels = upgradeCards.filter(
          (c) =>
            c.template.immutable_data.type === addOns.fuel &&
            !c.template.immutable_data.name.includes(addOns.tires)
        );
        const nitros = upgradeCards.filter(
          (c) => c.template.immutable_data.type === addOns.nitro
        );
        const tires = upgradeCards.filter(
          (c) =>
            c.template.immutable_data.type === addOns.fuel &&
            c.template.immutable_data.name.includes(addOns.tires)
        );

        setAllCards((cards) => [...cards, ...additionalCards]);
        setFuels(fuels);
        setNitros(nitros);
        setTires(tires);
      }
    } catch (error) {
      console.log("🎶", error);
    }
  }

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      _upgradesInit(activeUser);
    }
  }, [serviceLoaded]);

  useEffect(() => {
    if (activeUser) {
      setAllCards([]);
      _serviceInit(activeUser);
      setServiceloaded(true);
    }
  }, [activeUser]);

  return (
    <div className="racing-page">
      <GnocityModal
        className="racing-page__message-wrapper"
        isOpen={messageOpen}
        messagePositive={messagePositive}
        modalMessage={message}
        onModalClose={() => {
          setMessage("");
          setMessageOpen(false);
        }}
      />

      <h3 className="racing-page__title">staking add-ons</h3>
      <div className="racing-page__card-container racing-page__card-container--spaced">
        {!stakedCards.length && (
          <h1 className="racing-page__title racing-page__title--centered">
            You do not have any add-ons of this type
          </h1>
        )}
        <Swiper
          className="collection-1 visible racing-page__bg--gray"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {stakedCards.map((c) => {
            return (
              <SwiperSlide key={c.asset_id}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div className="image">
                      <img
                        className="racing-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">
                      <Button
                        className="tf-button"
                        onClick={async () => {
                          try {
                            const response = await unstakeAddOn(
                              userProperties,
                              activeUser,
                              c
                            );
                            if (response.success) {
                              setMessageOpen(true);
                              setMessagePositive(true);
                              setMessage(`UNSTAKE SUCCESSFUL`);
                            }

                            if (!response.success) {
                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage(`UNSTAKE FAILED`);
                            }
                          } catch (error) {
                            setMessageOpen(true);
                            setMessage(`UNSTAKE FAILED : ${error}`);
                            setMessagePositive(false);
                            console.log("🎶🎶🎶", error);
                          }
                        }}
                      >
                        UNSTAKE
                      </Button>
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <h3 className="racing-page__title">tire add-on</h3>
      <div className="racing-page__card-container racing-page__card-container--spaced">
        {!tires.length && (
          <h1 className="racing-page__title racing-page__title--centered">
            You do not have any add-ons of this type
          </h1>
        )}
        <Swiper
          className="collection-1 visible racing-page__bg--gray"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {tires.map((c) => {
            const isSelected =
              selectedCard && selectedCard.asset_id === c.asset_id;

            return (
              <SwiperSlide key={c.asset_id}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div className="image">
                      <img
                        className="racing-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">
                      <Button
                        className="tf-button"
                        onClick={async () => {
                          try {
                            const response = await stakeAddOn(
                              userProperties,
                              activeUser,
                              c
                            );
                            if (response.success) {
                              setMessageOpen(true);
                              setMessagePositive(true);
                              setMessage(`INSTALLATION SUCCESSFUL`);
                            }

                            if (!response.success) {
                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage(`INSTALLATION FAILED`);
                            }
                          } catch (error) {
                            setMessageOpen(true);
                            setMessage(`INSTALLATION FAILED : ${error}`);
                            setMessagePositive(false);
                            console.log("🎶🎶🎶", error);
                          }
                        }}
                      >
                        Install Upgrade
                      </Button>
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <h3 className="racing-page__title">fuel add-on</h3>
      <div className="racing-page__card-container racing-page__card-container--spaced">
        {!tires.length && (
          <h1 className="racing-page__title racing-page__title--centered">
            You do not have any add-ons of this type
          </h1>
        )}
        <Swiper
          className="collection-1 visible racing-page__bg--gray"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {fuels.map((c) => {
            const isSelected =
              selectedCard && selectedCard.asset_id === c.asset_id;

            return (
              <SwiperSlide key={c.asset_id}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div className="image">
                      <img
                        className="racing-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">
                      <Button
                        className="tf-button"
                        onClick={async () => {
                          try {
                            const response = await stakeAddOn(
                              userProperties,
                              activeUser,
                              c
                            );
                            if (response.success) {
                              setMessageOpen(true);
                              setMessagePositive(true);
                              setMessage(`INSTALLATION SUCCESSFUL`);
                            }

                            if (!response.success) {
                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage(`INSTALLATION FAILED`);
                            }
                          } catch (error) {
                            setMessageOpen(true);
                            setMessage(`INSTALLATION FAILED : ${error}`);
                            setMessagePositive(false);
                            console.log("🎶🎶🎶", error);
                          }
                        }}
                      >
                        Install Upgrade
                      </Button>
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <h3 className="racing-page__title">nitro add-on</h3>
      <div className="racing-page__card-container racing-page__card-container--spaced">
        {!tires.length && (
          <h1 className="racing-page__title racing-page__title--centered ">
            You do not have any add-ons of this type
          </h1>
        )}

        <Swiper
          className="collection-1 visible racing-page__bg--gray"
          spaceBetween={30}
          breakpoints={sliderBreakpoints}
          loop={false}
          modules={[Autoplay]}
          autoplay={sliderAutoplay}
        >
          {nitros.map((c) => {
            const isSelected =
              selectedCard && selectedCard.asset_id === c.asset_id;

            return (
              <SwiperSlide key={c.asset_id}>
                <div className="slider-item">
                  <div className="tf-product ">
                    <div className="image">
                      <img
                        className="racing-page__slider-image"
                        src={c.imgUrl}
                        alt={c.imgUrl}
                      />
                    </div>
                    <h6 className="name">
                      <Button
                        className="tf-button"
                        onClick={async () => {
                          try {
                            const response = await stakeAddOn(
                              userProperties,
                              activeUser,
                              c
                            );
                            if (response.success) {
                              setMessageOpen(true);
                              setMessagePositive(true);
                              setMessage(`INSTALLATION SUCCESSFUL`);
                            }

                            if (!response.success) {
                              setMessageOpen(true);
                              setMessagePositive(false);
                              setMessage(`INSTALLATION FAILED`);
                            }
                          } catch (error) {
                            setMessageOpen(true);
                            setMessage(`INSTALLATION FAILED : ${error}`);
                            setMessagePositive(false);
                            console.log("🎶🎶🎶", error);
                          }
                        }}
                      >
                        Install Upgrade
                      </Button>
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
