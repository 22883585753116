import waxUrl from "../shared/waxUrl";
import transactionSigner from "./TransactionSigner";

const { rpcEndpoint, pvpContract, nodeApiUrl } = waxUrl;
const STATUS_OK = 200;

export async function getMatchesByAccountName(
  activeUser,
  indexPosition = 3,
  rows = 10000
) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    scope: pvpContract,
    code: pvpContract,
    table: "matches",
    key_type: "name",
    show_payer: false,
    index_position: indexPosition,
    lower_bound: activeUser,
    upper_bound: activeUser,
    table_key: "",
    limit: rows,
    reverse: false,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === STATUS_OK) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function userFindMatch(activeUser) {
  // try {
  //   const response = await axios.post(
  //     "https://nodeapi.gnocity.io/pvp/attackact",
  //     {
  //       account_name: activeUser,
  //     }
  //   );
  //   console.log("🎂", response);

  //   if (response.status === STATUS_OK) {
  //     return { success: true, responseData: response.data };
  //   } else {
  //     return { success: false, responseData: response.data };
  //   }
  // } catch (error) {
  //   console.log("🎶🎶", error);
  // }

  const url = `${nodeApiUrl}/pvp/attackact`;
  const payload = {
    account_name: activeUser,
  };
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function setMint(activeUser) {
  const url = `${nodeApiUrl}/pvp/usersetmint/${activeUser}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getMatchData(matchId) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    code: pvpContract,
    scope: pvpContract,
    table: "history",
    lower_bound: matchId,
    upper_bound: matchId,
    limit: 1,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === STATUS_OK) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getTokenRewards() {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    json: true,
    code: pvpContract,
    scope: pvpContract,
    table: "tokenrewards",
    limit: 1,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };

  const response = await fetch(url, options);
  if (response.status === STATUS_OK) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function enableNFT(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: pvpContract,
      actionName: "enablecard",
      data: {
        account: activeUser,
        asset_id: assetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}

export async function disableNFT(userProperties, activeUser, assetId) {
  const transactionData = [
    {
      contractAccount: pvpContract,
      actionName: "disablecard",
      data: {
        account: activeUser,
        asset_id: assetId,
      },
    },
  ];

  const response = await transactionSigner(userProperties, transactionData);
  if (response.success) {
    return { success: true, responseData: response.responseData };
  } else {
    return { success: false, responseData: response.responseData };
  }
}
